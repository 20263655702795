
@import "../variables.scss";
@import "~bulma/bulma.sass";
@import "../helpers.scss";
@import "_pages.scss";
@import "_header.scss";
@import "_default-form.scss";
@import "_sidebar.scss";
@import "_profile.scss";
@import "_footer.scss";

body{
    background: $background-main;
}

.admin{
.page-wrapper{
    background: $background-main;
    min-height: 100vh;
}

.columns{
    .column{
        &.is-4{
            width: 40%;
        }
        &.is-8{
            width: 60%;
        }
    }

    &:last-child{
        margin-bottom: 0;
    }
}

.title-area{
    margin:3rem 0 2rem;
    display:block;
    h1{
        font-size: 1.6rem;
        color: $second-color;
        font-weight: 400;
    }
}

.button{
    border-radius: 4px;
    font-size: .9rem;
    font-weight: 500;
    text-align: center;
    display:flex;
    position: relative;
    padding:.8rem 1rem;
    height: unset;
    border:none;
    justify-content: unset;
    color: white;
    font-family: $main-font;
    //box-shadow: 0px 1px 24px 1px rgba(0, 0, 0, 0.02);
    z-index: 1;
    &:focus{
        outline:none;
        color: white;
        border:none;
        box-shadow: 0px 1px 24px 1px rgba(0, 0, 0, 0.12) !important; 
    }
    &:before{
        content:"";
        width: 0;
        height: 100%;
        top:0;
        left:0;
        position: absolute;
        background:rgba(0, 0, 0, 0.15);
        border-radius: 7px;
        transition: width .5s;
        z-index: -1;
    }
    &:hover{
        &:before{
            width: 100%;
        }
    }
    svg{
        margin-left:.4rem;
        font-size: .8rem;
    }
    .item{
        margin-left:1rem;
        z-index: 10;
    }
    .icon{
        margin: auto 0;
        border-radius: 90px;
        padding: 0;
        width: 30px;
        height: 30px;
        display: flex;
        z-index: 10;
    }

    &.default-no-icon{
        .item{
            margin:0;
        }
    }

  &.default{
    background: #ad5389; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ad5389, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ad5389, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
   
    &.add{
      .icon{
        background:white url(./images/plus-blue.svg) center no-repeat;
        background-size: 18px;
      }
    }
    &.account{
        
        .icon{
            background: url(./images/account.svg) center no-repeat;
            background-size: 16px;
        }
        }

    &.logout{
    .icon{
        background: url(./images/logout.svg) center no-repeat;
        background-size: 16px;
    }
    }
  }

  &.confirm{
      background: $main-color;
  }

  &.decline{
      background: $red-color;
  }

  &:hover{
      color: white;
      box-shadow:  0px 2px 7px 1px #dfdfdf;
  }
}

.default-empty-divider{
    height: 1px;
    margin:1rem 0 1rem;
    background: rgba(0, 0, 0, 0.10);
    width: 100%;
    display:block;
}

  .popup-wrapper{
      position: fixed;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      display:flex;
      z-index: 999;
      opacity: 0;
      visibility: hidden;

      &.show{
          opacity: 1;
          visibility: visible;
      }
      .overlay-back{
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0,0,0,0.5);
      }

      .popup-box{
          margin:auto;
          border-radius: 4px;
          background: white;
          z-index: 999;
          padding:2rem;
          max-width: 450px;
          .text{
              font-size: 1rem;
              font-weight: 500;
              text-align: center;
              color: $second-color;
          }

          .button-area{
              margin:2rem auto 0;
              display:flex;
              .button{
                  margin:auto;
                  color: white;
                  padding: .9rem 1.4rem;
                  svg{
                      font-size: .9rem;
                      position: relative;
                      top:-.05rem;
                      margin-right: .2rem;
                  }
                  .confirm{}

                  .decline{}
              }
          }
          &.popup-edit-filter{
              width: 40%;
              .text{
                  width: 100%;
                  display: block;
              }
        label{
            margin-top: 1rem;
            font-size: .8rem;
            color: $second-color;
            border:none;
            font-weight: 500;
            padding: .8rem .75rem;
        }
          input{
            width: 100%;
            margin-top:.6rem;
            border-radius:4px;
            background: white;
            border:1px solid rgba(0,0,0,.45);
            padding: 1rem 1.2rem;
            color: $second-color;
            font-size: 1rem;

            &:focus{
                outline:none;
                box-shadow:  -3px 13px 24px -10px rgba(0,0,0,.13);
            }
          }
        }
      }
  }
}

.admin{
    .pagination-wrapper{
        display: flex;
        margin:3rem 0;
        .pagination{    
            &.pagination-box{
                border:1px solid #e5e5e5;
                margin:auto 0 auto auto;
                display: inline-flex;
                li{
                    list-style: none;
                    a{
                        border-right: 1px solid #e5e5e5;
                        color: #666666;
                        padding: .6rem 1rem;
                        font-size: .9rem;
                        font-weight: 500;
                        display:inline-block;
                       
                        &:hover{
                            background: #823362; /* fallback for old browsers */
                            background: -webkit-linear-gradient(to right, #823362, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
                            background: linear-gradient(to right, #823362, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                            color: white;
                        }
                    }
                    &.active{
                    a{
                        background: #823362; /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #823362, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #823362, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                        color: white;
                    }
                    }
                    &.disabled{
                        a{
                            color: $grey-color;
                            background: $background-main;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
    .content li + li{
        margin-top: 0;
    }
}


@-webkit-keyframes slide {
    100% { transform:translateX(0); }
}

@keyframes slide {
    100% { transform:translateX(0); }
}