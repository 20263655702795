//Google fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Encode+Sans+Condensed:300,500,700&subset=latin-ext');

//Colors
$main-color: #00E9D4;
$main-color-hover: #05D9C6;
$second-color: #330a46;
$second-color-hover: #420d5a;
$background-main: #F5F7FA;
$light-grey-back: #f2f2f2;
$grey-color: #96A0A7;
$cta-color: #C8E351;
$cta-color-hover: #98B612;
$text-color: #707070;
$blue-color: #2D98DA;
$orange-color: #F7B731;
$red-color: #FC5C65;
$light-blue-color: #5781a3;
$dark-blue-color: #0c4a8a;
$yellow-color: #d7eb2c;
$light-green-color: #8dcfc0;
$dark-green-color: #126d1b;
$purple-color: #d1b8e0;
$pink-color: #e0108a;
$orange-color: #eb7043;
$text-black: #2b2b2b;
$green-success: #1cbb5f;

//Fonts
$main-font: 'Montserrat', sans-serif;
$second-font: 'Encode Sans Condensed', sans-serif;

//Category colors
$zdravlje-color: #4a82a6;
$ljepota-i-njega-color:#d6b6e3;
$ishrana-color: #78d1c0;
$drogerija-color: #a8acab;
$aparati-i-pomagala-color: #ce9393;

//Responsive breakpoints
$fullhd: 1480px;
$widescreen: 1216px;
$desktop: 1024px;
$tablet: 769px;
$mobile: 480px;
@media screen and (min-width: $fullhd){}
@media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){}
@media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){}
@media screen and (min-width: $tablet) and (max-width:$desktop - 1px){}
@media screen and (min-width: $mobile) and (max-width:$tablet - 1px){}
@media screen and (max-width:$mobile - 1px){}
@media screen and (max-width:$tablet - 1px){}