.admin{
    .page-content{
        margin-left: 320px;
        margin-top: 70px;
        background: #f5f5f5;
    }
    .content-page-section{
        .container{
            max-width: 100%;
            width: 100%;
        }
    }
    .dashboard{
        padding: 0;
        .container{
            max-width: 100%;
            width: 100%;
            margin-top: 2rem;
            .subtitle{
                font-size: .9rem;
                text-transform: uppercase;
                color: $second-color;
                font-weight: 700;
                margin-bottom: 1rem;
                &.with-padding{
                    padding-left:2rem;
                }
            }
            .last-added-product{
                margin:0 2rem 2rem;
                .inner{
                    background: white;
                    box-shadow: 0 0 0.25rem rgba(108,118,134,.1);
                    border:1px solid #e7e7e7;
                    padding: 1rem;
                    width:calc(100% - 1rem);
                    display:flex;
                    .product-name{
                    color: $second-color;
                    font-size: .9rem;
                    text-transform: none;
                    font-weight: 500;
                    display:block;
                    .date{
                        display: block;
                        color: #ababab;
                        font-size: .8rem;
                        margin-top: .3rem;
                    }
                    }
                    .edit-product{
                        color: $second-color;
                        font-size: .85rem;
                        font-weight: 700;
                        margin:auto 0 auto auto;
    
                    }
                }
               
            }
           .top-infos{
               width: 100%;
               margin:0;
               padding: 0 2rem 1.4rem;
               .column{
                   padding-top: 0;
                   &:first-child{
                       padding-left: 0;
                   }
                   .inner{
                    border-radius: 4px;
                    padding:0;
                    .title{
                        color:white;
                        text-transform: uppercase;
                        font-size: 1rem;
                        opacity: .8;
                        padding:1rem 1rem 0;
                        margin-bottom: 0;
                    }
                    .result{
                        font-size: 2.4rem;
                        color: white;
                        font-weight: 700;
                        padding:.8rem 1rem;
                    }
                    .subtitle{
                        background: rgba(255,255,255,.1);
                        color: white;
                        font-size: .95rem;
                        margin-bottom: 0;
                        font-weight: 400;
                        text-transform: none;
                        padding:.6rem 1rem;
                        
                    }
                    &.first{
                        background: #06beb6;  /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #48b1bf, #06beb6);  /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #48b1bf, #06beb6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                        }
                       &.second{
                        background: #ff9966;  /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #ff5e62, #ff9966);  /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #ff5e62, #ff9966); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                  
                       }
                       &.third{
                        background: #74ebd5;  /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #ACB6E5, #74ebd5);  /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #ACB6E5, #74ebd5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                  
                       }
                       &.fourth{
                        background: #667db6;  /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);  /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                       }
                   }  
               }
           }
           .main-dashboard{
            padding:0 2rem 1.4rem;
            width: 100%;
            margin:0;
               .order-table{
                   .reactable-filterer, .reactable-pagination{
                       display:none;
                   }
                   .reactable-column-header{
                    box-shadow: 0 0 0.25rem rgba(108,118,134,.1);
                       th{
                           font-size: .8rem;
                       }
                   }
                   .reactable-data{
                    box-shadow: 0 0 0.25rem rgba(108,118,134,.1);
                       td{
                           font-size: .8rem;
                           .options-wrapper{
                               .inner{
                                   .button{
                                        background: #ad5389; /* fallback for old browsers */
                                        background: -webkit-linear-gradient(to right, #ad5389, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
                                        background: linear-gradient(to right, #ad5389, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                   }
                               }
                           }
                       }
                   }
               }
           }
          .bulk-add{
              padding: 0rem 3rem;
              display: flex;
              margin-bottom:2rem;
              margin-top:1rem;
              input {  
                  height: 100%;
                  margin:auto 3rem auto 0;
                  height: unset;
            }
            label{
                display: flex;
            }
            input:focus + label,
            input + label:hover {
            }
            input + label {
                cursor: pointer; /* "hand" cursor */
            }

            .button{
                padding: .6rem 3rem;
                &::before{
                    content: none;
                }
            }
          } 
          .import-finished{
              padding: 0 3rem;
            p{
                background: $main-color;
                width: 100%;
                display: block;
                margin: 1rem 0;
                padding: .6rem;
                border-radius: 3px;
                font-size: 1rem;
                color: white;
            }
          } 
          form{
              margin-left: 3rem;
          }
        }
    }
    .section{
        padding:1.5rem;
        font-weight: 500;
    .container{
        .page-header{
            display:flex;
            margin-top: 1rem;
            margin-bottom: .6rem;
            .page-title{
                font-size: 1.05rem;
                color: $second-color;
                text-transform: uppercase;
                font-weight: 700;
                margin:auto 0;
            }
            .link-back{
                font-size: 1rem;
                color: $grey-color;
                .icon{
                    background:$main-color;
                    padding: 1rem;
                    border-radius: 90px;
                    margin-right: 1rem;
                    width: 40px;
                    height: 40px;
                    box-shadow: 0px 0px 9px 0px #e4e4e4;
                    position: relative;
                    &:before{
                        content:"";
                        background:$main-color url(./images/arrow-left-white.svg) center no-repeat;
                        background-size:16px;
                        position: absolute;
                        margin:auto;
                        width: 16px;
                        height: 16px;
                        display:block;
                    }
                }
            }
            .status{
                background: white;
                box-shadow: 0px 0px 9px 0px #e4e4e4;
                text-transform: uppercase;
                color: $grey-color;font-size: .8rem;
                border-radius: 7px;
                padding: 0 1rem;
                font-size: .7rem;
                font-weight: 500;
                height: 1.8rem;
                line-height: 1.8rem;
                margin:auto 0;
            }
        }

        .breadcrumbs{
            background: white;
            margin-bottom: 1rem;
            width: max-content;
            padding: .5rem .8rem;
            a{
                font-size: .72rem;
                color: $second-color;
                font-weight: 700;
            }
            span{
                margin:0 .4rem;
                color: #666666;
                font-size: .72rem;
                font-weight: 500;
            }
        }

        .content{
            .default-list{
                margin-top:0;
                width: 57%;
                .inner-box{
                    margin-bottom: 1.6rem;
                }

                &.tags-list{
                    .inner-box{
                        padding:0 .75rem;
                    }
                }
                .single-row{
                    background: white;
                    display:flex;
                    padding:.6rem 1rem;
                    background: white;
                    border:1px solid rgba(0,0,0,.09);
                    margin:.3rem 0;

                    .image{
                        img{
                            width: 50px;
                            background-size: contain;
                        }
                    }
                    .title{
                        color: $second-color;
                        font-size: .9rem;
                        font-weight: 700;
                        margin:auto .6rem;
                        padding-left:0;
                        position: relative;
                        display:flex;
                    }

                    .options{
                        background:transparent;
                        display:flex;
                        .link{
                            margin:auto .4rem;
                            cursor: pointer;
                            border:none;
                            background:transparent;
                            svg{
                                font-size: 1rem;
                                color: $second-color;
                            }
                            &:hover{
                                &:before{
                                    display:none;
                                }
                            }
                        
                            &::last-child{
                                margin-right: 0;
                            }
                        }
                    }
                    &.subcategory{
                        background:transparent;
                        padding: .4rem 1rem .4rem 2.4rem;
                        border-radius: 0;
                        border:1px solid rgba(0,0,0,.09);
                        border-top:0;
                        .title{
                            font-weight: 500;
                            &::before{
                                display: none;
                            }
                        }
                        &:before{
                            content:"";
                            position: relative;
                            width: 20px;
                            height: 1px;
                            left:0;
                            display:block;
                            margin:auto .4rem auto 0;
                            background: $second-color;
                        }
                    }
                }
            }
            .category-list{
                .single-row{
                    margin:0;
                    .image{
                        border-radius: 4px;
                        background: #ad5389; /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #ad5389, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #ad5389, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                        img{
                            padding: .6rem;
                        }
                    }
                }
            }
        }
        .meta-info{
            display:flex;
            background: white;
            box-shadow: 0 0 0.25rem rgba(108,118,134,.1);
            border:1px solid #e7e7e7;
            .default-text{
                padding: 1rem;
                font-size: .9rem;
                border-right: 1px solid #f4f4f4;
                color: $second-color;
            }
        }
    }
}

.default-table{
    .table-header{
        background: $main-color;
        border-radius: 7px 7px 0 0;
        .column{
            border-right:1px solid rgba(255,255,255,.5);
            &:last-child{
                border-right: 0;
            }
            p{
                text-align: center;
                color: white;
                font-size: 1rem;
            }
        }
        &:not(.is-desktop){
            margin-bottom: 0;
        }
    }

    .columns-wrapper{
        background: $background-main;
    .table-single-row{
        background:transparent;
        height: 85px;
        border:1px solid rgba(0,0,0,.07);
        border-bottom: 0;
        &:nth-child(odd){
            background: white;
        }
        &:last-child{
            border-radius: 0 0 7px 7px;
            border-bottom: 1px solid rgba(0,0,0,.07);
        }
        .column{
            border-right: 1px solid rgba(0,0,0,.07);
            display:flex;
            flex-wrap: wrap;
            align-items: stretch;
            &:last-child{
                border-right: 0;
            }
            &.image{
                a{
                    margin:auto;
                    img{
                        height: 60px;
                        width: auto;
                        border-radius: 7px;
                    }
                }
            }

            &.default{
                margin:auto;
                height: 100%;
                p,a{
                    color: $second-color;
                    font-size: .95rem;
                    margin:auto;
                    font-weight: 500;
                }
            }

            &.options{
                margin:auto;
                .options-wrapper{
                    width: 100%;
                    display:block;
                    .inner{
                        display:flex;
                        margin:auto;
                        width: fit-content;
                        .button{
                            padding: .3rem 1rem;
                            margin:auto .4rem;
                            font-size: .75rem;
                            font-weight: 500;
                            text-transform: uppercase;
                            &.blue{
                                background: $blue-color;
                            }
        
                            &.red{
                                background: $red-color;
                            }
        
                            &.orange{
                                background: $orange-color;
                            }
                        }
                    }
                   
                }
               
              
            }
        }
        &:not(.is-desktop){
            margin-bottom: 0;
        }
    }
    }
}

.default-table{
    &.products-table{
        thead{
            .reactable-column-header{
                th{
                    &.reactable-th-options{
                        width: 20%;
                    }
                    &.reactable-th-date{
                        width: 10%;
                    }
                    &.reactable-th-category{
                        width: 10%;
                    }
                }
            }
        }
        .reactable-data{
         
            tr{
               
                td{
                    &[label=Opcije]{
                        width: 20%;
                      
                    }
                    &[label="Datum objave"]{
                        width: 10%;
                     }
                    &[label=Kategorija]{
                        width: 10%;
                    }  
                }
            }
        }
    }
}

.default-table{
    width: 100%;
    display:block;
    margin-top: 2rem;
    thead{
        display:block;
        width: 100%;
        .reactable-column-header{
            background: #f1f1f1;
            border:none;
            padding: 0;
            display:flex;
            width: 100%;
            border:1px solid rgba(0,0,0,.08);
            box-shadow: 0 0 0.25rem rgba(108,118,134,.1);
            th{
                border-right:1px solid rgba(0,0,0,.07);
                border-bottom: 0;
                text-align: left;
                color: $second-color;
                font-size: .8rem;
                font-weight: 700;
                padding: .6rem 0 .6rem .4rem;
                &:last-child{
                    border-right: 0;
                }
            &.reactable-th-id, &.reactable-th-orderid{
                width: 5%;
            }
            &.reactable-th-image{
                width: 7%;
            }
            &.reactable-th-status{
                width: 13%;
            }
            &.reactable-th-price{
                width: 10%;
            }
            &.reactable-th-date, &.reactable-th-category, &.reactable-th-charge{
                width: 15%;
            }
            &.reactable-th-title{
                width: 25%;
            }
            &.reactable-th-date{
                width: 15%;
            }
            &.reactable-th-options{
                width: 10%;
            }
            &.reactable-th-name{
                width: 20%;
            }
            &.reactable-th-page_title{
                width: 80%;
            }
            &.reactable-th-options_extend{
                width: 20%;
            }
            &.reactable-th-ordername{
                width: 20%;
                border-radius: 0px 0 0 0;
            }
            &.reactable-th-totalprice{
                width: 10%;
            }

            &.reactable-header-sortable{
                cursor: pointer;
                &:after{
                    content:"";
                    width: 12px;
                    height: 14px;
                    position: relative;
                    display: inline-block;
                    margin-left: .5rem;
                    background:url(./images/sort.svg) center no-repeat;
                    background-size: contain;
                    top:.1rem;
                }
            }
            &.reactable-header-sort-asc, &.reactable-header-sort-desc{
                background: #e7e6e6;
            }
            &:focus{
                outline: none;
            }
            }
        }
        .reactable-filterer{
            width: 50%;
            display: block;
            margin:3rem 0 1.6rem;
            td{
                border-bottom: 0;
                width: 100%;
                padding: 0;
                margin-bottom: 1rem;
                display: flex;
                position: relative;
                z-index: 9;
                &:before{
                    content:"Pretraga po ključnim riječima";
                    position: relative;
                    top:0;
                    left:0;
                    margin:auto 0;
                    display:flex;
                    font-size: .8rem;
                    height: 100%;
                    line-height: 2.4rem;
                    color: $second-color;
                    z-index: 999;
                    margin-right: .6rem;
                }
                input{
                    width: 300px;
                    margin:auto 0;
                    display:block;
                    padding: 0 1rem;
                    height: 2.4rem;
                    border-radius: 4px;
                    font-size: 1rem;
                    color: $second-color;
                    background: white;
                    position: relative;
                    border:1px solid #e7e7e7;
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
    }
    
    .reactable-data{
        display:block;
        width: 100%;
        background: $background-main;
        border:1px solid rgba(0,0,0,.08);
        tr{
            display:flex;
            width: 100%;
            background:transparent;
            height: 55px;
            border-bottom: 1px solid rgba(0,0,0,.09);
            &:nth-child(odd){
                background: white;
                
            }
            &:last-child{
                border-radius: 0 0 7px 7px;
              
            }
            td{
                border:none;
                border-right: 1px solid rgba(0,0,0,.07);
                display:flex;
                flex-wrap: wrap;
                align-items: center;
                color: $second-color;
                font-size: .8rem;
                margin:0;
                padding: 0 .4rem 0 .4rem;
                font-weight: 500;
                &:last-child{
                    border-right: 0;
                }
                &[label=ID]{
                   width: 5%;
                   font-weight: 700;
                   text-align: center;
                }
                &[label=Status]{
                    width: 13%;
                 }
                &[label=Slika]{
                    width: 7%;
                    img{
                        height: 50px;
                        width: auto;
                        border-radius: 0;
                        margin:auto;
                    }
                 }
                 &[label=Naziv]{
                    width: 25%;
                 }
                 &[label="Datum objave"]{
                    width: 15%;
                 }
                 &[label="Ime i prezime"]{
                     width: 20%;
                 }
                 &[label=Kategorija]{
                    width: 15%;
                 }
                 &[label=Cijena]{
                     width: 10%;
                 }
                  &[label=Datum]{
                    width: 15%;
                 }
                 &[label="Način plaćanja"]{
                    width: 15%;
                 }
                 &[label="Broj narudžbe"]{
                    width: 20%;
                 }
                 &[label="Naziv stranice"]{
                    width: 80%;
                 }
                 &[label="Opcije stranice"]{
                    width: 20%;
                 }
                 &[label=Opcije]{
                    width: 10%;
                    margin:auto;
                    padding: 0;
                 }

                    .options-wrapper{
                        width: 100%;
                        display:block;
                        position: relative;
                        .inner{
                            display:flex;
                            margin:auto 0;
                            width: fit-content;
                            .button{
                                padding: .3rem .7rem;
                                margin:auto .3rem;
                                font-size: .7rem;
                                font-weight: 500;
                                text-transform: uppercase;
                                &.blue{
                                    background: $blue-color;
                                }
                                &.green{
                                    background: $dark-green-color;
                                }
                                &.red{
                                    background: $red-color;
                                }
            
                                &.orange{
                                    background: $orange-color;
                                }
                            }
                            .link{
                                color: $second-color;
                                background: transparent;
                                border:none;
                                margin:auto .4rem;
                                cursor: pointer;
                                svg{
                                   font-size: 1rem;
                                }
                            }
                        }
                        &:after{
                            content:"";
                            width: 1rem;
                            height: 1rem;
                            border-radius: 90px;
                            position: absolute;
                            right: 0;
                            top:5px;
                            display:block;
                            margin:auto 1rem auto auto;
                        }
                        &.published{
                            &:after{
                            background: $light-green-color;
                            }
                        }
                        &.draft{
                            &:after{
                            background: $yellow-color;
                            }
                        }
                        &.unpublished{
                            &:after{
                            background: $red-color;
                            }
                        }
                    } 
            }
        }
    }

    .reactable-pagination{
        margin:3rem 0 auto auto;
        display:flex;
        tr{
            display:block;
            margin:auto 0 auto auto;
            border:1px solid #e5e5e5;
            td{
                a{
                    border-right: 1px solid #e5e5e5;
                    color: #666666;
                    padding: .6rem 1rem;
                    font-size: .9rem;
                    font-weight: 500;
                    display:inline-block;
                    &:last-child{
                        border-right: 0;
                    }
                    &:hover{
                        background: #823362; /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #823362, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #823362, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                        color: white;
                    }
                    &.reactable-current-page{
                        background: #823362; /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #823362, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #823362, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                        color: white;
                    }
                }
            }
        }
    }
}

.order-table{
    .reactable-column-header{
        th{
            &.reactable-th-options{
            }
        }
    }
    .reactable-data{
        tr{
            position: relative;
            td{
                &[label=Cijena]{
                    width: 10%;
                }
                &[label=Opcije]{
                }
                &[label=Boja]{
                   span{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    &.unreaded{
                        background: $cta-color;
                       }
                   }
                  
                }
            }
        }
    }
}
}

.users-table{
    .reactable-column-header{
        th{
            &.reactable-th-name, &.reactable-th-address, &.reactable-th-email, {
                width: 15%;
            }
            &.reactable-th-phone, &.reactable-th-totalpoints{
                width: 10%;
            }
        }
    }
    .reactable-data{
        tr{
            position: relative;
            td{
                &[label="Ime i prezime"], &[label=Adresa],  &[label="Datum registracije"],  &[label=Email]{
                    width: 15%;
                }
               &[label=Telefon], &[label="Broj bodova"]{
                    width: 10%;
                }
            }
        }
    }
}