/*
Helper classes used in administration and shop
1. Helper classes
2. Sticker
3. Loader box
*/

body{
    font-family: $main-font;
}

html {
    scroll-behavior: smooth;
  }
  
a, div, p, h1,h2,h3,h4,h5,h6,img,i, span, button,input, textarea, svg{
    -webkit-transition: all .25s cubic-bezier(.46,.82,.61,.87);
    -moz-transition: all .25s cubic-bezier(.46,.82,.61,.87);
    -o-transition: all .25s cubic-bezier(.46,.82,.61,.87);
    transition:all .25s cubic-bezier(.46,.82,.61,.87);
}

//1. Default, simple CSS class
.is-left{
    margin:auto 0;
}

.is-right{
    margin:auto 0 auto auto;
}

.padding-0{
    padding: 0;
}

.padding-main{
    padding:8rem 0;
}

.padding-side-0{
    padding-left:0;
    padding-right: 0;
}

.margin-0{
    margin:0;
}

.area-left{
    margin:auto 0;
}

.area-right{
    margin:auto 0 auto auto;
}

.button{
    border-radius: 0;
    border:none;
}

::selection{
    background: $second-color;
    color: white;
}

::placeholder{
    color:#BABABA;
}

.columns:last-child{
    margin-bottom: 0;
}

.tile.is-ancestor:last-child{
    margin-bottom: 0;
}

.is-hidden{
    display:none;
}

input{
    font-family: $main-font;
}

  button{
    &:focus{
        outline:none;
    }
  }

//2. Sticker for admin when some action is finished
  .sticker{
    position: fixed;
    right:2rem;
    top:2rem;
    border-radius: 7px;
    width: 400px;
    box-shadow: 0px 0px 5px 0px #706a6a;
    padding: 2rem;
    transform:translateX(40%);
    -webkit-animation: slide 0.2s forwards;
    animation: slide 0.2s forwards;
    z-index: 1020;
    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
        width: 300px;
        padding: 1rem;
    }
    @media screen and (max-width:$desktop - 1px){
        width: 270px;
        padding: 1rem;
    }
    .inner{
        display:flex;
        .text-box{
           padding-right: 2rem;
           @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
               padding-right: 1rem;
           }
           @media screen and (max-width:$desktop - 1px){
               padding-right: .9rem;
           }
            p{
                color: white;
                font-size: 1rem;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: .9rem;
                }
                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                    font-size: .8rem;
                }
                @media screen and (max-width:$desktop - 1px){
                    font-size: .7rem;
                }
            }
        }
        .icon-box{
            i{
                background:url(./images/sticker-check.svg) center no-repeat;
                background-size: cover;
                display:block;
                width: 2.4rem;
                height: 2.4rem;
                padding: 0;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    width: 2.2rem;
                    height: 2.2rem;
                }
                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                    width: 2rem;
                    height: 2rem;
                }
                @media screen and (max-width:$desktop - 1px){
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }
        }
    }
    &.success{
        background: $green-success;
        .inner{
            .text-box{
            }
            .icon-box{
                i{
                    background:url(./images/sticker-check.svg) center no-repeat;
                    background-size: cover;
                }
            }
        }
    }
    &.danger{
        background: $red-color;
        .inner{
            .text-box{
            }
            .icon-box{
                i{
                    background:url(./images/sticker-danger.svg) center no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}

//3. Default loader box for shop and CMS
.loader-box{
    position: fixed;
    width: 100%;
    left: 0;
    height: 100%;
    top:5%;
    background: white;
    top:0;
    display:flex;
    margin:auto;
    background: white;
    z-index: 990;
    .loader {
        width: 50px;
        height: 50px;
        display:block;
        margin: auto;
        border: 3px solid $main-color;
        border-right-color: transparent;
        border-top-color: transparent;
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            width: 40px;
            height: 40px;
        }
        @media screen and (max-width:$mobile - 1px){
            width: 30px;
            height: 30px;
        }
      }
      &.loader-box-subcategory{
          position: absolute;
          .loader{
              margin:5rem auto;
          }
      }
}

.popup-success{
    .loader{
        width: 30px;
        height: 30px;
    }
}

.is-mobile{
    display:none;
    @media screen and (max-width:$tablet - 1px){
        display:block;
    }
}

.is-desktop{
    @media screen and (max-width:$tablet - 1px){
        display:none;
    }
}

.display-none{
    display: none;
}