/*
Footer for all pages, with three parts
  1. Main footer: class - {main}
  2. SEO text: class - {seo-section}
  3. Copyright: class - {bottom}
*/

.shop{
    .footer{
        background: #F9F9F9;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
            padding-top: 0;
        }
        @media screen and (max-width:$tablet - 1px){
            position: relative;
            z-index: 999;
        }
        @media screen and (max-width:$mobile - 1px){
            padding-top: 2rem;
        }

//1. Main footer with logo and footer menu, first part of footer
        .main{
            padding-top:1rem;
            padding-bottom: 1rem;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                padding-top: 0;
                padding-bottom: 0;
            }
            @media screen and (max-width:$mobile - 1px){
                padding-top: 0;
                padding-bottom: 0;
            }
            .container{
                @media screen and (max-width:$widescreen - 1px){
                    padding: 0 1.5rem;
                }
                .columns{
                    @media screen and (max-width:$widescreen - 1px){
                        margin: 0 !important;
                    }
                    @media screen and (max-width:$tablet - 1px){
                        display:flex;
                    }
                    .column{
                        @media screen and (max-width:$tablet - 1px){
                            width: 50%;
                            min-width: 50%;
                        }
                        &.area-left{
                            @media screen and (max-width:$tablet - 1px){
                                width: 50%;
                                min-width:50%;
                            }
                            
                            .logo{
                                img{
                                    width: 200px;
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        width: 160px;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        width: 130px;
                                    }
                                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                        width: 130px;
                                    }
                                    @media screen and (max-width:$mobile - 1px){
                                        width: 110px;
                                    }
                                }
                            }
                            .social-box{
                                display: none;
                                @media screen and (max-width:$tablet - 1px){
                                    display:inline-flex;
                                    margin-left: 1.5rem;
                                    margin-top: 0rem;
                                    position: relative;
                                    top:-.3rem;
                                }
                            }
                            .support-box{
                                display:flex;  
                                margin: 1.6rem 0;
                                @media screen and (max-width:$mobile - 1px){
                                    margin-top:.8rem;
                                }
                                .help{
                                    background: url(./images/help-icon.svg) center no-repeat;
                                    background-size: contain;
                                    width: 60px;
                                    height: 60px;
                                    display:block;
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        width: 46px;
                                        height: 46px;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        width: 40px;
                                        height: 40px;
                                    }
                                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                        width: 50px;
                                        height: 50px;
                                    }
                                    @media screen and (max-width:$mobile - 1px){
                                        width: 50px;
                                        height: 50px;
                                        display:none;
                                    }
                                }
                                .content-box{
                                    margin-left:1rem;
                                    @media screen and (max-width:$mobile - 1px){
                                        margin-left: 0;
                                    }
                                    p{
                                        color: $second-color;
                                        font-weight: 500;
                                        font-size: 1rem;
                                        margin-bottom: .3rem;
                                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                            font-size: .9rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: .9rem;
                                        }
                                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                            font-size: .9rem;
                                        }
                                        @media screen and (max-width:$mobile - 1px){
                                            font-size: 1rem;
                                        }
                                    }
                                    a{
                                        font-size: 1.8rem;
                                        color: $second-color;
                                        font-weight: 500;
                                        line-height: 2.2rem;
                                        display:block;
                                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                            font-size: 1.6rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: 1rem;
                                            line-height: 1.2rem;
                                        }
                                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                            font-size: 1.6rem;
                                            line-height: 2rem;
                                        }
                                        @media screen and (max-width:$mobile - 1px){
                                            font-size: .8rem;
                                            line-height: 1.3rem;
                                        }
                                    }
                                    .social-box{
                                        @media screen and (max-width:$tablet - 1px){
                                            display:none;
                                        }
                                    }
                                }
                            }
                        }

                        &.is-6{
                            &.is-mobile{
                                .social-box{
                                    margin-top: 0;
                                }
                            }
                        }

                        &.footer-menu{
                            margin-top:0;
                            .title{
                                font-size: .95rem;
                                color: $second-color;
                                font-weight: 700;
                                margin-bottom: .4rem;
                                text-transform: uppercase;
                                @media screen and (min-width: $tablet) and (max-width:$widescreen - 1px){
                                    font-size: .85rem;
                                    margin-bottom: .4rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .75rem;
                                    margin-bottom: .4rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: .9rem;
                                    margin-bottom: 0rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .85rem;
                                    margin-bottom: 0;
                                    font-weight: 700;
                                }
                            }

                            ul{
                                @media screen and (max-width:$tablet - 1px){
                                    margin:.4rem 0;
                                }
                                li{
                                    a{
                                        color: $second-color;
                                        font-size: .85rem;
                                        font-weight: 500;
                                        padding: .15rem 0;
                                        display:block;
                                        @media screen and (min-width: $tablet) and (max-width:$widescreen - 1px){
                                            font-size: .85rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: .75rem;
                                        }
                                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                            font-size: .9rem;
                                        }
                                        @media screen and (max-width:$mobile - 1px){
                                            font-size: .85rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
//2. SEO text, middle part of footer
        .seo-section{
            background: $light-grey-back;
            padding: 3rem 0;
            .container{
                @media screen and (max-width:$widescreen - 1px){
                    padding:0 1.5rem;
                }
                .title{
                    font-size: .85rem;
                    font-weight: 600;
                    color: $second-color;
                    margin-bottom: .3rem;
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .75rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .75rem;
                    }
                }
                .text{
                    font-size: 0.85rem;
                    font-weight: 400;
                    color: $second-color;
                    margin-bottom: 1rem;
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .75rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .75rem;
                    }
                }
                a{
                    color: $cta-color-hover;
                    font-weight: 700;
                }
                ul{
                    margin:-.6rem 0 1.4rem;
                    width: 60%;
                    li{
                        padding:0;
                        a{
                            font-size: .85rem;
                            font-weight: 700;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .75rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: .75rem;
                            }
                        }
                    }
                }
            }
        }

//3. Copyright, bottom part of footer
        .bottom{
            background: #EAEAEA;
            padding: 1.6rem 0;
            .container{
                display:flex;
                @media screen and (max-width:$widescreen - 1px){
                    padding:0 1rem;
                }
                @media screen and (max-width:$tablet - 1px){
                    display:block;
                }
                p{
                    font-size: .9rem;
                    font-weight: 400;
                    color: $second-color;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: .85rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .75rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        font-size: .85rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .75rem;
                    }
                    a{
                        color: $second-color;
                        &:hover{
                            color: $cta-color-hover;
                        }
                    }
                }
                .is-left{

                }
            }
        }
    }
}