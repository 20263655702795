.admin{
    .main-header{
        background: #ad5389; /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #ad5389, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #ad5389, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        height: 70px;
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        z-index: 99;
        .container{
            display:flex;
            margin:auto 2rem !important;
            height: 100%;
            .logo{
                display:flex;
                width: 255px;
                a{
                    line-height: 0;
                    height: auto;
                    margin:auto 0;
                    display:block;
                    img{
                        margin:auto 0;
                        height: 35px;
                    }
                }
            }
            .header-menu{
                display:flex;
                &.is-left{
    
                    .close-sidebar{
                        font-size: 1.6rem;
                        margin:auto;
                        color: white;
                        cursor:pointer;
                    }
    
                    
                }
                &.is-right{
    
                }
                .header-link{
                    color: white;
                    font-size: .8rem;
                    font-weight: 500;
                    margin:auto 0 auto 2.3rem;
                    &.logout{
                        cursor: pointer;
                    }
                    svg{
                        margin-left:.2rem;
                        font-size: .7rem;
                    }
                    &.my-account{
                        display:flex;
                        span{
                            margin:auto .4rem;
                        }
                        .avatar{
                            width: 44px;
                            height: 44px;
                            border-radius: 90px;
                            background: white url(../../static/profile-image.png) center no-repeat;
                            background-size: contain;
                            display:block;
                            border:1px solid white;
    
                        }
                    }
                }
            }
        }
    }
}