/*
Header for all pages
  1. Top header: class - {top}
  2. Main header: class - {main}
  3. Mobile: class - {mobile}
  4. Mini cart: class - {cart-header}
  5. Search result: class - {search-results}
*/

.shop{

    .user-header{
        background: $second-color;
        height: 2.1rem;
       .container{
        display:flex;
        height: 100%;
        .text{
            color: white;
            font-size: .8rem;
            display:flex;
            font-weight: 400;
            &.my-account{
                margin-right: 2rem;
            }
            &.logout{
                cursor: pointer;
            }
           .icon{
               width: .9rem;
               height: .9rem;
               display:block;
               margin-right: .4rem;
               position: relative;
               top:.1rem;
               &.my-account{
                   background: url(./images/user-menu-white.svg) center no-repeat;
                   background-size: contain;
               }
               &.logout{
                background: url(./images/logout.svg) center no-repeat;
                background-size: contain;
            }
           }
        }
        .is-right{
            display:flex;
        }
       }
    }

    .header{
    box-shadow: -3px 13px 24px -10px rgba(0,0,0,.3);
    z-index: 999;
    
    &.desktop{
        @media screen and (max-width:$tablet - 1px){
            display:none;
        }
    }

    //1. Top header, logo, search bar, info menu, cart icon
    .top{
        background: $main-color;
        padding: .4rem 0;
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            padding: .1rem 0;
        }
        @media screen and (max-width:$tablet - 1px){
            padding: 1rem 0;
        }
        .container{
            @media screen and (max-width:$desktop - 1px){
                padding: 0 1.5rem;
            }
            &.mobile{
                display:none;
                @media screen and (max-width:$tablet - 1px){
                    display:block;
                }
                .columns{
                    .column{
                        display:flex;
                    }
                }
            }
            &.desktop{
                @media screen and (max-width:$tablet - 1px){
                    display:none;
                }
            }
            .columns{
                width: 100%;
                
                //Logo - left part
                    .logo{
                        display:flex;
                        a{
                            margin:auto 0;
                            line-height: 0;
                            &:focus{
                                outline: none;
                            }
                            @media screen and (max-width:$tablet - 1px){
                                margin:auto;
                            }
                            img{
                                width: 75%;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    width: 75%;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    width: 85%;
                                }
                                @media screen and (max-width:$tablet - 1px){
                                    width: 80%;
                                    margin:auto;
                                }
                            }
                        }
                    }
                    .viber-call{
                        display: flex;
                        i{
                            margin: auto .6rem auto 0;
                            width: 1.8rem;
                            height: 1.8rem;
                            background: url(./images/viber_header_icon.svg) center no-repeat;
                            background-size: contain;
                            display: block;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                width: 1.4rem;
                                height: 1.4rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                width: 1.2rem;
                                height: 1.2rem;
                                margin-right: .4rem;
                            }
                        }
                        a{
                            font-size: .9rem;
                            font-weight: 700;
                            color: $second-color;
                            font-size: 1rem;
                            margin: auto 0;
                            text-align: left;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .85rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .7rem;
                            }
                        }
                    }

                    //Search bar - not used
                    .search-bar{
                        @media screen and (max-width:$tablet - 1px){
                            margin:1rem auto;
                        }
                        .inner{
                            width: 85%;
                            margin:auto;
                            height: 40px;
                            line-height: 40px;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                height: 36px;
                                line-height: 36px;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                height: 32px;
                                line-height: 32px;
                            }
                            button{
                                width: 32px;
                                height: 32px;
                                margin:auto 4px auto auto;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    height: 29px;
                                    width: 29px;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    height: 26px;
                                    height: 26px;
                                }
                            }
                            input{
                                width: 100%;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .85rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                }
                            }
                            .search-input{
                                width: 100%;
                            }
                        }

                    }

                    //Cart box - right part
                    .cart-box{
                        display:flex; 
                        .user-button{
                            font-family: $second-font;
                            color: $second-color;
                            font-size: .92rem;
                            font-weight: 700;
                            display: flex;
                            margin:auto 3rem auto auto;
                            background: $cta-color;
                            border-radius: 30px;
                            padding: .4rem 1.2rem;
                            .icon{
                                width: .9rem;
                                height: .9rem;
                                display:block;
                                margin-left: .4rem;
                                position: relative;
                                top:.2rem;
                            }
                            &.login{
                               .icon{
                                background: url(./images/user-login.svg) center no-repeat;
                                background-size: contain;
                               }
                            }
                            &.my-account{
                                .icon{
                                 background: url(./images/account-dark.svg) center no-repeat;
                                 background-size: contain;
                                }
                             }
                        }
                        .top-navbar{
                            display:flex;
                            margin-right: 3rem;
                            li{
                                position: relative;
                                padding: .5rem 0;
                                margin:auto 0 auto auto;
                                a{
                                    font-family: $second-font;
                                    font-size: .9rem;
                                    color: $second-color;
                                    text-transform: uppercase;
                                    font-weight: 500;
    
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        font-size: .85rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .8rem;
                                    }
            
                                    &.informations{
                                        position: relative;
                                        padding-left:22px;
                                        padding-right: 17px;
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            padding-left: 18px;
                                            padding-right: 14px;
                                        }
                                        &:before{
                                            content:"";
                                            position: absolute;
                                            left:0;
                                            top:.2rem;
                                            width: 15px;
                                            height: 15px;
                                            background: url(./images/info.svg) center no-repeat;
                                            background-size: 70%;
                                            border-radius: 90px;
                                            border:1.3px solid $second-color;
                                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                                top:.05rem;
                                            }
                                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                                width: 12px;
                                                height: 12px;
                                            }
                                        }
            
                                        &:after{
                                            content:"";
                                            position: absolute;
                                            right:0;
                                            top:.35rem;
                                            width: 9px;
                                            height: 9px;
                                            background: url(./images/down-arrow.svg) center no-repeat;
                                            background-size: contain;
                                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                                top:.25rem;
                                            }
                                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                                width: 8px;
                                                height: 8px;
                                                top:.25rem;
                                            }
                                        }
                                    }
                                
                                }
                                ul{
                                    position: absolute;
                                    top:100%;
                                    opacity: 0;
                                    visibility: hidden;
                                    transform: translateY(-10%);
                                    width: 170px;
                                    background: $second-color;
                                    z-index: 100;
                                    -webkit-transition: all .15s cubic-bezier(.46,.82,.61,.87);
                                    -moz-transition: all .15s cubic-bezier(.46,.82,.61,.87);
                                    -o-transition: all .15s cubic-bezier(.46,.82,.61,.87);
                                    transition:all .15s cubic-bezier(.46,.82,.61,.87);
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        width: 130px;
                                    }
            
                                    li{width:100%;
                                        padding: 0;
                                        a{
                                            width: 100%;
                                            display:block;
                                            padding: .6rem;
                                            font-size: .9rem;
                                            color:white;
                                            border-bottom: 1px solid rgba(255,255,255,.1);
                                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                                font-size: .85rem;
                                                padding: .5rem;
                                            }
                                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                                font-size: .75rem;
                                                padding: .4rem;
                                            }
                                            &:hover{
                                                background:$second-color-hover;
                                            }
                                        }
                                    }
                                }
                                &:hover ul{
                                    opacity: 1;
                                    visibility: visible;
                                    transform:translateY(0);
                                }
                            }
                        }

                        .inner{
                            display:flex;
                            margin:auto 0 auto auto;
                            cursor: pointer;
                        .quantity{
                            background: url(./images/shopping-cart-green.svg);
                            background-size: contain;
                            padding: 1.3rem;
                            background-repeat: no-repeat;
                            position: relative;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                padding: 1rem;
                            }
                            span{
                                position: absolute;
                                right:0%;
                                bottom:10%;
                                background: $cta-color;
                                color: $second-color;
                                font-size: .75rem;
                                border-radius: 90px;
                                width: 17px;
                                height: 17px;
                                line-height: 17px;
                                display: block;
                                text-align: center;
                                font-weight: 600;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    width: 18px;
                                    height: 18px;
                                    font-size: .75rem;
                                    line-height: 18px;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    width: 16px;
                                    height: 16px;
                                    font-size: .7rem;
                                    line-height: 16px;
                                }
                            }
                        }
                            .price{
                                font-size: 1.1rem;
                                color: $second-color;
                                margin:auto;
                                margin-left:1rem;
                                font-weight: 600;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: 1.1rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: 1rem;
                                    margin-left: .4rem;
                                }
                                span{
                                    font-weight: 400;
                                }
                            }
                        }
                    }
            }    
        }
    }

//2. Main header, category button, main menu
    .main{
        display:flex;
        background: white;
        .container{
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                width: 100%;
                padding: 0 0;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                width: 100%;
                padding: 0 1.5rem;
            }
            .columns{
                margin:0 !important;
                .column{
                    padding: 0;
                }
                //Category menu - left part
                .category-menu{
                    width: 23%;
                        li{
                            position: relative;
                            display:block;
                            height: 100%;
                            p{
                            text-transform: uppercase;
                            color: $second-color;
                            display: flex;
                            font-weight: 700;
                            font-size: .95rem;
                            padding-right: .4rem;
                            height: 100%;
                            border:3px solid white;
                            border-left:0;
                            cursor: pointer;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                font-size: .8rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .63rem;
                                padding: 0;
                                }
                            &:before{
                                    content:"";
                                    width: 2.7rem;
                                    height: 2.7rem;
                                    background: #ccc;
                                    position: relative;
                                    display: block;
                                    margin: auto 0;
                                    background: url(./images/menu.svg) left no-repeat;
                                    background-size: contain;
                                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                        width: 2.3rem;
                                        height: 2.3rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        width: 1.4rem;
                                        height: 1.4rem;
                                        }
                                }
                            span{
                                margin:auto 0;
                            }
                            &.category-icon{
                                background:$cta-color;
                            }
                            }
                            &:hover .first-child{
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    .first-child{
                        position: absolute;
                        background: $cta-color;
                        width: 100%;
                        padding: 0 .4rem .4rem;
                        opacity: 0;
                        top:100%;
                        left: 0;
                        visibility: hidden;
                        z-index: -999;
                        -moz-transition:opacity 0.2s 0.1s ease-out, visibility 0.1s 0.1s linear; 
                        -webkit-transition:opacity 0.3s ease-out, visibility 0.1s 0.1s linear;
                        -o-transition:opacity 0.3s ease-out, visibility 0.1s 0.1s linear;
                        transition:opacity 0.3s ease-out, visibility 0.1s 0.1s linear;
                        li{
                            display:block;
                            background: white;
                            width: 100%;
                            margin:auto;
                            border-bottom: 1px solid $light-grey-back;
                            -moz-transition:opacity 0.1s 0.15s ease-out, margin 0.3s 0.1s ease-out;
                            -webkit-transition:opacity 0.1s 0.15s ease-out, margin 0.3s 0.1s ease-out;
                            -o-transition:opacity 0.15s 0.15s ease-out, margin 0.3s 0.1s ease-out;
                            transition:opacity 0.15s 0.15s ease-out, margin 0.3s 0.1s ease-out;
                            a{
                                font-size: .9rem;
                                text-transform: none;
                                padding: 0;
                                font-weight: 500;
                                display:flex;
                                color: $second-color;
                                border:none;
                                line-height: 3.4rem;
                                height: 3.4rem;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .8rem;
                                    height: 3rem;
                                    line-height: 3rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .75rem;
                                    height: 2.8rem;
                                    line-height: 2.8rem;
                                    }
                                &:before{
                                    content:"";
                                    width: 3.4rem;
                                    height: 3rem;
                                    background: #ccc;
                                    position: relative;
                                    display: block;
                                    margin-right: .2rem;
                                    margin-left: 0;
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        width: 2.6rem;
                                        height: 2.3rem;
                                        top:0rem;
                                        }
                                }
                                &.zdravlje{
                                    &:before{
                                        background: url(./images/zdravlje-menu.svg) left no-repeat;
                                        background-size: contain;
                                    }
                                }
                                &.ljepota-i-njega{
                                    &:before{
                                        background: url(./images/ljepota-i-njega-menu.svg) left no-repeat;
                                        background-size: contain;
                                    }
                                }
                                &.sport-i-rekreacija{
                                    &:before{
                                        background: url(./images/sport-i-rekreacija-menu.svg) left no-repeat;
                                        background-size: contain;
                                    }
                                }
                                &.drogerija{
                                    &:before{
                                        background: url(./images/drogerija-menu.svg) left no-repeat;
                                        background-size: contain;
                                    }
                                }
                                &.aparati-i-pomagala{
                                    &:before{
                                        background: url(./images/aparati-i-pomagala-menu.svg) left no-repeat;
                                        background-size: contain;
                                    }
                                }
                            &.link-first-child{
                                &:hover{
                                    background: $light-grey-back;
                                  
                                }
                            }
                            }
                            &:last-child{
                                border-bottom: 0;
                            }
                           
                            &:hover .second-child{
                                opacity: 1;
                                height: auto;
                                visibility: visible;
                                transform: translateX(0);
                                &:before{
                                    border-left-color: $light-grey-back
                                }
                            }
                        }
                    }
                    
                    .second-child{
                        position: absolute;
                        left:100%;
                        top:0;
                        background: $cta-color;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateX(-10%);
                        width: inherit;
                        padding: .4rem 1.4rem;
                       // max-height: 350px;
                        overflow-y: auto;
                        max-height: 410px;
                        z-index: -2;
                        &::-webkit-scrollbar {
                            width: 4px;
                        }
                        
                        &::-webkit-scrollbar-track {
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                            border-radius: 4px;
                            background: rgba(0,0,0,0.04)
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            border-radius: 4px;
                            background: rgba(0,0,0,0.2);
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            padding: .4rem 1.4rem;
                            }
                        &:before{
                            content:"";
                            position: absolute;
                            left:0px;
                            top:1rem;
                            width: 10px; 
                            height: 0; 
                            border-top: 12px solid transparent;
                            border-bottom: 12px solid transparent;  
                            border-left: 12px solid white;
                            z-index: 999;
                        }
                        li{
                            background: transparent;
                            border-bottom: 0;
                            a{
                                color: $second-color;
                                line-height: normal;
                                height: unset;
                                font-size: .83rem;
                                border:none;
                                font-weight: 500;
                                padding: .5rem 0;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .71rem;
                                    padding: .3rem 0;
                                    }
                                &::before{
                                    display:none;
                                }
                                &:hover{
                                   text-shadow: 0px 0px 1px #000
                                }
                            }
                        }
                    }
                }
                //Search bar - middle part
                .search-bar{
                    margin:auto 0;
                    .inner{
                            background: $light-grey-back;
                            border-radius: 0px;   
                            border:2px solid white; 
                            .search-input{
                                height: 100%;
                                display: flex;
                                &::before{
                                    content: none;
                                    width: 1.4rem;
                                    height: 1.4rem;
                                    display: block;
                                    background: url(./images/search_icon.svg) center no-repeat;
                                    background-size: contain;
                                    margin: auto 0rem auto .6rem;
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        width: 1.1rem;
                                        height: 1.1rem;
                                    }
                                }
                                width: 100%;
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    display:flex;
                                }
                                input{
                                    width: 100%;
                                    padding-left: .6rem;
                                }
                            }                   
                    
                        .search-button{
                            width: 36px;
                            height: 34px;
                            margin: auto .5rem auto auto;
                            background: $cta-color url(./images/search_icon.svg); 
                            background-size: 55%;
                            background-repeat: no-repeat;
                            background-position: center;
                            border:none;
                            border-radius: 90px;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            width: 32px;
                            height: 30px;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                width: 26px !important;
                                height: 22px;
                                background-size: 45%;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                width: 30px;
                            }
                            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                height: 34px;
                                width: 36px;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                width: 34px;
                                height: 32px;
                            }
                        }
                    }
                }
            }

            //Main navbar - right part
            .navbar{
                margin:auto 0 auto auto;
                min-height: auto;
                width: unset;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    display:flex;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    display:flex;
                }
                li{
                    margin:auto 0;
                    display:inline-block;
                    a{
                        color: $second-color;
                        font-size: .85rem;
                        padding: .6rem 0;
                        margin:0 .9rem;
                        font-weight: 500;
                        margin-top:3px;
                        display: block;
                        border-bottom: 3px solid white;
                        @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                            margin: 0 .9rem;
                        }
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: .75rem;
                            padding: .5rem 0;
                            margin: 0 .5rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .6rem;
                            padding: .3rem 0;
                            margin:0 .3rem;
                            margin-top:3px;
                        }
                        &:hover{
                            text-shadow: 0px 0px 1px #000
                         }
                    }
                    &:last-child{
                        a{
                            padding-right: 0;
                            margin-right: 0;
                        }
                    }
                    ul{
                        position: absolute;
                        opacity: 0;
                        top:100%;
                        visibility: hidden;
                        border:2px solid $cta-color;
                        background: white;
                        z-index: -999;
                        -moz-transition:opacity 0.2s 0.1s ease-out, visibility 0.1s 0.1s linear; 
                        -webkit-transition:opacity 0.3s ease-out, visibility 0.1s 0.1s linear;
                        -o-transition:opacity 0.3s ease-out, visibility 0.1s 0.1s linear;
                        transition:opacity 0.3s ease-out, visibility 0.1s 0.1s linear;
                        
                        li{
                            display:block;
                            border-bottom: 1px solid $light-grey-back;
                            a{  
                                font-size: .85rem;
                                font-weight: 500;
                                margin:0;
                                border-bottom: 0;
                                padding: .6rem 1rem .6rem .8rem;
                            }
                        }
                    }
                    &:hover ul{
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                    }
                }
            }

        ul:before,
        ul:after {
        content: " "; /* 1 */
        display: table; /* 2 */
        }

        ul:after { clear: both; }
        }
    }

    
//3. Mobile menu style
    &.mobile{
        display:none;
        @media screen and (max-width:$tablet - 1px){
            display:block;
            position: relative;
        }

        .viber-call{
            display: flex;
            margin: auto;
            padding:0 0 .5rem 0;
            background: $main-color;
            i{
                margin: auto .4rem auto auto;
                width: 1.2rem;
                height: 1.2rem;
                background: url(./images/viber_header_icon.svg) center no-repeat;
                background-size: contain;
                display: block;
            }
            a{
                font-size: .7rem;
                font-weight: 700;
                color: $second-color;
                font-size: 1rem;
                margin: auto auto auto 0;
                text-align: left; 
            }
        }
    .top-header{
        background: $main-color;
        padding: .3rem 1rem;
        .container{
            display:flex;
            //Logo - left part
            .logo{
                width: 33%;
                display:flex;
                a{
                    display:block;
                    margin:auto;
                    img{
                        display:block;
                        margin:auto;
                    }
                }   
            }
            
            //Menu - right part
            .menu{
                display:flex;
                .cart-box{
                    display:flex;  
                    margin-right: .8rem; 
                    .inner{
                        display:flex;
                        margin:auto 0 auto auto;
                        cursor: pointer;
                    .quantity{
                        background: url(./images/shopping-cart-green.svg);
                        background-size: contain;
                        padding: 1.2rem;
                        background-repeat: no-repeat;
                        position: relative;
                        @media screen and (max-width:$mobile - 1px){
                            padding: 1rem;
                        }
                        span{
                            position: absolute;
                            right:0%;
                            bottom:10%;
                            background: white;
                            color: $cta-color;
                            font-size: .75rem;
                            border-radius: 90px;
                            width: 17px;
                            height: 17px;
                            line-height: 17px;
                            display: block;
                            text-align: center;
                            font-weight: 600;
                            @media screen and (max-width:$mobile - 1px){
                                width: 14px;
                                height: 14px;
                                line-height: 14px;
                                font-size: .7rem;
                            }
                        }
                    }
                        .price{
                            font-size: 1.2rem;
                            color: $second-color;
                            margin:auto;
                            margin-left:.6rem;
                            font-weight: 600;
                            @media screen and (max-width:$mobile - 1px){
                                font-size: 1.1rem;
                            }
                            span{
                                font-weight: 400;
                            }
                        }
                    }
                }
                .nav-button-open{
                    width: 3.4rem;
                    height: 3rem;
                    background: #ccc;
                    cursor: pointer;
                    position: relative;
                    display: block;
                    background: url(./images/menu.svg) left no-repeat;
                    background-size: contain;
                }
            }
        }
        .big-mobile-menu{
            opacity: 0;
            visibility: hidden;
            transform: translateX(100%);
            position: fixed;
            width: 100%;
            height: 100%;
            left:0;
            top:0;
            background: white;
            z-index: 999;
            border: 5px solid $cta-color;
            &.show{
                opacity: 1;
                visibility: visible;
                transform: translateX(0)
            }

            .user-button{
                font-family: $second-font;
                color: $second-color;
                font-size: .92rem;
                font-weight: 700;
                display: inline-flex;
                margin:2rem 1rem;
                background: $cta-color;
                border-radius: 30px;
                padding: .4rem 1.2rem;
                .icon{
                    width: .9rem;
                    height: .9rem;
                    display:block;
                    margin-left: .4rem;
                    position: relative;
                    top:.2rem;
                }
                &.login{
                   .icon{
                    background: url(./images/user-login.svg) center no-repeat;
                    background-size: contain;
                   }
                }
                &.my-account{
                    .icon{
                     background: url(./images/account-dark.svg) center no-repeat;
                     background-size: contain;
                    }
                 }
            }

            .nav-button-close{
                width: 1.3rem;
                height: 2rem;
                right:2rem;
                padding:0;
                cursor: pointer;
                top:1rem;
                position: absolute;
                display: block;
                background: url(./images/close.svg) left no-repeat;
                background-size: contain;
                @media screen and (max-width:$mobile - 1px){ 
                    width: 1.1rem;
                    height: 1.8rem;
                }
            }

            .category-menu{
                margin-top: 4rem;
                @media screen and (max-width:$mobile - 1px){
                    margin-top: 3rem;
                }
                li{
                    position: relative;
                    display:block;
                    height: 100%;
                    padding: 1rem;
                    p{
                       text-transform: uppercase;
                       color: $second-color;
                       display: flex;
                       font-weight: 500;
                       padding-right: 2rem;
                       height: 100%;
                       margin:auto;
                       @media screen and (max-width:$mobile - 1px){
                           font-size: .85rem;
                       }
                       &:before{
                            content:"";
                            width: 3.4rem;
                            height: 2.7rem;
                            background: #ccc;
                            position: relative;
                            display: block;
                            margin-right: .2rem;
                            background: url(./images/menu.svg) left no-repeat;
                            background-size: contain;
                            @media screen and (max-width:$mobile - 1px){
                                width: 3rem;
                                height: 2.4rem;
                            }
                        }
                       span{
                           margin:auto 0;
                       }
                       &.category-icon{
                           background:$cta-color;
                       }
                    }
                    &:hover .first-child{
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0%);
                    }
                }
            .first-child{
                width: 100%;
                li{
                    display:block;
                    background: white;
                    width: 100%;
                    padding: 0;
                    margin:auto;
                    border-bottom: 1px solid $light-grey-back;
                    a{
                        font-size: .9rem;
                        text-transform: none;
                        padding: 0;
                        display:flex;
                        line-height: 3rem;
                        color: $second-color;
                        height: 3rem;
                        @media screen and (max-width:$mobile - 1px){
                            height: 2.2rem;
                            line-height: 2.2rem;
                            font-size: .8rem;
                        }
                        &:before{
                            content:"";
                            width: 3.4rem;
                            height: 3rem;
                            background: #ccc;
                            position: relative;
                            display: block;
                            margin-right: .2rem;
                            margin-left: 0;
                            top:0;
                            @media screen and (max-width:$mobile - 1px){
                                width: 2.6rem;
                                height: 2.2rem;
                            }
                        }
                        &.zdravlje{
                            &:before{
                                background: url(./images/zdravlje-menu.svg) left no-repeat;
                                background-size: contain;
                            }
                        }
                        &.ljepota-i-njega{
                            &:before{
                                background: url(./images/ljepota-i-njega-menu.svg) left no-repeat;
                                background-size: contain;
                            }
                        }
                        &.sport-i-rekreacija{
                            &:before{
                                background: url(./images/sport-i-rekreacija-menu.svg) left no-repeat;
                                background-size: contain;
                            }
                        }
                        &.drogerija{
                            &:before{
                                background: url(./images/drogerija-menu.svg) left no-repeat;
                                background-size: contain;
                            }
                        }
                        &.aparati-i-pomagala{
                            &:before{
                                background: url(./images/aparati-i-pomagala-menu.svg) left no-repeat;
                                background-size: contain;
                            }
                        }
                       
                    }
                    &:last-child{
                        border-bottom: 0;
                    }
                    &:hover .second-child{
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                    }
                }
            }
        }

            .top-navbar{
                display:flex;
                margin:0;
                position: absolute;
                bottom: 1rem;
                width: 100%;
                ul{
                    display:flex;
                    margin:auto;
                    li{
                        position: relative;
                        display:inline-block;
                        margin:auto 1rem;
                        a{
                            font-family: $second-font;
                            font-size: .9rem;
                            color: $second-color;
                            text-transform: uppercase;
                            font-weight: 500;
                            @media screen and (max-width:$mobile - 1px){
                                font-size: .75rem;
                            }
                        
                        }
                    }
                }
            }

            .navbar{
                margin-top: 2rem;
                @media screen and (max-width:$mobile - 1px){
                    margin-top: 1rem;
                }
                li{
                    margin:auto 0;
                    a{
                        color: $second-color;
                        font-size: 1rem;
                        padding: .4rem 0;
                        margin:0 1.4rem;
                        margin-top:3px;
                        font-weight: 500;
                        display: block;
                        border-bottom: 1px solid $light-grey-back;
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .75rem;
                        }
                        &:hover{
                            border-bottom: 1px solid $main-color;
                        }
                    }
                }
            }
        }

        .cart-header{
            width: 100%;        
            &.show{
                transform:translateY(0);
                opacity: 1;
                visibility: visible;
                top:100%;
            } 
        }
    }
    //Search bar
    .search-bar{
        padding: .3rem 1rem;
        @media screen and (max-width:$mobile - 1px){
            padding: 0rem .6rem;
        }
        .container{
            .inner{
                label{
                    display: flex;
                }
                .search-input{
                    width: 100%;
                    display: flex;
                    &::before{
                        content: none;
                        width: 1.2rem;
                        height: 1.2rem;
                        display: block;
                        background: url(./images/search_icon.svg) center no-repeat;
                        background-size: contain;
                        margin: auto 0rem auto .6rem;
                       
                    }
                    input{
                        width: 100%;
                    }
                }
                .search-button{
                    background: $cta-color url(./images/search_icon.svg) center no-repeat;
                    background-size: 60%;
                    width: 34px;
                    border-radius: 90px;
                    height: 32px;
                    margin: auto 0 auto auto;
                }
            }
            .search-results{
                top: 58px;
                .container{
                    .columns{
                        .column{
                            &.content{
                                .inner-content{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    }
}


//4. Mini cart applied for mobile and desktop
.cart-header{
    top:100%;
    position: absolute;
    right: 0;
    box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 0.2);
    background: white;
    transform: translateY(-45%);
    transition: 0.5s;
    overflow: hidden;
    width: 500px;
    display:none;
    margin-top: .4rem;
    z-index: 9999;
    .no-products{
        margin-top: 0;
    }
   
    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
    }
    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
        right: 1rem;
        margin-top: 0;
        width: 400px;
    }
    &.show{
        transform:translateY(0);
        display: block;
    } 

    .cart-top{
        background: $second-color;
        padding: .7rem 1rem;
        display:flex;
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            padding: .6rem 1rem;
        }
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            padding: .4rem 1rem;
        }
        @media screen and (max-width:$mobile - 1px){
            padding: .5rem 1rem;
        }
        .added-successfully{   
            color: white;
            font-weight: 500;
            font-size: .95rem;  
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .85rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .8rem;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .8rem;
            }  
        }
        .close-cart{
            font-size: .9rem;
            color: white;
            display: flex;
            cursor: pointer;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .8rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .75rem;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .75rem;
            }
            i{
                margin-left: .5rem;
                width: .9rem;
                height: .9rem;
                display:block;
                background: url(./images/close-white.svg) center no-repeat;
                background-size: contain;
                position: relative;
                top: .2rem;
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    width: .7rem;
                    height: .7rem;
                    top: .13rem;
                    margin-left: .3rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    width: .6rem;
                    height: .6rem;
                    top:.26rem
                }
            }
          
        }
    }
 
    .inner{
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        display:inline-block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
       
    .single-row{
        display:flex;
        padding: 0 .4rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        .image{
            margin:auto 0;
            overflow: hidden;
            height: 70px;
            width: 70px;
            min-width: 70px;
            max-width: 70px;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                padding: .4rem;
            }
            @media screen and (max-width:$mobile - 1px){
                padding: .4rem;
            }
            img{
                height: 100%;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                object-fit: contain;
                transform: scale(1.4);
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    transform: scale(1.2);
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                }
                @media screen and (max-width:$mobile - 1px){
                }
            }       
        }

        .content{
            margin:auto 0;
            padding: 1rem;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                padding: .8rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                padding: .6rem;
            }
            @media screen and (max-width:$mobile - 1px){
                padding: .6rem;
            }
            .title{
                font-size: 1.1rem;
                color: $second-color;
                margin-bottom:0;
                font-weight: 600;
                font-family: $second-font;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: 1rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .9rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .9rem;
                }
            }
            .price{
                font-weight: 500;
                font-size: 1.2rem;
                color: $second-color;
                margin-bottom: 0;
                padding: .2rem 0;
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: 1.1rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: 1rem;
                }
            }
            .old-price{
                font-weight: 500;
                font-size: .95rem;
                color: $red-color;
                text-decoration: line-through;
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .85rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .85rem;
                }
            }

           
        }
        .quantity{
            margin:auto 2rem auto auto;
            font-weight: 600;
            border-radius: 90px;
            background: $light-grey-back;
            width: 32px;
            height: 32px;
            min-width: 32px;
            font-size: .95rem;
            line-height: 35px;
            text-align: center;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                width: 26px;
                height: 26px;
                line-height: 26px;
                font-size: .85rem;
            }
            @media screen and (max-width:$mobile - 1px){
                widows: 26px;
                height: 26px;
                line-height: 26px;
                font-size: .8rem;
            }
        }
    }
}
    .no-products{
        font-size: 1.1rem;
        color: $second-color;
        margin-bottom:0;
        font-weight: 600;
        font-family: $second-font;  
        padding: 1.6rem;
        border-bottom: 1px solid $light-grey-back;
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            font-size: 1rem;
            padding: 1.4rem;
        }
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            font-size: 1rem;
            padding: 1.2rem;
        }
        @media screen and (max-width:$mobile - 1px){
            font-size: 1rem;
            padding: 1.2rem;
        }
    }

    .button-area{
        display:flex;
        margin: 1rem;
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            margin: .8rem;
        }
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            margin:.3rem .7rem;
        }
        @media screen and (max-width:$mobile - 1px){
            margin:.7rem
        }
        .button{
            font-size: .9rem;
            padding: 1.2rem 1.4rem;
            margin:auto 0 auto auto;
            width: 170px;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .8rem;
                padding: 1rem 1.2rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .7rem;
                padding: 1rem .6rem;
            }
            @media screen and (max-width:$tablet - 1px){
                width: initial;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .7rem;
                padding: .9rem .7rem;
            }
        }
        .total-price{
            span{
                font-size: .85rem;
                font-weight: 500;
                color: $second-color;
                margin-right: 3rem;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .8rem;
                    line-height: .9rem;
                    display:block;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .74rem;
                    line-height: .9rem;
                    display: block;
                    margin-bottom: .3rem;
                }
            }

            h4{
            font-weight: 600;
            font-size: 1.4rem;
            color: $second-color;
            margin-bottom: 0;
            padding: 0;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: 1rem;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: 1rem;
            }
            }
        }
    }
}

//5. Search results
.search-results{
    position: absolute;
    top:100%;
    left:0;
    width: 100%;
    z-index: 999;
    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
       margin-top: 1.25rem;
    } 
    @media screen and (max-width: $tablet){
        margin-top: 0rem;
    }
    .columns{
        margin-left:0;
        margin-right: 0;
        .column{
            &.is-2{
                width: 23%;
            }
        }
        .content{
            position: relative;
            .inner-content{
                background: white;
                box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 0.1);
                width: calc(100% - 4px);
                margin:auto;
                max-height: 400px;
                overflow: auto;
                margin-bottom: 2.5rem;
               
            .single-row{
                display: flex;
                background: transparent;
                width: 100%;
                position: relative;
                border-radius: 0;
                padding: .5rem 1rem;
                border-bottom: 1px solid rgba(0,0,0,0.05);
                cursor: pointer;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    padding: .3rem .8rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    padding: .3rem .8rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    padding: .3rem .8rem;
                }
        
                &:hover{
                    background: $light-grey-back;
                }
                .content{
                    display:block;
                    padding: .4rem 0;
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        padding: .2rem 0;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        padding: .2rem 0;
                    }
                    .title{
                        font-size: 1.1rem;
                        color: $second-color;
                        margin:auto 1rem;
                        font-family: $second-font;
                        font-weight: 500;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: 1rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .9rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .9rem;
                        }
                    }
                    .price{
                        font-size: .85rem;
                        color: $second-color;
                        margin:auto 1rem;
                        font-weight: 600;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: .8rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .7rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .7rem;
                        }
                    }
                }
            
                img{
                    width: 50px;
                    object-fit: contain;
                    object-position: center;
                    height: 50px;
                    margin:auto 0;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        width: 45px;
                        height: 45px;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        width: 40px;
                        height: 40px;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        width: 40px;
                        height: 40px;
                    }
                }
            }
            .no-results{
                font-size: 1rem;
                color: $second-color;
                padding: 2rem 2rem;
                font-weight: 600; 
                text-align: center;
                margin:0;
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .9rem;
                    padding: 1.4rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .9rem;
                    padding: 1.4rem;
                }
            }

            .advanced-search{
                padding: .8rem 0;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: $cta-color;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    padding: .6rem 0;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    padding: .5rem 0;
                }
                @media screen and (max-width:$mobile - 1px){
                    padding: .6rem 0;
                }
                a{ 
                    color: $second-color;
                    text-align: left;
                    font-weight: 700;
                    font-size: .9rem;
                    display:block;
                    padding-left: 1.4rem;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: .85rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .75rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .8rem;
                    }
                }
                &:hover{
                    backgorund: $cta-color-hover;
                }
            }
        }
        }
    }
}
}

    .contact .header .main .navbar li a.contact{
        text-shadow: 0px 0px 1px #000
    }
    .actions .header .main .navbar li a.actions{
        text-shadow: 0px 0px 1px #000
    }
    .week-offer .header .main .navbar li a.week-offer{
        text-shadow: 0px 0px 1px #000
    }
    .other-actions .header .main .navbar li a.other-actions{
        text-shadow: 0px 0px 1px #000
    }

    /*.sticky-outer-wrapper{
    &.active{
        .header{
            .top{
                padding: .4rem 0;
                .container{
                    .columns{
                        .column{
                            &.logo{
                                a{
                                    img{
                                       //transform: scale(.8);
                                      // transform-origin: center left;
                                    }
                                }
                            }
                            &.search-bar{
                                .inner{
                                    width: 85%;
                                    margin:auto;
                                    height: 40px;
                                    line-height: 40px;
                                    button{
                                        width: 32px;
                                        height: 32px;
                                        margin:auto 4px auto auto;
                                    }
                                    .search-input{
                                        width: 85%;
                                        position: relative;
                                    }
                                }
                            }
                            &.cart-box{
                             
                                .inner{
                                    cursor: pointer;
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        margin-right: .4rem;
                                        }
                                .quantity{
                                    padding: 1.3rem;
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        padding: 1.1rem;
                                        }
                                    span{
                                        font-size: .75rem;
                                        width: 17px;
                                        height: 17px;
                                        line-height: 17px;
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            width: 14px;
                                            height: 14px;
                                            line-height: 14px;
                                            }
                                    }
                                }
                                    .price{
                                        font-size: 1.1rem;
                                    }
                                }
                            }
                        }
                    }    
                }
            }  
            .main{
                .navbar{
                    li{
                        a{
                            font-size: .9rem;
                            padding: .6rem 0;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .8rem;
                                padding: .4rem 0;
                                }
                        }
                    }
                }
            }
            .category-menu{
                    li{
                        a{
                            font-size: .9rem;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .8rem;
                                }
                        }
                    }
            }
        }
        .cart-header{
            top: 3.4rem;
         
            @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                top:3.3rem;
            }
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                top:3rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                top:2.6rem;
            }
        }
    }
   
}*/
