.shop{
    .intro-mobile{
        padding: 1.5rem;
        //height: 66.6666vh;
        display: none;
        @media screen and (max-width:$tablet - 1px){
          //  display: none;
          display: block;
        }
        .container{
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    .intro{
        padding: 1.5rem;
        @media screen and (max-width:$tablet - 1px){
           display: none;
        }
    .container{
        .slick-slider > .slick-dots{
            bottom:2rem !important;
            text-align:left;
            padding-left:2rem;
            @media screen and (max-width:$mobile - 1px){
                bottom: 1rem !important;
                padding-left:1rem;
            }
        }

        .my-tiles{
            .is-left{

            }
            .is-right{
                display:grid;
                margin: 0;
                .first-tile{
                }
                .second-tile{
                    margin:auto 0 0;
                    img{
                        display:block;
                    }

                }
                @media screen and (max-width:$tablet - 1px){
                    display:none;
                    flex-direction: unset;
                }
                .ads{
                    padding:0rem;
                    margin:.7rem 1.4rem !important;
                    margin-right: 0 !important;
                    @media screen and (max-width:$tablet - 1px){
                        margin:0;
                        padding: .6rem .3rem;
                        &:first-child{
                            padding-left: 0;
                        }
                        &:last-child{
                            padding-right: 0;
                        }
                    }
                    
                    a{
                        line-height: 0;
                        display:block;
                        img{
                            margin:auto;
                            display: block;
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                        a{
                            padding-top: .9rem;
                        }
                    }
                }
            }

        } 

       /*  .main-tile{
            margin:0;

                .is-left{
                    position: relative;
                    margin: .7rem 0 0;
                    padding-left:0;
                    padding-top: 0;
                    .tile{
                        width: 100%;
                    }
                   .slick-slider{
                        .slick-list{
                            .slick-track{
                                .slick-slide{
                                    .image{
                                        position: relative;
                                        top:0;
                                        left:0;
                                        height: 100%;
                                        width: 100%;
                                        z-index: -1;
                                        display:block;
                                        img{
                                            width:100%;
                                            height: 100%;
                                            object-fit: cover;
                                            object-position: center;
                                        }
                                    }
                                }
                            }
                        }
                    }     
                }
        }

       
    } */
}
    }

    .posts-homepage{
        .columns{
            margin-top: 2rem;
            .column{
                .inner{
                    height: 100%;
                    background: #fafafa;
                    border:1px solid $light-grey-back;            
                    figure{
                        a{
                            img{
                              height: 250px;
                              object-fit: cover;
                              width: 100%;
                              object-position: center; 
                              @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                  height: 200px;
                              } 
                              @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                  height: 150px;
                              }
                              @media screen and (max-width:$mobile - 1px){
                                  height: 180px;
                              }
                            }
                        }
                    }
                    .content{
                        padding: 1.4rem;
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            padding: .9rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            padding: 1rem;
                        }
                        h4{
                            font-family: $second-font;
                            color: $second-color;
                            font-size: 1.5rem;
                            font-weight: 500;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: 1.3rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: 1.1rem;
                                margin-bottom: .4rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                margin-bottom: .8rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: 1.3rem;
                            }
                            a{
                                color: $second-color;
                            }
                        }
                        p{
                            font-size: .9rem;
                            color: $second-color;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .8rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .7rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: .8rem;
                            }
                        }
                    .button-area{
                        margin: 1.4rem 0 0;
                        .button{
                            font-size: .8rem;
                            padding: 1.3rem 1.6rem;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .75rem;
                                padding: 1rem 1.3rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .65rem;
                                padding: .8rem 1rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: .7rem;
                                padding: 1rem 1.3rem;
                            }
                        }
                    }
                    }
                }
                &:hover{
                    .inner{
                        box-shadow: -3px 13px 24px -10px rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }
    }
.offer{
    background: url(./images/offer-back.png) repeat;
    @media screen and (max-width:$tablet - 1px){
        padding-top: 2rem;
    }
    .container{
        .columns{
            .column{
                &.area-left{
                    padding: 3rem 0;
                    width: 22%;
                    @media screen and (max-width:$tablet - 1px){
                        padding: 0 0 1rem;
                        width: 100%;   
                    }
                    .title-box-action{
                        .title{
                            font-size: 2.2rem;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: 1.8rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: 1.7rem;
                            }
                        }
                    }
                   
                    .discount{
                        font-size: 4.6rem;
                        color: $second-color;
                        font-weight: 700;
                        line-height: 5.2rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: 4rem;
                            line-height: 4rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: 3.4rem;
                            line-height: 3rem;
                        }
                        @media screen and (max-width:$tablet - 1px){
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            font-size: 4rem;
                            line-height: 4.6rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: 3.8rem;
                            line-height: 4.6rem;
                        }
                        span{
                            font-weight: 400;
                            color: $second-color;
                            margin-left:-1rem;
                        }
                    }
                    .button-area{
                        margin-top: 2rem;
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            margin-top: 1rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            margin-top: 1rem;
                        }
                        .button{
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .75rem;
                                padding: 1.1rem 1.5rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .7rem;
                                padding:1rem .9rem;
                            }
                        }
                    }
                    .counter{
                        display:flex;
                        margin-top:1rem;
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            width: 60%;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            width: 70%;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            width: 70%;
                        }
                        .single{
                            margin-right: .8rem;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                margin-right: .6rem;
                            }
                            @media screen and (max-width:$tablet - 1px){
                                margin:auto;
                            }
                            span{
                                width: 55px;
                                height: 55px;
                                line-height: 50px;
                                display:block;
                                text-align: center;
                                border-radius: 90px;
                                border:2px solid $second-color;
                                color: $second-color;
                                font-size: 1.6rem;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    width: 42px;
                                    height: 42px;
                                    line-height: 42px;
                                    font-size: 1.3rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    width: 35px;
                                    height: 35px;
                                    line-height: 35px;
                                    font-size: 1rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    width: 42px;
                                    height: 42px;
                                    line-height: 42px;
                                    font-size: 1.3rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    width: 36px;
                                    height: 36px;
                                    line-height: 34px;
                                    font-size: 1rem;
                                }
                            }
                            p{
                                color: $grey-color;
                                font-family: $second-font;
                                font-size: .85rem;
                                padding-top:.5rem;
                                text-align: center;
                                text-transform: uppercase;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .7rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (max-width:$tablet - 1px){
                                    font-size: .8rem;
                                }
                            }
                        }
                    }
                }
                &.area-right{
                    position: relative;
                    padding-left:2.8rem;
                    padding-right: 0;
                    width: 78%;
                    @media screen and (max-width:$tablet - 1px){
                        padding-left: 0;
                        width: 100%;
                    }
                    &:before{
                        //content:"";
                        width: 1px;
                        height: 100%;
                        position: absolute;
                        left:0;
                        top:0;
                        background: rgba(0,0,0,0.05);
                        @media screen and (max-width:$tablet - 1px){
                            width: 100%;
                            height: 1px;
                        }
                    }
                }
            }
        }
    }
}

.wide-banner{
    padding-bottom: 0;
    @media screen and (min-width: $mobile) and (max-width:$widescreen - 1px){
        padding-top: 2rem;
    }
    @media screen and (max-width:$mobile - 1px){
        padding-top: 1.5rem;
    }
    .container{
        display:flex;
        a{
            margin:auto;
            img{}
        }
    }
}

.grid-banner{
    background: url(./images/offer-back.png) repeat;
    .container{
        .columns{
            margin-top:0;
        }
    }
}

.action{
    background: #f8f8f8;
    border-bottom: 1px solid #e8e8e8;
    .container{
        position: relative;
        .products-slider{
            margin-top: 2rem;
            position: unset;
            .swiper-button-next, .swiper-button-prev{
                top: 50%;
                margin-top: 0rem;
            }
        }
        .button-area{
            margin-top: 1.4rem;
        }
    }
}

.slick-prev, .slick-next{
    width: 1.3rem;
    height: 3.2rem;
    z-index: 10;
    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
        width: 1.1rem;
        height: 3rem;
    }
    &::before{
        content:none;
        display:none;
    }
    &:hover{
        box-shadow: -3px 3px 10px 1px rgba(0, 0, 0, 0.13);
    }
   
}
.slick-prev{
    border-radius:3px 0px 0px 3px;
    background:#D0D0D0 url(./images/left-arrow-white.svg);
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
    left:-10px;
    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
        left: -11px;
    }
    @media screen and (max-width:$mobile - 1px){
        left: -15px;
    }
    &:hover, &:focus{
        background:#D0D0D0 url(./images/left-arrow-white.svg);
        background-size: 75%;
        background-position: center;
        background-repeat: no-repeat;
       
    }
}

.slick-next{
    border-radius:0px 3px 3px 0px;
    background:#D0D0D0 url(./images/right-arrow-white.svg);
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
    right:-10px;
    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
        right: -14px;
    }
    @media screen and (max-width:$mobile - 1px){
        right: -15px;
    }
    &:hover, &:focus{
    background:#D0D0D0 url(./images/right-arrow-white.svg);
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
    }
}

.default-pagination{
    .swiper-button-next, .swiper-button-prev{
        top:48%;
        width: 1.8rem;
        height: 3.2rem;
        border-radius:3px 0px 0px 3px;
        &:focus{
            outline: none;
        } 
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            width: 2.2rem;
            height: 2.2rem;
        }
        @media screen and (max-width:$mobile - 1px){
            width: 2rem;
            height: 2rem;
        }
    }
    .swiper-button-next{
        right:0rem;
        background:#D0D0D0 url(./images/right-arrow-white.svg);
        background-size: 65%;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            margin-right:2rem;
        }
        @media screen and (max-width:$mobile - 1px){
            margin-right:1rem;
            background-size: 45%;
        }
    }
    .swiper-button-prev{
        left: 0rem;
        background:#D0D0D0 url(./images/right-arrow-white.svg);
        transform: rotate(180deg);
        background-size: 65%;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            margin-right:5rem;
        }
        @media screen and (max-width:$mobile - 1px){
            margin-right:3.4rem;
            background-size: 45%;
        }
    }
}
.category-main{
    background: #f8f8f8;
    padding:1.5rem 1.5rem;
    &.is-first{
        padding-top: 3rem;
    }
    &.is-last{
        padding-bottom: 3rem;
    }
    .container{
        .columns{
            .area-left{
                position: relative;
                margin: 0rem;
                padding:0;
                overflow: hidden;
                display:flex;
                padding-top: 0;
                width: 22%;
                @media screen and (max-width:$tablet - 1px){
                    margin:1rem 0;
                    width: 100%;
                }
                &:hover{
                    transform: scale(1.01);
                    box-shadow: 0px 8px 24px -10px rgba(0, 0, 0, 0.55);
                }
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    height: 9rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    height: 7rem;
                }
                .inner-wrapper{
                    background-size: cover;
                    width: 100%;
                    position: relative;
                    @media screen and (max-width:$tablet - 1px){
                        margin:0 5px;
                    }
                    .overlay{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left:0;
                        top:0;
                        padding: 0rem;
                        .inner{
                            width: 100%;
                            height: 100%;
                            opacity: 1;
                            &.red{
                                background: $red-color;
                            }
                            &.dark-blue{
                                background: $dark-blue-color;
                            }
                            &.light-green{
                                background: $light-green-color;
                            }
                            &.light-blue{
                                background: $light-blue-color;
                            }
                            &.zdravlje{
                                background: $purple-color;
                            }
                            &.sport-i-rekreacija{
                                background: $zdravlje-color;
                            }
                            &.drogerija{
                                background: $drogerija-color;
                            }
                            &.ljepota-i-njega{
                                background: $ljepota-i-njega-color;
                            }
                            &.ishrana{
                                background: $ishrana-color;
                            }
                            &.aparati-i-pomagala{
                                background: $aparati-i-pomagala-color;
                            }
                        }
                    }
                    .icon-category{
                        width: 6rem;
                        height: 6rem;
                        display:block;
                        position: absolute;
                        right: 1.6rem;
                        top:1.6rem;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            width: 4rem;
                            height: 4rem;
                            right: 1.2rem;
                            top:1.2rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            width: 3rem;
                            height: 3rem;
                            right: 1rem;
                            top:1rem;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            width: 3.4rem;
                            height: 3.4rem;
                            right: 1.2rem;
                            top:1.2rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            width: 2.5rem;
                            height: 2.5rem;
                            right: 1rem;
                            top:1rem;
                        }
                    }
                    .load-category{
                        border-radius: 30px;
                        background: white;
                        position: absolute;
                        right: 1.6rem;
                        bottom:1.6rem;
                        font-size: 1rem;
                        font-weight: 700;
                        padding: .3rem 1.4rem;
                        text-transform: uppercase;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            right: 1.2rem;
                            bottom:1.2rem;
                            font-size: .9rem;
                            padding: .25rem 1.2rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            right: 1rem;
                            bottom: 1rem;
                            font-size: .8rem;
                            padding: .2rem 1rem;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            right: 1.2rem;
                            bottom:1.2rem;
                            font-size: .9rem;
                            padding: .25rem 1.2rem; 
                        }
                        @media screen and (max-width:$mobile - 1px){
                            right: 1rem;
                            bottom: 1rem;
                            font-size: .75rem;
                            padding: .15rem 1rem;
                        }
                        &.red{
                            color: $red-color;
                        }
                        &.dark-blue{
                            color: $dark-blue-color;
                        }
                        &.light-green{
                            color: $light-green-color;
                        }
                        &.light-blue{
                            color: $light-blue-color;
                        }
                        &.purple{
                            color: $purple-color;
                        }
                    }
                    .title-box{
                      position: relative;
                      top:1.6rem;
                      left:1.6rem;
                      max-width: 100%;  
                      
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            top:1.2rem;
                            left:1.2rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            top:1rem;
                            left: 1rem;
                        }
                        @media screen and (max-width:$tablet - 1px){
                            left: 1rem;
                            display:flex;
                            height: 100%;
                            top:unset;
                            bottom: 1rem;
                        }
                        
                        .title{
                            color: white;
                            font-size: 2.5rem;
                            font-weight: 400;
                            font-family: $second-font;
                            writing-mode: vertical-rl;
                            text-orientation: mixed;
                            text-align: right;
                            max-width: 100%;
                            transform: rotate(180deg);
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                font-size: 2.2rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: 1.6rem;
                            }
                            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                font-size: 2rem;
                            }
                            @media screen and (max-width:$tablet - 1px){
                                transform: rotate(0);
                                writing-mode: unset;
                                text-align: left;
                               
                                margin:auto 0 0;
                                padding:0;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: 1.6rem;
                                width: 60%;
                            }
                        }
                    }
                    &:hover{
                        box-shadow: -3px 13px 24px -10px rgba(0, 0, 0, 0.33);
                    }
                    &:hover .overlay .inner{
                        opacity: 1;
                    }
                   
                }
                }
                
            .area-right{
                width: 78%;
                padding-top: 0;
                padding-bottom: 0;
                position: relative;
                @media screen and (max-width:$tablet - 1px){
                    padding: 0;
                    width: 100%;
                }
                ul{
                    &.slider{
                        li{
                            width: 25%;
                        }
                    }
                }
            }
        }
    }



    &.category-2{
        .container{
            .columns{
                .area-left{
                    .overlay{
                        .inner{
                        }
                    }
                }
            }
        }
    }

    &.category-3{
        padding-bottom: 3rem;
        .container{
            .columns{
                .area-left{
                    .overlay{
                        .inner{
                        }
                    }
                }
            }
        }
    }
}

.newsletter{
    background: $main-color;
    padding: 1.2rem 1.5rem;
    .container{
        display:flex;
        @media screen and (max-width:$tablet - 1px){
            display:block;
        }
        .icon{
            width: 60px;
            height: 60px;
            display:block;
            background: url(./images/newsletter-icon.svg) center no-repeat;
            background-size: contain;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                width: 40px;
                height: 40px;
                margin:auto 0;
            }
            @media screen and (max-width:$tablet - 1px){
                margin:1rem auto;
            }
            @media screen and (max-width:$mobile - 1px){
                width: 44px;
                height: 44px;
            }
        }
        .title{
            font-size: 1.8rem;
            color: $second-color;
            margin:auto 1.6rem;
            font-weight: 400;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: 1.6rem;
                margin-right: 0;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: 1.1rem;
                margin-right: 0;
                margin-left:.6rem;
            }
            @media screen and (max-width:$tablet - 1px){
                text-align: center;
                margin:1rem auto;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: 1.4rem;
            }
        }

        .subtitle{
            color: $second-color;
            font-size: 1rem;
            font-family: $second-font;
            font-weight: 400;
            margin:auto 0 auto auto;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .9rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .8rem;
            }
            @media screen and (max-width:$tablet - 1px){
                text-align: center;
                margin:1rem auto;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .9rem;
            }
            span{
                font-weight: 600;
            }
        }
        .search-bar{
            width: 30%;
            margin:auto 0 auto auto;
            .inner{
                height: 50px;
                line-height: 50px;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                   height: 46px;
                   line-height: 46px;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    height: 36px;
                    line-height: 36px;
                }
                @media screen and (max-width:$mobile - 1px){
                    height: 40px;
                    line-height: 40px;
                    input{
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .75rem;
                        }
                    }
                }
                button{
                    height: 42px;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        width: 36px;
                        height: 36px;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        height: 30px;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        height: 34px;
                    }
                }
            }
            @media screen and (max-width:$tablet - 1px){
                margin:1rem auto;
                width: 80%;
            }
        }
    }
}

.category-page{
    position: relative;
    .cat-intro{
        padding: 2rem 1.5rem;
        position: relative;
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            padding: 1.8rem 1.5rem;
        }
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            padding: 1.4rem 1.5rem;
        }
        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
            padding: 1.4rem 1.5rem;
        }
        @media screen and (max-width:$mobile - 1px){
            padding: 1.2rem 1.5rem;
        }
        .overlay{
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            &.zdravlje{
                background: $zdravlje-color;
            }
            &.sport-i-rekreacija{
                background: $zdravlje-color;
            }
            &.drogerija{
                background: $drogerija-color;
            }
            &.ljepota-i-njega{
                background: $ljepota-i-njega-color;
            }
            &.aparati-i-pomagala{
                background: $aparati-i-pomagala-color;
            }
        }
        .container{
            display:flex;
            @media screen and (max-width:$tablet - 1px){
                display:block;
            }
            .title-box{
                display:flex;
                .icon-category{
                    width: 4rem;
                    height: 4rem;
                    display:block;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-right: 1rem;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        width: 3.6rem;
                        height: 3.6rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        width: 3rem;
                        height: 3rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        width: 3rem;
                        height: 3rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        width: 2.4rem;
                        height: 2.4rem;
                    }
                }
                .title{
                    font-size: 2.2rem;
                    text-transform: uppercase;
                    color: white;
                    font-weight: 700;
                    margin:auto 0;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: 2rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: 1.6rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        font-size: 1.6rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: 1.4rem;
                    }
                }
                .subtitle{
                    font-size: 1rem;
                    text-transform: uppercase;
                    color: $light-grey-back;
                    padding:.4rem 0 0;
                    display:block;
                    margin:0;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: .95rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .85rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        font-size: .9rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .8rem;
                    }
                }
            }
        
            .share-box{
                margin:auto 0 auto auto;
                display:flex;
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    margin-top: 1rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    margin-top:1rem;
                }
                p{
                    color: white;
                    font-size: .9rem;
                    font-weight: 400;
                    margin-right: .5rem;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: .85rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .75rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        font-size: .8rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .7rem;
                    }
                }
                a{
                    span{
                        width: 20px;
                        height: 20px;
                        display:block;
                        margin:0 .5rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            width: 18px;
                            height: 18px;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            width: 16px;
                            height: 16px;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            width: 16px;
                            height: 16px;
                            margin:0 .3rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            width: 14px;
                            height: 14px;
                            margin:0 .2rem;
                        }
                        &.facebook{
                            background: url(./images/facebook-white.svg) center no-repeat;
                            background-size: contain;
                            color: white;
                        }
                        &.twitter{
                            background: url(./images/twitter.svg) center no-repeat;
                            background-size: contain;
                        }
                        &.email{
                            background: url(./images/email.svg) center no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }

    .cat-body{
        padding-top: 1.5rem;
        @media screen and (min-width: $mobile) and (max-width:$widescreen - 1px){
            padding-top: 1rem;
        }
        @media screen and (max-width:$mobile - 1px){
            padding-top: 1rem;
        }
        .container{
         .options-box{
             display:flex;
             margin-bottom: 1.2rem;
             @media screen and (max-width:$tablet - 1px){
                margin-top: 1rem;
                margin-bottom: 1.4rem;
                margin-left: -.75rem;
            }
             .sort-products{
               .inner{
                   position: relative;
                   .open-filter{
                       font-size: .9rem;
                    display: initial;
                    font-weight: 700;
                    padding: 1.2rem 1.2rem;
                    color: $second-color;
                    cursor: pointer;
                   }
                   .sort-filters-list{
                       display: block;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 250px;
                        height: auto;
                        margin-top: .1rem;
                        z-index: 990;
                        background: $light-grey-back;
                        opacity: 0;
                        visibility: hidden;
                        border: 1px solid rgba(0,0,0,.04);
                        &.show{
                            opacity: 1;
                            visibility: visible;
                        }
                       .single-option{
                        border-bottom: 1px solid rgba(0,0,0,.04);
                        display: block;
                        padding: .5rem;
                        font-size: .9rem;
                        font-family: $second-font;
                        color: $second-color;
                        cursor: pointer;
                        &:hover, &.active{
                            background: #d3d3d3;
                        }
                        &:last-child{
                            border-bottom: 0;
                        }
                       }
                       
                   }
               }
             }
             p{
                margin:auto 0;
                font-size: 1rem;
                font-family: $second-font;
                color: $grey-color;
                font-weight: 500;
                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                    font-size: .9rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .8rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .86rem;
                }
                &.total-products{
                    margin:auto 0 auto auto;
                    text-align: right;
                    b{
                        margin-left:.3rem;
                    }
                    }
             }
         }
            .columns{
                .filters-button{
                    background: $cta-color;
                    display:none;
                    padding: .4rem .8rem;
                    color: $second-color;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: .8rem;
                    @media screen and (max-width:$mobile - 1px){
                       display:flex;
                    }
                    span{
                        background: url(./images/menu.svg) center no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: cover;
                        display:block;
                        margin:auto 0;
                        margin-right: .6rem;
                    }
                }

                .column{
                    &.area-left{
                        margin:2.7rem 0 0;
                        padding: 0 .75rem 0 0;
                        @media screen  and (max-width:$tablet - 1px){
                            padding: 0;
                            opacity: 0;
                            visibility: hidden;
                            height: 0;
                            margin-top: 1rem;
                            &.show{
                                opacity: 1;
                                visibility: visible;
                                height: auto;
                            }
                            .banner{
                                display:none;
                            }
                        }
                        .subcategories{
                            margin-bottom:3rem;
                            @media screen and (max-width:$tablet - 1px){
                                margin-bottom: 1.6rem;
                            }
                            li{
                                display:block;
                                margin:0rem 0;
                                border:1px solid #f1f1f1;
                                border-bottom: 0;
                                &:last-child{
                                    border-bottom:1px solid #f1f1f1;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                }
                                @media screen and (max-width:$mobile - 1px){
                                }
                                .single{
                                    width: 100%;
                                    font-weight: 500;
                                    font-family: $second-font;
                                    color: $second-color;
                                    font-size: .9rem;
                                    padding:.3rem .8rem;
                                    display:block;
                                    transition:all .25s cubic-bezier(.46,.82,.61,.87);
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        font-size: .85rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .8rem;
                                        padding: .1rem .3rem;
                                    }
                                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                        font-size: .85rem;
                                    }
                                    @media screen and (max-width:$mobile - 1px){
                                        font-size: .80rem;
                                        padding: .3rem .8rem;
                                    }
                                   
                                    &:before{
                                        content: '\2022';
                                        display:none;
                                        font-size: 2rem;
                                        position: relative;
                                        top:.15rem;
                                        line-height: 0;
                                        color: $grey-color;
                                        margin-right: .5rem;
                                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                            font-size: 1.6rem;
                                            top: .15rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: 1.4rem;
                                            top:.1rem;
                                        }
                                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                            font-size: 1.6rem;
                                            top:.15rem;
                                        }
                                        @media screen and (max-width:$mobile - 1px){
                                            font-size: 1.4rem;
                                            top:.1rem;
                                        }
                                       
                                    }

                                    &:hover, &.active{
                                        background: $main-color;
                                        color: $second-color;
                                        &:before{
                                            color: $main-color;
                                        }
                                    }
                                }
                                .parent{
                                    width: 100%;
                                    font-weight: 600;
                                    font-family: $second-font;
                                    color: $second-color;
                                    font-size: .95rem;
                                    display: block;
                                    padding:.5rem .8rem;
                                    transition:all .25s cubic-bezier(.46,.82,.61,.87);
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        font-size: .9rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .8rem;
                                        padding: .3rem;
                                    }
                                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                        font-size: .95rem;
                                    }
                                    @media screen and (max-width:$mobile - 1px){
                                        font-size: .85rem;
                                        padding: .3rem .8rem;
                                    }
                                    &:hover, &.active{
                                        color: $main-color;
                                    }
                                }
                            }
                        }

                        .filter-title{
                                font-weight: 700;
                                color: $second-color;
                                font-size: .95rem;
                                border:1px solid #f1f1f1;
                                font-family: $second-font;
                                padding:0.6rem .8rem;
                                position: relative;
                                margin-bottom: 0;
                                transition:all .25s cubic-bezier(.46,.82,.61,.87);
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .9rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                    padding: .3rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: .95rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .85rem;
                                    padding: .3rem .8rem;
                                }
                                &:after{
                                    content:"";
                                    width: 15px;
                                    height: 100%;
                                    position: absolute;
                                    background: url(./images/arrow-down-white.svg) center no-repeat;
                                    background-size: 14px;
                                    border-left:1px solid #f1f1f1;
                                    display:block;
                                    padding:0 1.3rem;
                                    right: 0rem;
                                    top:0;
                                    margin:auto 0 auto auto;
                                }
                        }

                        .filter-box{
                            margin-bottom: 2rem;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                margin-bottom: 2rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                margin-bottom: 2rem;
                            }

                            .filter-select{
                                border-radius: 0;
                                border:1px solid #f1f1f1;
                                .css-151xaom-placeholder{
                                    color: $second-color;
                                    font-weight: 600;
                                    font-size: 1rem;
                                    font-family: $second-font;
                                    padding-left: .3rem;
                                }
                                .css-bg1rzq-control{
                                    border:none;
                                  
                                }
                                .css-1szy77t-control{
                                   border:none;
                                   outline:none;
                                   box-shadow: 0 1px 5px 0px rgba(206, 206, 206, 1);
                                }
                                .css-1wy0on6{
                                    span{
                                        width: 1px;
                                        height: 100%;
                                        margin-top: 0;
                                        background: #f1f1f1;
                                    }
                                    .css-16pqwjk-indicatorContainer{
                                        color: $second-color;
                                    }
                                }
                                .css-kj6f9i-menu{
                                    display:block !important;
                                    margin:0;
                                }
                            }

                            .tag-category-name{
                                color: $main-color;
                                font-family: $second-font;
                                text-transform: uppercase;
                                font-weight: 700;
                                border:1px solid #f1f1f1;
                            }
                            .inner{
                                   
                                    &.show{
                                        display: block;
                                    }
                                .single-line{
                                    margin:.1rem 0;
                                    display:flex;
                                    background: #f1f1f1;
                                    &:first-child{
                                        margin-top: 0;
                                    }
                                    .remove{
                                        width: 10px;
                                        height: 10px;
                                        display:block;
                                        background: url(./images/close-white.svg) center no-repeat;
                                        background-size: contain;
                                        margin:auto .4rem auto auto;
                                        cursor: pointer;
                                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                            width: 7px;
                                            height: 7px;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            width: 6px;
                                            height: 6px;
                                        }
                                        @media screen and (max-width:$tablet - 1px){
                                            width: 8px;
                                            height: 8px;
                                            padding: .33rem;
                                        }
                                    }

                                    p{
                                        color: $second-color;
                                        font-size: 0.9rem;
                                        font-family: $second-font;
                                        font-weight: 500;
                                        margin:0;
                                        width: 90%;
                                        cursor: pointer;
                                        padding:.3rem .8rem;
                                        &.no-filters-found{
                                            background: white;
                                            width: 100%;
                                            cursor: text;
                                        }
                                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                            font-size: .85rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: .8rem;
                                            padding: .1rem .3rem;
                                        }
                                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                            font-size: .9rem;
                                        }
                                        @media screen and (max-width:$mobile - 1px){
                                            font-size: .80rem;
                                            padding: .3rem .8rem;
                                        }
                                        
                                        &:before{
                                            content: '\2022';
                                            display: none;
                                            font-size: 2rem;
                                            position: relative;
                                            top:.15rem;
                                            line-height: 0;
                                            color: $grey-color;
                                            margin-right: .5rem;
                                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                                font-size: 1.6rem;
                                                top: .15rem;
                                            }
                                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                                font-size: 1.4rem;
                                                top:.1rem;
                                            }
                                            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                                font-size: 1.6rem;
                                                top:.15rem;
                                            }
                                            @media screen and (max-width:$mobile - 1px){
                                                font-size: 1.4rem;
                                                top:.1rem;
                                            }
                                           
                                        }
                                    }
                                    &:hover, &.active{
                                        background: #d3d3d3;
                                        &:before{
                                            color: $main-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.area-right{
                        position: relative;
                        margin:0;
                        padding-right: 0;
                        padding-top: 0;
                        &:before{
                            content:"";
                            display:none;
                            width: 1px;
                            height: 100%;
                            position: absolute;
                            left:0;
                            top:2rem;
                            background: rgba(0,0,0,0.05);
                        }
                        .products-slider{
                            margin-top: 0;
                            @media screen and (max-width:$tablet - 1px){
                                display:flex;
                            }
                            .single{
                              margin-bottom: 20px;
                              @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                  width: 33.333333%;
                              }
                              @media screen and (max-width:$mobile - 1px){
                                  width: 100%;
                                  min-width: 100%;
                                  padding: 0 10px;
                              }
                                @media screen and (max-width:$tablet - 1px){
                                    padding-left:0;
                                    margin-bottom: 10px;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    width: 50%;
                                    min-width: 50%;
                                }
                            }
                        }

                        .filters-list{
                            display:flex;
                            margin-left:0rem;
                            margin-bottom: 1.4rem;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                margin-bottom: 2rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                margin-bottom: 1.4rem;
                            }
                            @media screen and (max-width:$tablet - 1px){
                                margin-bottom: 2rem;
                                margin-left: -.75rem;
                            }
                            h6{
                                font-weight: 700;
                                color: $second-color;
                                font-size: 1rem;
                                text-transform: uppercase;
                                margin:auto .4rem auto 0;
                                font-family: $second-font;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .9rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (max-width:$tablet - 1px){
                                    font-size: .85rem;
                                }
                            }
                            p{
                                border-radius: 30px;
                                padding:.4rem .8rem;
                                background: $main-color;
                                color: $second-color;
                                font-size: .8rem;
                                margin:0 .4rem;
                                display:flex;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    padding: .3rem .6rem;
                                    font-size: .75rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    padding: .2rem .5rem;
                                    font-size: .7rem;
                                }
                                @media screen and (max-width:$tablet - 1px){
                                    padding: .25rem .6rem;
                                    font-size: .75rem;
                                }
                                span{
                                    width: 8px;
                                    height: 8px;
                                    display:block;
                                    background: url(./images/close.svg) center no-repeat;
                                    background-size: contain;
                                    margin:auto 0 auto .6rem;
                                    cursor: pointer;
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        width: 7px;
                                        height: 7px;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        width: 7px;
                                        height: 7px;
                                    }
                                    @media screen and (max-width:$tablet - 1px){
                                        width: 8px;
                                        height: 8px;
                                    }
                                }
                                &:hover{
                                    background: $main-color-hover;
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
}

.checkout{
    .container{
        .title-box-main{
            margin-bottom:4rem;
            @media screen and (max-width:$desktop - 1px){
                margin-bottom: 2rem;
            }
        }

        .empty-cart{
            font-size: 1.2rem;
            text-align:center;
            color: $second-color;
            padding: 2rem 0;
            font-weight: 600;
            @media screen and (max-width:$widescreen - 1px){
                padding: 1rem 0;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: 1rem;
                padding-bottom: 0;
            }
        }
        .single-row{
            background: #F8F8F8;
            border-radius: 5px;
            padding: .6rem 2rem;
            @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                width: 100%;
                padding: .2rem 2rem;
            }
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                padding: .2rem 1rem;
                width: 100%;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                padding: 0 .4rem;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                position: relative;
                padding: .4rem;
            }
            @media screen and (max-width:$mobile - 1px){
               position: relative;
               padding: .4rem;
            }
            .column{
                &.image{
                    display:flex;
                    @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                        width: 10%;
                    }
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        width: 10%;
                        padding-left: 0;
                    }
                    a{
                        background: $light-grey-back;
                        margin:auto 0;
                        @media screen and (max-width:$tablet - 1px){
                            margin:auto;
                        }
                        img{
                            height: 90px;
                            width: auto;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                height: 70px;
                            }
                        }
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        width: 12%;
                    }
                }
                &.product-info{
                    display:block;
                    margin:auto 0;
                    @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                        width: 40%;
                    }
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        width: 40%;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        width: 38%;
                    }
                    @media screen and (max-width:$tablet - 1px){
                        margin:auto;
                        text-align: center;
                    }
                    .title{
                        color: $second-color;
                        font-family: $second-font;
                        font-size: 1.1rem;
                        font-weight: 500;
                        margin-bottom: .5rem;
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: 1rem;
                            margin-bottom: .3rem;
                        }
                    }
                    .single-price{
                        font-size: .9rem;
                        color: $second-color;
                        font-weight: 500;
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .8rem;
                        }
                        .old-price{
                            margin-left: .6rem;
                            font-size: .8rem;
                            font-weight: 500;
                            text-decoration: line-through;
                            color: $red-color;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .75rem;
                            }
                        }
                        .points{
                            color: $grey-color;
                            font-size: .8rem;
                        }
                    }
                    .variation{
                        font-size: .8rem;
                        color: $second-color;
                        font-weight: 500;
                        position: relative;
                        top: .4rem;
                        background: rgba(0,0,0,.06);
                        border-radius: 7px;
                        display:initial;
                        padding: .3rem .5rem;
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .7rem;
                            padding: .2rem .4rem;
                            top:.2rem;
                        }
                        span{
                            font-weight: 700;
                        }
                    }
                }
                &.quantity{
                    display:flex;
                    position: relative;
                    @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                        width: 20%;
                    }
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        width: 17%;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        width: 20%;
                    }
                    @media screen and (max-width:$tablet - 1px){
                        padding: .2rem 0;
                    }
                  /*  input[type=number]::-webkit-inner-spin-button, 
                    input[type=number]::-webkit-outer-spin-button { 
                    -webkit-appearance: none; 
                    margin: 0; 
                    }*/
                    label{
                        background: white; 
                        border-radius: 30px;
                        padding: .3rem .5rem;
                        border:1px solid #dbdbdb;
                        display:flex;
                        margin:auto 0;
                        @media screen and (max-width:$tablet - 1px){
                            margin:auto;
                        }
                        span{
                            font-size: .8rem;
                            color: $second-color;
                            font-weight: 500;
                            padding-left: 1rem;
                            margin:auto;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                padding-left: .5rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .75rem;
                                padding-left: .3rem;
                            }
                        }
                        input{
                            border:0;
                            border-left: 1px solid rgba(0, 0, 0, 0.05);
                            background:transparent;
                            padding-left: 1.6rem;
                            width: 4rem;
                            margin:auto 0 auto 1rem;
                            font-size: 1.5rem;
                            color: $second-color;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                margin:auto 0 auto .5rem;
                               width: 2.6rem;
                               padding-left: 1rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                margin:auto 0 auto .5rem;
                                width:2rem;
                                padding-left: .6rem;
                            }
                            &:focus{
                                outline: none;
                                box-shadow: 1px 0 1px 5px 0px #cecece;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: 1.2rem;
                            }
                        }
                        input[type=number]::-webkit-inner-spin-button, 
                        input[type=number]::-webkit-outer-spin-button {  
                            opacity: 1;
                        }
                    }
                }
                &.price{
                    display:block;
                    margin: auto 0 auto auto;
                    width: auto;
                    @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                        width: 15%;
                    }
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    }
                    @media screen and (max-width:$tablet - 1px){
                        display: grid;
                    }
                    h4{
                        margin:1.6rem 0 auto auto;
                        text-align: right;
                        font-size: 1.5rem;
                        font-weight: 700;
                        color: $second-color;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            margin-top: 1.1rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: 1.1rem;
                            margin-top: 1.4rem;
                        }
                        @media screen and (max-width:$tablet - 1px){
                            text-align: center;
                            width: 100%;
                        }
                        span{
                            font-weight: 500;
                        }
                    }
                    .update-quantity{
                        margin:auto 0rem auto auto;
                        height: 1.6rem;
                        background: $cta-color;
                        border-radius: 30px;
                        font-weight: 700;
                        font-size: .75rem;
                        display:flex;
                        color: $second-color;
                        padding: 0rem 1rem;
                        width: auto;
                        line-height: 1.6rem;
                        border-radius: 90px;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: .7rem;
                            line-height: 1.4rem;
                            height: 1.4rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .65rem;
                            height: 1.1rem;
                            line-height: 1.1rem;
                            padding: 0 .5rem;
                        }
                        @media screen and (max-width:$tablet - 1px){
                            margin:auto;
                        }
                        &:after{
                            content:"";
                            position: relative;
                            display:block;
                            width: 15px;
                            height: 15px;
                            margin: auto 0 auto auto;
                            background: url(./images/refresh.svg) center no-repeat;
                            background-size: 15px;
                            margin-left: .4rem;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                background-size: 12px;
                                width: 12px;
                                height: 12px;
                            }
                        }
                        
                        cursor: pointer;
                        &:hover{
                            background: $cta-color-hover;
                          &:after{
                            background: url(./images/refresh.svg) center no-repeat;
                            background-size: 15px;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                background-size: 12px;
                            }
                          }
                        }
                    }
                }
                &.delete-box{
                    display:flex;
                    @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                        width: 15%;
                    }
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        width: 10%;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        width: 10%;
                    }
                   
                    .inner{
                        display:flex;
                        border-left: 1px solid rgba(0, 0, 0, 0.05);
                        margin:auto 0 auto auto;
                        height: 100%;
                        padding-left: 3rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            padding-left: 1rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            margin:auto 0 auto auto;
                            padding-left: 1rem;
                        }
                        .delete{
                            margin:auto 0 auto auto;
                            min-width: 30px;
                            min-height: 30px;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                min-width: 25px;
                                min-height: 25px;
                            }
                        }
                        .update-quantity{
                            margin:auto 1rem auto auto;
                            min-width: 30px;
                            min-height: 30px;
                            border-radius: 90px;
                            background: $cta-color url(./images/refresh.svg) center no-repeat;
                            background-size: 20px;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                min-width: 25px;
                                min-height: 25px;
                                background-size: 16px;
                                margin:auto .5rem auto auto;
                            }
                            cursor: pointer;
                            &:hover{
                                background: $cta-color-hover url(./images/refresh.svg) center no-repeat;
                                background-size: 20px;
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    background-size: 16px;
                                }
                            }
                        }
                        @media screen and (max-width:$tablet - 1px){
                            border-left: none;
                            padding-left: 0;
                            margin: auto;
                        }
                    }
                }   
            }
        }
        .button-area{
            .save-cart{
                margin:1rem 0 1rem auto;
                padding: 1.2rem 1.2rem;
                font-size: .8rem;   
            }
        }

        .sum{
            margin-bottom: 2rem;
            h1{
                font-size: 2rem;
                color: $second-color;
                font-weight: 400;
                text-align: right;
                padding: 3rem 0 1.6rem;
                line-height: 0;
                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                    font-size: 1.8rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: 1.8rem;
                    padding: 3rem 0 1.2rem;
                }
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    font-size: 1.8rem;
                    text-align: center;
                    margin:auto;
                    padding: 3rem 0 1rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: 1.6rem;
                    padding: 4rem 0 1rem;
                    text-align: center;
                    margin:auto;
                }
                span{
                    margin-left:1rem;
                    @media screen and (max-width:$mobile - 1px){
                        margin-left: unset;
                    }
                }
            }
            h4{
                font-size: 1.2rem;
                color: $grey-color;
                font-family: $second-font;
                font-weight: 400;
                text-align: right;
                padding: 1rem 0 3.6rem;
                line-height: 0;
                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                    font-size: 1.1rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: 1rem;
                }
                @media screen and (max-width:$tablet - 1px){
                    text-align: center;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: 1rem;
                }
                span{
                    margin-left:.6rem;
                    @media screen and (max-width:$mobile - 1px){
                        margin-left: unset;
                    }
                }
            }
        }
    }
}

.single-page-intro{
   
    .container{
        .title{
            font-family: $second-font;
            color: $second-color;
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom:.6rem;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: 1.7rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: 1.4rem;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                font-size: 1.7rem;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: 1.5rem;
            }
            &.is-desktop{
                @media screen and (max-width:$tablet - 1px){  
                    display:none;
                }
            }
            &.is-mobile{
                display:none;
                margin-left:1rem;
                @media screen and (max-width:$tablet - 1px){  
                    display:block;
                }
            }
        }
        .purpose{
            font-family: $second-font;
            color: $grey-color;
            font-size: 1.15rem;
            font-weight: 400;
            margin-bottom:1rem;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: 1.05rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .9rem;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                font-size: 1.1rem;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: 1rem;
            }
            &.is-desktop{
                @media screen and (max-width:$tablet - 1px){  
                    display:none;
                }
            }
            &.is-mobile{
                display:none;
                margin-left:1rem;
                @media screen and (max-width:$tablet - 1px){  
                    display:block;
                }
            }
        }
        .brand-rating{
            display:block;
            &.is-mobile{
                display: none;
            }
            @media screen and (max-width:$tablet - 1px){
                display:flex;
                &.is-mobile{
                    display: flex;
                    margin: .75rem;
                }
                &.is-desktop{
                    display: none;
                }
            }
        }
        .rating{
            margin-bottom: .6rem;
            display:flex;
            margin-top: .6rem;
            @media screen and (max-width:$tablet - 1px){
                margin: auto 0 auto auto;
            }
            .stars{
                p{
                    color: $grey-color;
                    font-size: .75rem;
                    font-weight: 500;
                }
            }
            .product-marks{
                color: $cta-color-hover;
                font-weight: 700;
                font-size: .85rem;
                display:block;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                   font-size: .8rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .75rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .75rem;
                }
            }
            .comments-button{
                margin: auto 2rem .4rem auto;
                color: $grey-color;
                font-size: .85rem;
                display:table;
                font-weight: 600;
                cursor: pointer;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: .8rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .7rem;
                    margin-right: 0;
                }
                @media screen and (max-width:$tablet - 1px){
                    margin:0 0 1rem;
                    display: none;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .7rem;
                }
                span{
                    width: 17px;
                    height: 17px;
                    display:inline-block;
                    background: url(./images/comment.svg) center no-repeat;
                    background-size: contain;
                    margin-right: .5rem;
                    position: relative;
                    top:.2rem;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        width: 15px;
                        height: 15px;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        width: 14px;
                        height: 14px;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        width: 15px;
                        height: 15px;
                    }
                }
              
            }
        }
        .brand-image{
           width: 100px;
           border:1px solid $light-grey-back
        }
        .columns{
            .column{
                .price-box{
                    &.is-mobile{
                        display: none;
                        .product-stock{
                            margin: auto 0 auto auto;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            display: flex;
                            h4{
                                margin-bottom: .4rem;
                            }
                        }
                    }
                    span{
                        text-transform: uppercase;
                        font-size: .8rem;
                        font-weight: 500;
                        color: $second-color;
                        display:block;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: .75rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .7rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .7rem;
                        }
                    }
                    .price{
                        color: $second-color;
                        font-weight: 700;
                        font-size: 1.8rem;
                        line-height: 2rem;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: 1.6rem;
                            line-height: 1.8rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: 1.6rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: 1.8rem;
                        }
                    }
                    .old-price{
                        font-weight: 700;
                        font-size: 1.2rem;
                        color: $red-color;
                        text-decoration: line-through;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: 1.1rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: 1rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: 1rem;
                        }
                    }
                }
                &.slider-image{
                    @media screen and (max-width:$tablet - 1px){
                        padding-bottom: 0;
                    }
                    .inner{
                        height: 100%;
                        @media screen and (max-width:$tablet - 1px){
                            position:relative;
                            //margin-bottom: 5rem;
                        }
                        .column-thumbs{
                            @media screen and (max-width:$tablet - 1px){
                                display: none;
                                position: absolute;
                                bottom: -4rem;
                                left:0;
                                z-index: 998;
                                width: 100%;

                            }
                            &.is-mobile{
                                display:none;
                                @media screen and (max-width:$tablet - 1px){
                                    display:block;
                                    padding-top:0;
                                }
                            }
                            .slick-slider{
                                height: 100%;
                                .slick-list{
                                    height: 100%;
                                    transform: translate(0,0) !important;
                                    .slick-track{
                                        height: auto !important;
                                        @media screen and (max-width:$tablet - 1px){
                                            width: 100% !important;
                                        }
                                    }
                                    .slick-slide{
                                        width: 96%;
                                        background: $light-grey-back;
                                        position: relative;
                                        display:flex;
                                        border:1px solid $light-grey-back;
                                        margin-bottom:.75rem;
                                        height: 70px !important;
                                        cursor: pointer;
                                        div{
                                            div{
                                                height: 100%;
                                            }
                                        }
                                        @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                                            height: 60px !important;
                                        }
                                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                            height: 50px !important;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            height: 30px !important;
                                        }
                                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                            height: 50px !important;
                                            width: 50px !important;
                                            margin-bottom: 0;
                                        }
                                        @media screen and (max-width:$mobile - 1px){
                                            height: 40px !important;
                                            width: 40px !important;
                                            margin-bottom: 0;
                                        }
                                        @media screen and (max-width:$tablet - 1px){
                                            margin-right: .5rem;
                                        }
                                        &:before{
                                            content:"";
                                            position: absolute;
                                            top:0;
                                            left:0;
                                            background: rgba(0,0,0,0.3);
                                            width: 100%;
                                            height: 100%;
                                            opacity: 0;
                                            -webkit-transition: all .25s cubic-bezier(.46,.82,.61,.87);
                                            -moz-transition: all .25s cubic-bezier(.46,.82,.61,.87);
                                            -o-transition: all .25s cubic-bezier(.46,.82,.61,.87);
                                            transition:all .25s cubic-bezier(.46,.82,.61,.87);
                                        }
                                        img{
                                           width:100%;
                                           height: 100%;
                                           margin:auto;
                                           object-fit: cover;
                                        }
                                        &.slick-current, &:hover{
                                          &:before{
                                            opacity: 1;
                                          }
                                        }

                                        &.slick-cloned{
                                            display:none;
                                        }
                                    }
                                }
                            }
                            
                        }

                        .column-gallery{
                            height: 100%;
                            padding-left: 0;
                            padding-bottom: 0;
                            @media screen and  (max-width:$tablet - 1px){
                                padding: .75rem;
                            }
                            .slick-slider{
                                box-shadow: -3px 10px 20px -10px rgba(0, 0, 0, 0.14);
                                border: 1px solid #ececec;
                                .slick-list{
                                    height: 100%;
                                    .slick-slide{
                                        position: relative;
                                        display:flex;                                   
                                        height: 100%;
                                        div{
                                            div{
                                                height: 100%;
                                                .magnifier{
                                                    height: 100% !important;
                                                }
                                            }
                                        }
                                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                            height: 400px !important;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            height: 350px !important;
                                        }
                                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                            height: 350px !important;
                                        }
                                        @media screen and (max-width:$mobile - 1px){
                                            height: 350px !important;
                                        }
                                        .action-label{
                                            position: absolute;
                                            left:0;
                                            top:1rem;
                                            height: auto;
                                            padding: .6rem .4rem .2rem;
                                            background: $red-color;
                                            border-radius: 0 4px 4px 0;
                                            z-index: 980;
                                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                                padding: .4rem .3rem .1rem;
                                            }
                                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                                padding: .3rem .2rem .1rem;
                                            }
                                            
                                            &.gratis{
                                                padding: .9rem;
                                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                                    padding: .8rem;
                                                }
                                                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                                                    padding: .7rem;
                                                }
                                                @media screen and (max-width:$mobile - 1px){
                                                    padding: .8rem;
                                                }
                                                .percent-discount{
                                                    font-size: .85rem;
                                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                                        font-size: .8rem;
                                                    }
                                                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                                                        font-size: .75rem;
                                                    }
                                                    @media screen and (max-width:$mobile - 1px){
                                                        font-size: .78rem;
                                                    }
                                                }
                                            }
                                            .percent-discount{
                                                color: white;
                                                font-weight: 700;
                                                display:block;
                                                width: 100%;
                                                line-height: .4rem;
                                                font-size: 1rem;
                                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                                    font-size: .9rem;
                                                    line-height: .3rem;
                                                }
                                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                                    font-size: .8rem;
                                                    line-height: .2rem;
                                                }
                                                @media screen and (max-width:$mobile - 1px){
                                                    font-size: .85rem;
                                                }
                                            }
                                            .save-calculator{
                                                font-size: .8rem;
                                                color: white;
                                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                                    font-size: .75rem;
                                                }
                                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                                    font-size: .7rem;
                                                }
                                                @media screen and (max-width:$mobile - 1px){
                                                    font-size: .72rem;
                                                }
                                            }
                                        }
                                        img{
                                            margin:auto;
                                            object-fit: cover;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                            .swiper-button-next{
                                background: url(./images/right-arrow-white.svg) center no-repeat;
                                background-size: contain;
                                width: 35px;
                                height: 35px;
                            }
                            .swiper-button-prev{
                                background: url(./images/right-arrow-white.svg) center no-repeat;
                                background-size: contain;
                                width: 35px;
                                transform: rotate(180deg);
                                height: 35px;
                            }

                        }
                    }
                }

                &.content{
                    padding: 1rem ;
                    padding-bottom: 0;
                    position: relative;
                    @media screen and (max-width:$tablet - 1px){
                        padding-top: 0;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        margin-bottom: .6rem;
                    }
                    
                    .product-stock{
                        border-radius: 7px;
                        font-size: .75rem;
                        display: initial;
                        padding: .5rem 1rem;
                        font-weight: 700;
                        margin-bottom:0;
                        position: relative;
                        top: 1.4rem;
                        &.is-mobile{
                            display: none;
                        }
                        @media screen and (max-width:$tablet - 1px){
                            &.is-desktop{
                                display: none;
                            }
                            &.is-mobile{
                                display: block;
                            }
                        }
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: .8rem;
                            padding: .4rem .8rem;
                            top: .8rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .7rem;
                            padding: .3rem .6rem;
                            top:.6rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .7rem;
                            padding: .3rem .6rem;
                            top: .6rem;
                            
                        }
                        @media screen and (max-width:$tablet - 1px){
                            margin-bottom: 1.6rem;
                            display:inline-flex;
                            width: 50%;
                            top: 0;
                        }
                        &.yes{
                            color: $second-color;
                            background: $main-color;
                        }
                        &.no{
                            color: white;
                            background: $red-color;
                        }
                    }
                    p, li{
                        font-size: .85rem;
                        color: $second-color;
                        font-weight: 500;
                        display: block;
                        margin:.6rem 0 .4rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: .8rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .7rem;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            font-size: .8rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .75rem;
                        }
                    }
                    li{
                        display: list-item;
                    }

                    .button-area{
                        margin:1.6rem 0;
                        @media screen and (max-width:$tablet - 1px){
                            display: inline-block;
                            width: 50%;
                        }
                        .read-more{
                            border:1px solid $cta-color;
                            color: $cta-color;
                            padding: 1rem 1rem;
                            font-size: .8rem;
                            &:focus{
                                outline: none;
                                box-shadow:none;
                            }
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .75rem;
                                padding: .9rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .7rem;
                                padding: .7rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: .7rem;
                                padding: .7rem;
                            }
                        }
                    }
                    .no-variation-selected{
                        color: $red-color;
                        font-size: .8rem;
                        font-weight: 700;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: .75rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .7rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .7rem;
                        }
                    }

                    

                    .variations-box{
                        p{
                            font-weight: 700;
                            text-transform: uppercase;
                            color: $second-color;
                            font-size: .8rem;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .75rem;
                             }
                             @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                 font-size: .7rem;
                             }
                             @media screen and (max-width:$mobile - 1px){
                                 font-size: .7rem;
                             }
                        }
                        .single{
                            display:block;
                            margin-bottom: 1.4rem;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                margin-bottom: 1.2rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                margin-bottom: 1rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                margin-bottom: .7rem;
                            }
                            .variation-title{
                                color: $second-color;
                                font-size: .75rem;
                                font-weight: 500;
                                text-transform: uppercase;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: .7rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .65rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .65rem;
                                }
                            }
                            select{
                                border:none;
                                background: $light-grey-back;
                                font-size: .85rem;
                                font-weight: 500;
                                color: $second-color;
                                width: 50%;
                                font-family: $main-font;
                                border-radius: 30px;
                                padding: .6rem;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: .8rem;
                                    padding: .5rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .7rem;
                                    padding: .45rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .7rem;
                                    padding: .5rem;
                                    width: 100%;
                                }
                                &:focus{
                                    outline:none;
                                    box-shadow: 0 1px 5px 0px rgba(206, 206, 206, 1)
                                }
                                option{

                                }
                            }
                        }
                    }

                   

                    .category-button, .tags-button{
                        color: $grey-color;
                        font-weight: 500;
                        font-size: .8rem;
                        margin:1rem 0 0;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: .8rem;
                        }
                        @media screen and (max-width:$tablet - 1px){
                            display:none;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .8rem;
                        }
                        ul{
                            margin-left: 0;
                            margin-top: .4rem;
                            border:1px solid rgba(0, 0, 0, 0.08);
                            border-radius: 4px;
                            width: auto;
                            display: inline-block;
                            li{
                                display: unset;
                                &:last-child{
                                    a{
                                        border-right: 0;
                                    }
                                    
                                } 
                                a{
                                    border-right: 1px solid rgba(0, 0, 0, 0.08);
                                    padding: .25rem .5rem;
                                    display:inline-flex;
                                   
                                }
 
                            }
                        }

                        a, .single-attribute{
                            margin-top: .1rem;
                            font-weight: 500;
                            font-size: .8rem;
                            color: $second-color;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                margin-top: .8rem;
                                font-size: .8rem;
                            }
                            
                            &:hover{
                                text-shadow: 0px 0px 1px #000
                            }
                            
                        }
                       
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .7rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .7rem;
                        }
                        .icon{
                            width: 14px;
                            height: 14px;
                            display:inline-block;
                            margin-right: .5rem;
                            position: relative;
                            top:.2rem;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                width: 15px;
                                height: 15px;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                width: 13px;
                                height: 13px;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                    .category-button{
                        .icon{
                            background: url(./images/category.svg) center no-repeat;
                            background-size: contain;
                        }
                    }
                    .tags-button{
                        ul{
                            margin-left:26px;
                            margin-top: 0;
                            li{
                                display:inline-block;
                                &:hover{
                                    color: $grey-color !important;
                                }
                            }
                        }
                        .icon{
                            background: url(./images/tags.svg) center no-repeat;
                            background-size: contain;
                        }
                    }
                }

                &.pricing{
                    border:1px solid $light-grey-back;
                    border-radius: 10px;
                    padding:1rem 2rem;
                    margin-bottom: 1.5rem;
                    .is-desktop{
                        @media screen and (max-width:$tablet - 1px){
                            display: none;
                        }
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        padding: 1rem;
                    }
                    @media screen and (max-width:$tablet - 1px){
                        margin:0 1rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        padding: 1rem;
                    }

                    

                    .shipping-box{
                        margin-top:2rem;
                        margin-bottom:3rem;
                        @media screen and (min-width: $tablet) and (max-width:$fullhd - 1px){
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                        }
                        .shipping-method{
                            color: $second-color;
                            font-size: 1rem;
                            margin-top:1rem;
                            font-weight: 500;
                            margin-bottom:0rem;
                            padding-bottom: .6rem;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: .85rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .75rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: .8rem;
                            }
                            span{
                                width: 25px;
                                height: 25px;
                                display:inline-block;
                                background: url(./images/shipping_options_icon.svg) center no-repeat;
                                background-size: contain;
                                margin-right: .5rem;
                                position: relative;
                                top:.4rem;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    width: 24px;
                                    height: 24px;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    width: 20px;
                                    height: 20px;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                        .inner{
                            margin:0rem 0 0;
                            @media screen and (max-width:$tablet - 1px){
                                display:flex;
                                position: relative;
                            }
                            .column{
                                padding: .5rem;
                                border:1px solid $light-grey-back;
                                position: relative;
                                @media screen and (max-width:$tablet - 1px){
                                    position: unset;
                                }
                                &.is-block{
                                    display: block;
                                    a{
                                        color: $cta-color-hover;
                                    }
                                }
                                &:first-child{
                                    border: none;
                                    border-bottom: 0;
                                    @media screen and (max-width:$tablet - 1px){
                                        border-bottom: 1px solid $light-grey-back;
                                    }
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    min-width: 50%;
                                    width: 50%;
                                }
                                &.home-delivery{
                                    i{
                                        background: url(./images/shipping.svg) center no-repeat;
                                        background-size: contain;
                                    }
                                }
                                &.free-pickup{
                                    i{
                                        background: url(./images/pickup_icon.svg) center no-repeat;
                                        background-size: contain;
                                    }
                                }
                                p{
                                    font-size: .8rem;
                                    font-weight: 500;
                                    color: $second-color;
                                    display: flex;
                                    margin: auto;
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        font-size: .75rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .7rem;
                                    }
                                    @media screen and (max-width:$mobile - 1px){
                                        font-size: .7rem;
                                    }
                                    i{
                                        width: 1.2rem;
                                        height: 1.2rem;
                                        display: block;
                                        margin-right: .4rem;
                                    }
                                    .info{
                                        width: 1.3rem;
                                        height: 1.3rem;
                                        display: block;
                                        background:$main-color url(./images/info.svg) center no-repeat;
                                        background-size: .8rem;
                                        border: 1px solid $main-color-hover;
                                        margin: auto 0 auto auto;
                                        border-radius: 90px;
                                        cursor: pointer;
                                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                            width: 1rem;
                                            height: 1rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            width: .85rem;
                                            height: .85rem;
                                            background-size: .5rem;
                                        }
                                        @media screen and (max-width:$mobile - 1px){
                                            width: .95rem;
                                            height: .95rem;
                                            background-size: .5rem;
                                        }
                                    }
                                }
                            }
                        }
                        .shipping-description{
                            color: $grey-color;
                            font-size: .75rem;
                            padding:0 1.4rem 0 2.4rem;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    padding: 0;
                                    margin-top: .3rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                padding: 0;
                                margin-top: .3rem;
                                font-size: .65rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                padding: 0;
                                margin-top: .3rem;
                            }
                        }
                    }

                    .quantity-box{
                        background: $light-grey-back;
                        border-radius:30px;
                        height: 50px;
                        width: 100%;
                        display:flex;
                        margin-bottom:1rem;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            height: 40px;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            height: 40px;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            height: 45px;
                        }

                        p{
                            font-size: .8rem;
                            font-weight: 500;
                            color: $second-color;
                            text-align: center;
                            width: 50%;
                            text-transform: uppercase;
                            margin: auto 0rem;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .7rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .65rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: .7rem;
                            }
                        }
                        .quantity{
                            width: 50%;
                            margin:auto 0 auto auto;
                            text-align: center;
                            font-size: 1.5rem;
                            height: 100%;
                            line-height: 50px;
                            padding: 0 .6rem 0 .6rem;
                            color: $second-color;
                            background: none;
                            font-family: $main-font;
                            font-weight: 500;
                            border:0;
                            border-left:1px solid #ebebeb;
                            &:focus{
                                outline:none;
                            }
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: 1.4rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: 1.2rem;
                                line-height: 40px;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: 1.4rem;
                                line-height: 45px;
                            }
                        }
                        input[type=number]::-webkit-inner-spin-button, 
                        input[type=number]::-webkit-outer-spin-button {  
                            opacity: 1;
                        }
                        input[type=number]::-webkit-inner-spin-button {
                            position: relative;
                            right: 20%;
                        }
                    }
                    

                    .button{
                        background: $cta-color;
                        border-radius: 30px;
                        padding:0;
                        width: 100%;
                        font-size: 1.05rem;
                        font-weight: 700;
                        margin-bottom:1rem;
                        color: $second-color;
                        height: 50px;
                        font-family: $main-font;
                        line-height: 50px;
                        &:hover{
                            box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.23);
                        }
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                         font-size: .9rem;   
                         line-height: 40px;
                         height: 40px;        
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .9rem;
                            height: 40px;
                            line-height: 40px;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .9rem;
                            height: 45px;
                            line-height: 45px;
                        }
                        span{
                            width: 30px;
                            height: 30px;
                            display:inline-block;
                            margin-right: .5rem;
                            position: relative;
                            top:.1rem; 
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                width: 26px;
                                height: 26px;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                width: 22px;
                                height: 22px;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                width: 24px;
                                height: 24px;
                            }
                        }
                        &.add-to-cart{
                            background: rgb(200,227,81);
                            background: linear-gradient(180deg, rgba(200,227,81,1) 0%, rgba(186,210,76,1) 50%, rgba(163,187,56,1) 100%);
                            &:hover{
                                background: linear-gradient(180deg, #9dbb17 0%, #9dbb17 50%, #9dbb17 100%);
                            }
                            &:focus{
                                outline: none;
                                box-shadow: none;
                            }
                            span{
                                background: url(./images/add-to-cart-dark.svg) center no-repeat;
                                background-size: contain;
                            }
                        }

                        &.buy-now{
                            border:1px solid #bbda31;
                            &:hover{
                                background: $cta-color-hover;
                                border:1px solid #9dbb17;
                            }
                            &:focus{
                                outline: none;
                                box-shadow: none;
                            }
                            span{
                                background: url(./images/buy-now.svg) center no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                    .free-shipping{
                        margin:1rem 0;
                        background: $main-color;
                        padding: .6rem 1rem;
                        border-radius: 7px;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            padding: .4rem .8rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            margin: 1rem 0 0;
                            padding: .2rem .6rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            margin:1rem 0 0;
                            padding: .3rem .8rem;
                        }
                        p{
                            color: $second-color;
                            font-size: .75rem;
                            line-height: 1.1rem;
                            font-weight: 700;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .7rem;
                                line-height: 1rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .6rem;
                                line-height: .9rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: .75rem;
                            }
                            span{
                                font-weight: 600;
                                font-size: 1rem;
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .9rem;
                                }
                            }
                        }
                    }
                    
                }

                .points-box{
                    display:flex;
                    border:1px solid rgba(0,0,0, .06);
                    border-radius: 4px;
                    background: rgba(0,0,0,.03);
                    padding: .5rem;
                    margin: 1rem 0;
                    &.is-mobile{
                        display:none;
                    }
                    @media screen and (max-width:$tablet - 1px){
                        &.is-desktop{
                            display: none;
                        }

                        &.is-mobile{
                            display:none;
                        }
                    }
                    
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        padding: .4rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        padding: .3rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                       padding: .3rem;
                    }
                    p{
                        font-size: .8rem;
                        color: $second-color;
                        font-weight: 500;
                        width: 83%;
                        margin:auto .3rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: .75rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .65rem;
                            margin-right: 0;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .7rem;
                        }
                        span{
                            font-weight: 700;
                            color: $main-color-hover;
                        }
                    }
                    .icon{
                        width: 2.6rem;
                        margin:0;
                        height: auto;
                        display:block;
                        background: url(./images/points-icon.svg) 0 .5rem no-repeat;
                        background-size: contain;
                        margin-right: .4rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            width: 2.2rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            width: 2rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            width: 2rem;
                        }
                    }
                }

            }
        }
    }
}

.single-page-desc{
    background: url(./images/offer-back.png) center repeat;
    a{
        color: $cta-color-hover;
        font-weight: 700;
    }
    .container{
        background: white;
        padding-bottom:3rem;
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            padding-bottom: 1rem;
        }
        @media screen and (max-width:$mobile - 1px){
            padding-bottom: 1rem;
        }
        @media screen and (max-width:$tablet - 1px){
            background: none;
        }
        .header-menu{
            border-bottom: 1px solid $light-grey-back;
            display:flex;
            @media screen and (max-width:$tablet - 1px){
                border-bottom: 0;
                background: white;
            }
            ul{
                display:flex;
                margin: auto;
                @media screen and (max-width:$tablet - 1px){
                    display:block;
                    width: 100%;
                }
                li{
                    margin:auto 1.4rem;
                    padding: .9rem 0;
                    border-bottom: 2px solid white;
                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                        padding: .8rem 0;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        padding: .75rem 0;
                    }
                    @media screen and (max-width:$tablet - 1px){
                        width: 100%;
                        margin:0;
                        padding-left:.8rem;
                        border-bottom: 1px solid $light-grey-back;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        padding: .9rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        padding: .8rem;
                    }
                    span{
                        font-size: .9rem;
                        color: $second-color;
                        padding-left:1.6rem; 
                        font-weight: 500;
                        padding: .5rem .3rem .5rem 1.6rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: .9rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .8rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .85rem;
                        }
                        
                        cursor: pointer;
                        &.product-desc{   
                            background: url(./images/product-desc.svg) left no-repeat;
                            background-size: 20px;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                background-size: 20px;
                            } 
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                background-size: 18px;
                            }
                            @media screen and (max-width:$tablet - 1px){
                                background-size: 20px;
                            }
                        }
        
                        &.product-shipping{
                            background: url(./images/shipping.svg) left no-repeat;
                            background-size: 20px; 
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                background-size: 20px;
                            } 
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                background-size: 18px;
                            }
                            @media screen and (max-width:$tablet - 1px){
                                background-size: 20px;
                            }
                            
                        }
        
                        &.product-pricing-types{
                            background: url(./images/product-pricing-types.svg) left no-repeat;
                            background-size: 20px; 
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                background-size: 20px;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                background-size: 18px;
                            } 
                            @media screen and (max-width:$tablet - 1px){
                                background-size: 20px;
                            }
                        }
                        &.product-attributes{
                            background: url(./images/tags.svg) left no-repeat;
                            background-size: 20px; 
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                background-size: 20px;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                background-size: 18px;
                            } 
                            @media screen and (max-width:$tablet - 1px){
                                background-size: 20px;
                            }
                        }
                        &.product-recensions{
                            background: url(./images/recension.svg) left no-repeat;
                            background-size: 20px; 
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                background-size: 20px;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                background-size: 18px;
                            } 
                            @media screen and (max-width:$tablet - 1px){
                                background-size: 20px;
                            }
                        }
                    }

                    &.react-tabs__tab--selected, &:hover{
                        border-bottom: 2px solid $cta-color;
                     }
                }
            }
        }
        .content{
            font-size: .9rem;
            color: $second-color;
            margin:3rem;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .8rem;
            } 
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .7rem;
                margin: 2rem;
            }
            @media screen and (max-width:$tablet - 1px){
                font-size: .8rem;
                margin: 1rem 0 0;
                background: white;
            }
            .inner{
                @media screen and (max-width:$tablet - 1px){
                    padding: 1rem;
                }
                img{
                    margin-bottom: .5rem;
                }
            }
            h3{
                color: $second-color;
                text-transform: uppercase;
                font-size: 1.3rem;
                margin-bottom: 0;
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: 1rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: 1rem;
                }
            }
            h4{
                font-weight: 700;
                margin:3rem 0 .5rem 0;
                font-size: 1.05rem;
                color: $second-color;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: .9rem;
                } 
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .7rem;
                    margin: 2rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .8rem;
                }
            }
            h3{
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: 1.2rem;
                } 
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: 1.1rem;
                    margin: 2rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: 1rem;
                }  
            }
            p, li{
                font-weight: 400;
                margin-bottom: .4rem;
               
            }
            strong{
                color: $second-color;
            }
            &.product-attributes{
                .column{
                    width:100%;
                    margin:0rem 0 .4rem;
                    .attributes-group-name{
                        color: $second-color;
                        font-size: 1.05rem;
                        font-family: $second-font;
                    }
                    .attributes-box{
                        display:flex;
                        flex-wrap: wrap;
                        p{
                            border:1px solid $light-grey-back;
                            font-size: .9rem;
                            margin-right: .6rem;
                            color: $second-color;
                            padding:.6rem 1.2rem;
                        }
                    }
                }
            }
            &.product-recensions{
                .recensions-box{
                    margin-top: 2rem;
                    .single-recension{
                        border:1px solid $light-grey-back;
                        display:flex;
                        padding: 1rem 1.4rem;
                        margin:1rem 0 0;
                        .avatar{
                            width: 3rem;
                            height: 3rem;
                            margin:auto 0;
                            display: block;
                            background: url(./images/recension_avatar.svg) center no-repeat;
                            background-size: contain;
                        }
                        .content{
                            margin:0 1.4rem;
                            .name{
                                font-weight: 700;
                                font-size: 1rem;
                                color: $second-color;
                                margin-bottom: .3rem;
                            }
                            
                            .meta{
                                display:flex;
                                span{
                                    font-size: .75rem;
                                    font-weight: 500;
                                    color: $grey-color;
                                    position: relative;
                                    margin-bottom: .4rem;
                                    margin-right: 1rem;
                                    padding-left: 1.1rem;
                                    &:before{
                                        content:"";
                                        width:.75rem;
                                        height: .75rem;
                                        position: absolute;
                                        left:0;
                                        top:.15rem;
                                        display:block;
                                    }
                                }
                                .email{
                                    &:before{
                                        background: url(./images/email-recension.svg) center no-repeat;
                                        background-size: contain;
                                    }
                                }
                                .date{
                                    &:before{
                                        background: url(./images/date_recension.svg) center no-repeat;
                                        background-size: contain;
                                    }
                                }
                            }
                            
                            .comment{
                                font-size: .9rem;
                                font-weight: 400;
                                margin-bottom: 0rem;
                                color: $text-color;
                            }
                        }
                        .rate{
                            margin:auto 0 auto auto;
                        }
                    }
                }
                
               
            }
            &.product-pricing-types{
                .paypal-title{
                    display:flex;
                    .icon{
                        width: 5rem;
                        height: auto;
                        margin-left: .6rem;
                        display:block;
                        background: url(./images/paypal-icon.png) center no-repeat;
                        background-size: contain;
                    } 
                }
            }
        }
    }
}

.recensions-popup{
    .default-form{
        width: 50%;
        position: relative;
        padding: 1.6rem 2rem;
        .close{
            background: url(./images/close.svg) center no-repeat;
            background-size: contain;
            width: 1.2rem;
            height: 1.2rem;
            display:block;
            position: absolute;
            right: 1.6rem;
            top:1.6rem;
            cursor: pointer;
        }
        .part-title{
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 400;
            color: $second-color;
            margin-bottom: 2.4rem;
        }
        .button-area{
            margin-top: 1rem;
            .button{
                height: unset;
                padding: 1rem;
            }
        }
    }

    .thank-you{
        padding: 4rem 0;
        .icon{
            width: 4rem;
            height: 4rem;
            background: url(./images/thankyou.svg) center no-repeat;
            display:block;
            margin:auto auto 2rem;
            background-size: contain;
        }
        p{
            text-align: center;
            font-size: 1rem;
            color: $text-color;
            font-weight: 700;
            margin:0;
        }
    }
}

.single-page-recomended{
    background: url(./images/offer-back.png) center repeat;
    @media screen and (max-width:$fullhd - 1px){
        padding: 1.5rem;
    }
    .container{
        .products-slider{
          //  position: unset;
            margin-top: 2rem;
           // .slick-cloned { display: none !important; }
        }
    }
}



.order-default{
    .container{
        .title-box-main{
            margin-bottom: 2rem;
        }
        .part-title{
            font-size: 1.4rem;
            font-weight: 500;
            text-transform: uppercase;
            color: $second-color;
            margin-bottom: 3rem;
            margin-top:0;
        }
    }
}
.order{
    .container{
        .title-box-main{
            margin-bottom: 2rem;
        }
        .shipping-table{
            top: .5rem;
            right: 3rem;
            @media screen and  (max-width:$tablet - 1px){
                top: 100%;
                right: 0;
                width: 100%;
            }
        }
        .part-title{
            font-size: 1.4rem;
            font-weight: 500;
            text-transform: uppercase;
            color: $second-color;
            margin-bottom: 2rem;
            margin-top:0;
            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                font-size: 1.2rem;
                margin-bottom: 2rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: 1.1rem;
                margin-bottom: 1rem;
            }
            @media screen and  (max-width:$mobile - 1px){
                font-size: 1rem;
                margin-bottom: 1.4rem;
            }
        }
        form{
            fieldset{
                border:0;
                .is-left{
                    padding-top: 0 !important;
                    .coupon-box{
                        width: 100%;
                        margin: 0 !important;
                        padding: 0 .45rem;
                        .coupon-code{
                            span{
                                font-size: .85rem;
                                color: $second-color;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .75rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .75rem;
                                }
                            }
                            input{
                                border-radius: 0;
                                margin-top: .3rem;
                                border:2px solid $cta-color;
                                padding: 1.4rem 1.6rem;
                                font-size: 1.2rem;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: 1.1rem;
                                    padding: 1.2rem 1.4rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: 1rem;
                                    padding: 1rem 1.2rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: 1rem;
                                    padding: 1rem 1.2rem;
                                }
                                &:focus{
                                    box-shadow: none;
                                }
                            }
                            ::placeholder{
                                color: $grey-color;
                            }
                        }
                        .button-area{
                            width: 50% !important;
                            display:flex !important;
                            height: 100%;
                            padding: .35rem !important;
                            .activate-coupon{
                                margin:2.6rem 0 1rem;
                                padding: 1rem;
                                width: unset;
                                height: 3rem;
                                font-size: .8rem;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    margin: .6rem 0 1rem;
                                    font-size: .75rem;
                                    padding: .8rem;
                                    height: 2.6rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    margin: .6rem 0 .6rem;
                                    font-size: .7rem;
                                    padding: .6rem;
                                    height: 2.2rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    margin: .2rem 0 .6rem;
                                    font-size: .7rem;
                                    padding: .6rem;
                                    height: 2.2rem;
                                }
                            }
                        }
                    }
                   .notes{
                       padding:0 .75rem;
                    textarea{
                        border-radius: 0;  
                    }
                   }
                    .coupon-validity{
                        font-size: .8rem;
                        color: white;
                        padding: .7rem;
                        margin-left: .75rem;
                        font-weight: 500;
                        border-radius: 4px;
                        margin-bottom: 2rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: .75rem;
                            padding: .5rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .7rem;
                            padding: .4rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: .7rem;
                            padding: .4rem;
                        }
                        &.valid{
                            background: $main-color;
                        }
                        &.invalid{
                            background: $red-color;
                        }
                    }
                }
                .subtitle{
                    display:block;
                    width: 100%;
                    text-transform: uppercase;
                    color: $second-color;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    padding: 0;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: .9rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .85rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .85rem;
                    }
                }
                

                .columns{
                    margin: 0;
                    .payment-options{
                        margin:1rem 0 2rem !important;
                        .subtitle{
                            font-size: 1rem;
                        }
                      &.shipping-options{
                        @media screen and (max-width:$mobile - 1px){
                            padding: .4rem;

                        }
                          margin-bottom: 0rem !important;
                      }
                    }
                  
                    .privacy-policy-label{
                        padding-bottom: 0;
                    }
                }
                .is-left{
                    margin:0;
                    padding: 2rem 3rem 2rem 0;
                    @media screen and (max-width:$tablet - 1px){
                        padding-right: 0;
                        margin-bottom: 2rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        padding-top: .4rem;
                        padding-bottom: 0;
                    }
                    .form{
                    
                    .contact-info{
                        margin-bottom: 2rem;
                        .inner{
                            border:1px solid $light-grey-back;
                            padding:1.2rem;
                            .single-row{
                                margin-bottom: .2rem;
                                p{
                                    font-size: .85rem;
                                    color: $second-color;
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        font-size: .8rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .75rem;
                                    }
                                    @media screen and (max-width:$mobile - 1px){
                                        font-size: .75rem;
                                    }
                                }
                            }
                            .button-area{
                                margin-top: 1rem;
                                .button{
                                    height: 40px;
                                    font-size: .8rem;
                                    width: auto;
                                    padding: 0 1rem;
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        height: 35px;
                                        padding: 0 .8rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        height: 35px;
                                        font-size: .75rem;
                                        padding: 0 .7rem;
                                    }
                                    @media screen and (max-width:$mobile - 1px){
                                        height: 35px;
                                        font-size: .7rem;
                                        padding: 0 .7rem;
                                    }
                                }
                            }
                        }
                       
                    }
                    label{
                        textarea{
                            height: 100px;
                        }
                    }
                    .columns{
                        margin:-.75rem;
                        .column{
                            font-size: 1rem;
                            margin:.2rem 0;
                            color: $second-color;
                            font-weight: 500;
                            a{
                                color: $cta-color-hover;
                            }
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .8rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .8rem;
                            }
                            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                font-size: .9rem;
                            }
                            @media screen and  (max-width:$mobile - 1px){
                                font-size: .8rem;
                            }
                           
                           
                        }
                        .button-area{
                            width: 100%;
                            padding: .75rem;
                            display:block;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                margin-bottom: 1rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                margin-bottom: 1rem;
                            }
                            .notice{
                                font-size: .9rem;
                                font-weight: 500;
                                color: $second-color;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .7rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .7rem;
                                }
                                
                            }
                          .privacy-policy{
                                color: $second-color;
                                font-weight: 600;
                                font-size: .9rem;
                                text-decoration: underline;
                                border-radius: 30px;
                                margin:0;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .85rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: .9rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .8rem;
                                }
                          }
                        
                        }
                    }
                }
                .button-area{
                    margin-top:0rem;
                    padding: 0;
                    position: relative;
                    .button{
                            background: $cta-color;
                            width: 100%;
                            font-weight: 700;
                            padding: 0;
                            font-size: .92rem;
                            height: 55px;
                            line-height: 55px;
                            color: $second-color;
                            &:focus{
                                outline: none;
                                box-shadow: none;
                            }
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                height: 50px;
                                line-height: 50px;
                                font-size: 1rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                height: 42px;
                                line-height: 42px;
                                font-size: .9rem;
                            }
                            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                height: 50px;
                                line-height: 50px;
                                font-size: 1rem;
                            }
                            @media screen and  (max-width:$mobile - 1px){
                                height: 45px;
                                line-height: 45px;
                                font-size: .9rem;
                            }
                            .icon{
                                width: 28px;
                                height: 28px;
                                display:block;
                                background: url(./images//shopping-cart.svg) center no-repeat;
                                background-size: contain;
                                margin-right: .3rem;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    width: 32px;
                                    height: 32px;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    width: 30px;
                                    height: 30px;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    width: 32px;
                                    height: 32px;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    width: 28px;
                                    height: 28px;
                                }
                            }
                    }
                    .paypal-button-context-iframe{
                        width: 100% !important;
                        position: absolute;
                        right: 0;
                        .zoid-outlet{
                            width: 100% !important;
                        }
                    }
                    &.next-step{
                        .button{
                            i{
                                width: 22px;
                                margin-right: 0;
                                margin-left: .8rem;
                                background:url(./images/next-arrow.svg) center no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                }
            }
        }

        .is-right{
            background: $light-grey-back;
            margin-top:0;
            .inner{
                padding: 1rem;
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    padding:1rem .4rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    padding: .4rem;
                }
                .button-area{
                    padding: 0;
                    margin:1.6rem 0 0;
                    .button{
                        width: unset;
                        padding: 1rem;
                        font-size: .75rem;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            padding: .8rem;
                            font-size: .7rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            padding: .8rem;
                            font-size: .6rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            padding: .8rem;
                        font-size: .65rem;
                        }
                    }
                }
                .single-row{
                    margin:1.2rem 0;
                    @media screen and (max-width:$tablet - 1px){
                        display:flex;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        margin:.8rem 0;
                    }
                    .column{
                        padding: .6rem;
                        @media screen and (max-width:$mobile - 1px){
                            padding: .2rem;
                        }
                        &.image{
                            position: relative;
                            display:flex;
                            padding-left: 0;
                            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                max-width: 13%;
                            }
                            @media screen and  (max-width:$mobile - 1px){
                                max-width: 13%;
                            }
                            a{
                                background: $light-grey-back;
                                margin:auto 0;
                                position: relative;
                                img{
                                    height: 60px;
                                    width: auto;
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        height: 40px;
                                    }
                                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                       height: 50px;
                                       margin:0;
                                    }
                                    @media screen and  (max-width:$mobile - 1px){
                                        height: 40px;
                                        margin:0;
                                    }
                                }
    
                                .quantity{
                                    width: 25px;
                                    height: 25px;
                                    line-height: 25px;
                                    color: $second-color;
                                    background: $cta-color;
                                    border-radius: 90px;
                                    position: absolute;
                                    top:-.8rem;
                                    font-weight: 500;
                                    font-size: .8rem;
                                    text-align: center;
                                    right: -.8rem;
                                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                        width: 20px;
                                        height: 20px;
                                        line-height: 20px;
                                        right: -.4rem;
                                        font-size: .75rem;
                                        top:-.4rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        width: 18px;
                                        height: 18px;
                                        line-height: 18px;
                                        right: -.4rem;
                                        top:-.4rem;
                                        font-size: .7rem;
                                    }
                                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                        width: 22px;
                                        height: 22px;
                                        line-height: 22px;
                                        right: -.6rem;
                                        top:-.6rem;
                                    }
                                    @media screen and  (max-width:$mobile - 1px){
                                        width: 14px;
                                        height: 14px;
                                        line-height: 14px;
                                        right: -.4rem;
                                        top:-.4rem;
                                        font-size: .6rem;
                                    }
                                }
                            }
                          
                        }
                        &.product-info{
                            padding-left: 1.2rem;
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                padding-left: .6rem;
                            }
                            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                padding-left: .8rem;
                            }
                            @media screen and  (max-width:$mobile - 1px){
                                padding-left: .4rem;
                            }
                            .title{
                                font-family: $second-font;
                                font-size: 1rem;
                                text-transform: none;
                                margin-bottom: .3rem;
                                @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                                    font-size: 1rem;
                                }
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .9rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: 1rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .8rem;
                                    word-break: normal;
                                }
                            }
                            .single-price{
                                font-size: .8rem;
                                color: $text-color;
                                font-weight: 500;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .7rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: .75rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .6rem;
                                }
    
                            }
                            .old-price{
                                margin-left: .4rem;
                                text-decoration: line-through;
                                font-size: .7rem;
                                color: $red-color;
                                font-weight: 500;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .65rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: .6rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .6rem;
                                }
                            }
                            .points{
                                font-size: .7rem;
                                color: $grey-color;
                                font-weight: 500;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .65rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: .6rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .6rem;
                                }
                            }
                            .variation{
                                font-weight: 500;
                                font-size: .7rem;
                                color: $second-color;
                                background: rgba(0, 0, 0, 0.05);
                                padding: .2rem .4rem;
                                position: relative;
                                border-radius: 7px;
                                display:initial;
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .65rem;
                                    padding: .2rem .3rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .6rem;
                                    padding: .2rem .3rem;
                                }
                                span{
                                    font-weight: 700;
                                }
                            }
                        }
                      
                        &.price{
                            text-align: right;
                            display:flex;
                            h4{
                                margin:auto 0 auto auto;
                                font-size: 1.1rem;
                                font-weight: 600;
                                color: $second-color;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: 1rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .9rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: 1rem;
                                    margin:auto 0 auto auto;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .8rem;
                                    margin:auto 0 auto auto;
                                }
                               
                                span{
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
                .sum{
                    border-top:1px solid rgba(0, 0, 0, 0.05);
                    margin-top:1rem;
                    h1{
                        font-size: 1.6rem;
                        color: $second-color;
                        font-weight: 400;
                        text-align: left;
                        padding: 3rem 0 1rem;
                        line-height: 0;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: 1.4rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: 1.2rem;
                            padding: 2rem 0;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            font-size: 1.4rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            font-size: 1.2rem;
                            padding:2rem 0rem 1rem;
                        }
                        span{
                            margin:auto 0 auto auto;
                            display:block;
                            text-align: right;
                        }
                    }
                    .free-order-info{
                        margin:.8rem 0 0;
                        font-family: $second-font;
                        font-size: 1.1rem;
                        color: $grey-color;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: 1rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .85rem;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            font-size: 1rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            font-size: .85rem;
                        }
                    }
                    h4{
                        margin:.6rem 0;
                        font-size: 1rem;
                        color: $second-color;
                        display:flex;
                        &.total-points{
                            color: $grey-color;
                        }
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: .9rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .8rem;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            font-size: .9rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            font-size: .8rem;
                        }
                        span{
                            margin:0 0 0 auto;
                            text-align: right;
                            }
                        }
                        .remove-coupon{
                            color: $second-color;
                            font-weight: 700;
                            font-size: .75rem;
                            margin:1rem 0;
                            background: $cta-color;
                            cursor: pointer;
                            padding: .4rem .8rem;
                            border-radius: 30px;
                            &:hover{
                                background: $cta-color-hover;
                            }
                        }
                    }
            }
           
            }
        }
    }
}

.finished-order{
    .container{
        .columns{
            width: 80%;
            margin:0 auto;
            border:1px solid $light-grey-back;
            @media screen and (max-width:$tablet - 1px){
                width: 100%;
            }
            .order-number{
                background: $second-color;
                font-size: 2rem;
                font-weight: 400;
                padding: 2rem;
                width: 100%;
                color: white;
                display:flex;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: 1.7rem;
                    padding: 1.8rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: 1.5rem;
                    padding: 1.5rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: 1.1rem;
                    padding: 1.1rem;
                }
               
                span{
                    margin-left:.4rem;
                    font-weight: 600;
                }
                .print-order{
                    font-size: 1rem;
                    margin:auto 0 auto auto;
                   
                }
            }

            .part-title{
                padding: 2rem;
                margin-bottom: 0;
                border-bottom: 1px solid $light-grey-back;
                width: 100%;
                font-size: 1.3rem;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: 1.2rem;
                    padding: 1.8rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .9rem;
                    padding: 1.5rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .8rem;
                    padding: 1.1rem;
                }
            }
            .column{
                margin-top:0;
                padding: 0;
            .single-row{
                display:flex;
                padding: .8rem 2rem;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    padding: .7rem 1.8rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    padding: .5rem 1.5rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    padding: .4rem 1.3rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    padding: .3rem 1.1rem;
                }
                .name{
                    font-size: 1rem;
                    color: $second-color;
                    font-weight: 400;
                    margin:auto 0;
                    width: 25%;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: .9rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        font-size: .8rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .8rem;
                    }
                }
                .answer{
                    font-weight: 600;
                    font-size: 1rem;
                    color: $second-color;
                    margin:auto 0;
                    @media screen and (min-width: $mobile) and (max-width:$widescreen - 1px){
                        font-size: .9rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        font-size: .8rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .8rem;
                    }
                }

                &:nth-child(odd){
                    background: $light-grey-back;
                }
            }
            &.payment-info{
              margin-top:2rem;
              margin-bottom: 2rem;
                .total{
                    margin-top:.6rem;
                    .name{
                        font-size: 1.8rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: 1.6rem;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                            font-size: 1.4rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: 1rem;
                        }
                    }
                    .answer{
                        font-size: 1.8rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: 1.6rem;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                            font-size: 1.4rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            font-size: 1rem;
                            margin-left: .4rem;
                        }
                    }
                }
            }

            &.order-items{

                .table-header{
                    margin:0;
                    width: 100%;
                    padding: 0 2rem;
                    background: $second-color;
                    @media screen  and (max-width:$tablet - 1px){
                        display:flex;
                        padding: 0 1rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        padding: 0 1rem;
                    }
                    .column{
                        padding:0 .6rem;
                        border-right: 1px solid rgba(255,255,255,.15);
                        p{
                            color: white;
                            font-size: .9rem;
                            font-weight: 600;
                            padding: 1rem 0;
                            @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                                padding: .8rem 0;
                                font-size: .8rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                padding: .6rem 0;
                                font-size: .7rem;
                                font-weight: 500;
                            }
                        }
                        &:last-child{
                            border-right: 0;
                        }
                    }
                }
                .single-row{
                    width: 100%;
                    margin: 0;
                    padding: 0 2rem;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                       padding: 0 1.8rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        padding: 0 1.5rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        padding: 0 1.1rem;
                    }
                    .column{
                        padding: .6rem;
                        border-right:1px solid rgba(0, 0, 0, 0.05);
                        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                            padding: .4rem;
                        }
                        &:last-child{
                            border-right: 0;
                        }
                        &.image{
                            position: relative;
                            display:flex;
                            padding-left: 0;
                            img{
                                height: 60px;
                                width: auto;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    height: 55px;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                                    height: 45px;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    height: 40px;
                                }
                            }
                            a{
                                background: $light-grey-back;
                                margin:auto 0;
                                
                            }
                        }
                        &.quantity, &.deliverer{
                            display:flex;
                            p{
                                margin:auto 0;
                                font-size: .9rem;
                                font-weight: 600;
                                color: $second-color;
                                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: 0.75rem;
                                }
                            }
                          

                        }
                        &.product-info{
                            .title{
                                font-size: .9rem;
                                text-transform: none;
                                margin-bottom: .3rem;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .85rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .7rem;
                                }
                            }
                            .single-price{
                                font-size: .8rem;
                                color: $text-color;
                                font-weight: 500;
                                margin-bottom: 0;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .75rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                                    font-size: .7rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .65rem;
                                }
                            }
                            .old-price{
                                margin-left: .4rem;
                                text-decoration: line-through;
                                font-size: .7rem;
                                color: $red-color;
                                font-weight: 500;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .65rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: .6rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .6rem;
                                }
                            }
                            .variation{
                                font-weight: 500;
                                font-size: .7rem;
                                color: $second-color;
                                background: rgba(0, 0, 0, 0.05);
                                padding: .2rem .4rem;
                                position: relative;
                                border-radius: 7px;
                                display:initial;
                                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                                    font-size: .65rem;
                                    padding: .1rem .3rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .6rem;
                                    padding: .1rem .2rem;
                                }
                                span{
                                    font-weight: 700;
                                }
                            }
                        }
                      
                        &.price{
                            text-align: left;
                            display:flex;
                            h4{
                                margin:auto 0;
                                font-size: .9rem;
                                font-weight: 600;
                                color: $second-color;
                                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .7rem;
                                }
                               
                                span{
                                    font-weight: 500;
                                }
                            }
                        }
                        
                    }
                }
            }
        }
        }
       
    }
}

@media print {
    .finished-order{
        .container{
            .columns{
                width: 100%;
                margin:0 auto;
                border:1px solid $light-grey-back;
                .order-number{
                    font-size: 2rem;
                    padding:1rem 2rem;
                }
    
                .part-title{
                    padding:1rem 2rem;
                    font-size: 1.1rem;
                }
                .column{
                .single-row{
                    padding: .6rem 2rem;
                    .name{
                        font-size: .9rem;
                    }
                    .answer{
                        font-size: .9rem;
                    }
                }
                &.payment-info{
                  margin-top:2rem;
                  margin-bottom:2rem;
                    .total{
                        .name{
                            font-size: 1.5rem;
                        }
                        .answer{
                            font-size: 1.5rem;
                        }
                    }
                }
    
                &.order-items{
                    .table-header{
                        .column{
                            p{
                                font-size: .75rem;
                                padding: .4rem 0;
                            }
                        }
                    }
                    .single-row{
                        .column{
                            padding: .4rem;
                            &.quantity, &.deliverer{
                                display:flex;
                                p{
                                    font-size: .8rem;
                                }
                              
    
                            }
                            &.product-info{
                                .title{
                                    font-size: .8rem;
                                }
                                .single-price{
                                    font-size: .75rem;
        
                                }
                            }
                          
                            &.price{
                                h4{
                                    font-size: .8rem;
                                }
                            }
                            
                        }
                    }
                }
            }
            }
           
        }
    }
 }

.thank-you{
    .container{
       .button-area{
           margin-top: 2rem;
       }
    }
}

.contact{
    &.top{
        padding-bottom: 2rem;
        padding-top: 6rem;
        background: $light-grey-back;
        .container{
            .columns{
                width: 100%;
                .is-left{
                    h4{
                        color: $second-color;
                        text-transform: uppercase;
                        font-size: 1.4rem;
                        font-weight: 400;
                        margin-bottom: 1rem;
                    }
                    p{
                        font-size: 1rem;
                        width: 70%;
                        font-weight: 500;
                        color: $second-color;
                    }
                }

                .is-right{
                    margin:auto 0 0 auto;
                    .inner{
                        display:flex;
                        margin:auto 0 0;
                    .icon{
                        width: 4rem;
                        height: 4rem;
                        background: url(./images/contact-page.svg) center no-repeat;
                        background-size: contain;
                        display:block;
                        margin-right: 1rem;
                    }
                    p{
                        font-size: 1.05rem;
                        color: $second-color;
                        font-weight: 500;
                        margin:auto 0;
                        span{
                            text-transform: uppercase;
                            font-weight: 700;
                        }
                    }
                    }
                }
            }
        }
    }
    &.main{
        .container{
            .is-left{
                margin:auto 0;
                .title{
                    font-weight: 500;
                    color: $second-color;
                    font-size: 2.6rem;
                    width: 70%;
                }
                p{
                    margin-bottom: 1rem;
                    color: $second-color;
                    font-size: 1rem;
                    width: 70%;
                    font-weight: 500;
                }
                .social-box{
                    .icon{
                        display:block;
                        height: 3rem;
                        width: 2.4rem;
                        &.phone{
                            background:url(./images/phone.svg) center no-repeat;
                            background-size: contain;
                        }
                        &.viber{
                            background:url(./images/viber.svg) center no-repeat;
                            background-size: contain;
                        }
                        &.whatsapp{
                            background:url(./images/whatsapp.svg) center no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }
}

}


.about-us{
    .container{
        .content{
            display:flex;
            .back-image{
                width: 30%;
                height: auto;
                background-size: contain;
                background-position: left;
                background-repeat: no-repeat;
                display:block;
            }
            .text-content{
                width: 70%;
                padding-right: 2rem;
            }
    
        }
    }
}

.search-page{
    .container{
        .search-results-key{
            margin-top: 2rem;
            display: flex;
            @media screen and (max-width:$tablet - 1px){
                display: block;
            }
            h4{
                font-size: 1.2rem;
                color: $second-color;
                font-weight: 500;
                b{
                    text-transform: uppercase;
                }
            }
            p{
                margin:auto 0;
                font-size: 1rem;
                font-family: $second-font;
                color: $grey-color;
                font-weight: 500;
                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                    font-size: .9rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .8rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .86rem;
                }
                &.total-products{
                    margin:auto 0 auto auto;
                    text-align: right;
                    @media screen and (max-width:$tablet - 1px){
                        text-align: left;
                        margin-top: .4rem;
                    }
                    b{
                        margin-left:.3rem;
                    }
                    }
             }
        }
    }
}

.static-page{
    .container{
        .content{
            margin:2rem 0 1rem;
            background: $light-grey-back;
            padding: 2rem;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
               padding: 1rem;
               margin:2rem 0 1rem;
            }
            @media screen and  (max-width:$mobile - 1px){
                padding: 1rem;
                margin:2rem 0 1rem;
            }
            img{
                width: 100%;
                height: 400px;
                object-fit: cover;
                object-position: center;
                margin-bottom:1.5rem;
            }
            h4{
                font-size: 1rem;
                font-weight: 700;
                color: $second-color;
            }
            .page-under-construction{
                color: $second-color;
                margin-bottom: 0;
                text-align: center;
            }
           ul{
               margin:1rem 1rem 1.6rem;
            li{
                list-style: disc;
                margin-bottom: .6rem;
            }
           }
            p,li, a{
                font-size: .9rem;
                color: $second-color;
                font-weight: 500;
                margin-bottom: 1rem;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: .85rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .8rem;
                    margin-bottom: .8rem;
                }
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    font-size: .8rem;
                }
                @media screen and  (max-width:$mobile - 1px){
                    font-size: .75rem;
                    margin-bottom: .6rem;
                }
                &.margin-bottom-small{
                    margin-bottom: .3rem;
                }
            }   
            a{
                color: $cta-color-hover;
                font-weight: 700;
            }
        }
    }
    &.privacy-policy-page{
        .container{
            .content{
                h4{
                    font-size: .9rem;
                }
                p,a, li{
                    font-size: .8rem;
                }
                br{
                    content: "";
                    margin: 2.4rem;
                    display: block;
                    font-size: 24%;
                }
            }
        }
    }
    &.faq-page{
        .container{
            .content{
                padding: 0;
                background: white;
                .faq-box{
                    margin:1rem 0;
                    background: $light-grey-back;
                    padding: 2rem;
                    .question{
                        font-size: 1.1rem;
                        font-weight: 700;
                        margin-bottom: .6rem;
                        color: $second-color;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: 1rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .95rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            font-size: .9rem;
                        }
                    }
                    .answer{
                        font-size: .85rem;
                        margin-bottom: .6rem;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: .8rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .75rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            font-size: .7rem;
                        }
                    }
                }
            }
        }
    }
}

#page-numbers {
    list-style: none;
    display: flex;
  }
  
  #page-numbers > li {
    margin-right: 0.3em;
    color: blue;
    user-select: none;
    cursor: pointer;
  }
  

  .actions-page{
     .container{
         .columns{
             margin:2rem 0 0;
         }
         .week-offer-box{
            margin:1rem 0;
            p{
                font-family: $second-font;
                color: $second-color;
                font-weight: 500;
                margin-left: .75rem;
                font-size: 1.2rem;
                span{
                    font-size: 2rem;
                    font-weight: 700;
                    font-family: $main-font;
                }
            }
        }
     }
  }

.signin-page{
    .container{
        .content{
            margin:auto;
            width: 45%;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                width: 60%;
            }
            @media screen and  (max-width:$tablet - 1px){
                width: 100%;
                padding: 0 1rem;
            }
            form{
                fieldset{
                    label{
                        font-size: .85rem;
                        color: $grey-color;
                        font-weight: 500;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: .8rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .75rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            font-size: .7rem;
                        }
                        input{
                            margin-top: .5rem;
                        }
                    }
                }
                .button-area{
                    .forgot-password{
                        font-weight: 700;
                        color: $second-color;
                        font-size: .8rem;
                        text-decoration: underline;
                        margin:auto 0;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: .75rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .7rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            font-size: .7rem;
                        }
                    }
                    .button{
                        width: 60%;
                        padding: 1.6rem;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            padding: 1.4rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            padding: 1.2rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                           padding: 1.2rem;
                        }
                    }
                }
            }

            .divider{
                display:flex;
                position: relative;
                margin:2rem 0;
                p{
                    font-size: .9rem;
                    color: $grey-color;
                    margin:auto;
                }
                &:before, &:after{
                    content:"";
                    position: absolute;
                    width: 45%;
                    top:40%;
                    border-bottom:2px dashed $grey-color;
                    opacity: .4;
                }
                &:before{
                    left:0;
                }
                &:after{
                    right: 0;
                }
            }

            .social-login{
                display:block;
                width: 100%;
                a{
                    width: 100%;
                    border-radius: 30px;
                    display:flex;
                    color: white;
                    font-weight: 500;
                    font-size: .9rem;
                    text-align: center;
                    margin:.6rem 0;  
                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                        font-size: .85rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .8rem;
                    }
                    @media screen and  (max-width:$mobile - 1px){
                        font-size: .75rem;
                    }
    
                    .item{
                        margin:auto;
                    }
                    .icon{
                        background: rgba(0,0,0,0.1);
                        height: 50px;
                        border-radius: 90px 0 0 90px;
                        width: 50px;
                        display:flex;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            width: 45px;
                            height: 45px;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            width: 42px;
                            height: 42px;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            width: 38px;
                            height: 38px;
                        }
                        i{
                            width: 22px;
                            height: 22px;
                            display:block;
                            margin:auto;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                width: 20px;
                                height: 20px;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                width: 18px;
                                height: 18px;
                            }
                            @media screen and  (max-width:$mobile - 1px){
                                widows: 16px;
                                height: 16px;
                            }
                        } 
                    }
                    &.facebook{
                        background:#4267B2;
                        .icon{
                            i{
                            background:url(./images/facebook-white.svg) center no-repeat;
                            background-size:contain;
                            } 
                        }
                    }
                    &.google{
                        background:#2F85FF;
                        .icon{
                            i{
                            background:url(./images/google-white.svg) center no-repeat;
                            background-size:contain;
                            }
                        }
                    }
                }
            }

            p{
                color: $second-color;
                font-size: .9rem;
                font-weight: 500;
                margin:2rem auto 3rem;
                text-align: center;
                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                    font-size: .85rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .8rem;
                }
                @media screen and  (max-width:$mobile - 1px){
                    font-size: .75rem;
                }
                &.register-text{
                    margin-bottom: .4rem;
                }
            }
            a{
                color: $second-color;
                font-weight: 700;
            }
        }
        .register-button{
            margin:auto;
            border:1px solid #cccccc;
            font-size: .85rem;
            padding: .6rem 1.4rem;
            border-radius: 30px;
            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                font-size: .8rem;
                padding: .5rem 1.2rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .75rem;
                padding: .4rem 1rem;
            }
            @media screen and  (max-width:$mobile - 1px){
                font-size: .7rem;
                padding: .4rem 1rem;
            }
            &:hover{
                background: $light-grey-back;
            }
        }
    }
}

.page-404{
    .container{
        padding: 6rem 0;
        display: grid;
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            padding: 4rem 0;
        }
        @media screen and  (max-width:$mobile - 1px){
            padding: 3rem 0;
        }
        .big-title{
            font-size: 7rem;
            font-weight: 700;
            color: $main-color;
            text-align: center;
            margin-bottom: -1rem;
            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                font-size: 6rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: 5rem;
            }
            @media screen and  (max-width:$mobile - 1px){
               font-size: 4rem;
            }
        }
        .small-title{
            font-weight: 700;
            color: $second-color;
            font-size: 1.1rem;
            text-transform: uppercase;
            text-align: center;
            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                font-size: 1rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .9rem;
            }
            @media screen and  (max-width:$mobile - 1px){
               font-size: .8rem;
            }

        }
        .text{
            font-size: 1rem;
            color: $text-color;
            text-align: center;
            margin-top: 3rem;
            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                font-size: .9rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .8rem;
            }
            @media screen and  (max-width:$mobile - 1px){
               font-size: .8rem;
               margin-top: 2rem;
            }

        }
        .button-area{
            margin-top: 1rem;
            .button{
                margin:auto;
            }

        }
    }
}

.page-maintance{
    background: $main-color;
    height: 100vh;
    display: flex;
    .container{
        padding: 6rem 0;
        display: grid;
        margin: auto;
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            padding: 4rem 0;
        }
        @media screen and  (max-width:$mobile - 1px){
            padding: 3rem 0;
        }
        img{
            width: 250px;
            margin: auto auto 2rem;
        }
        .big-title{
            font-size: 3rem;
            font-weight: 700;
            color: white;
            text-align: center;
            margin-bottom:1rem;
            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                font-size: 6rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: 5rem;
            }
            @media screen and  (max-width:$mobile - 1px){
               font-size: 4rem;
            }
        }
        .small-title{
            font-weight: 500;
            color: $second-color;
            font-size: 1.1rem;
            text-align: center;
            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                font-size: 1rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .9rem;
            }
            @media screen and  (max-width:$mobile - 1px){
               font-size: .8rem;
            }

        }
    }
}

.signup-page{
    .container{
        .content{
            width: 80%;
            @media screen and  (max-width:$tablet - 1px){
                width: 100%
            }
            form{
                .break{
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: $light-grey-back;
                    margin: 1.2rem .8rem;
                }
              .button-area{
                  margin-top: 2rem;
                  padding: 0;
                  .button{
                      width: 100%;
                  }
              }
            }
        }
    }
}

.user-page{
    .container{
        .content{
            .columns{
                margin:0;
                .column{
                    &.user-menu{
                        margin-top: 2rem;
                        @media screen and  (max-width:$tablet - 1px){
                            margin-top: .5rem;
                        }
                        ul{
                            background: $light-grey-back;
                            border:1px solid rgba(0, 0, 0, 0.06);
                            margin-left: 0;
                            li{
                                margin-top: 0;
                                display:inline-block;
                                @media screen and  (max-width:$tablet - 1px){
                                    display:block;
                                }
                                a, .link{
                                    color: $second-color;
                                    font-size: 1rem;
                                    font-family: $second-font;
                                    font-weight: 700;
                                    padding:.6rem 2rem;
                                    border-right: 1px solid rgba(0, 0, 0, 0.06);
                                    display:block;
                                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                        font-size: .95rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .85rem;
                                        padding: .5rem 1.6rem;
                                    }
                                    @media screen and  (max-width:$mobile - 1px){
                                        font-size: .9rem;
                                    }
                                    @media screen and  (max-width:$tablet - 1px){
                                        border-right: 0;
                                        padding: .6rem;
                                        border-bottom: 1px solid rgba(0,0,0,0.06);
                                    }
                                    &:hover{
                                        background: $main-color;
                                    }
                                    &.current{
                                        background: $main-color;
                                    }
                                }
                                .link{
                                    border-bottom: 0;
                                    cursor: pointer;
                                }
                            }
                        }
                        
                    }
                    &.main{
                        .welcome-image{
                            width: 17rem;
                            height: 15rem;
                            display:block;
                            background: url(./images/welcome-user.svg) center no-repeat;
                            background-size: contain;
                            margin:3rem auto auto;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                               width: 15rem;
                               height: 12rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                width: 13rem;
                               height: 10rem;
                            }
                            @media screen and  (max-width:$mobile - 1px){
                                width: 10rem;
                               height: 8rem;
                               margin: 1rem auto auto;
                            }
                        }
                        .welcome-text{
                            border-radius: 19% 19% 0 0;
                            border-top:4px solid $main-color;
                            padding: 3rem 0 0 0;
                            background: white;
                            margin-top: -3rem;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                padding: 2.4rem 0 0 0;
                                margin-top: -2.4rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                padding: 2rem 0 0 0;
                                margin-top: -2rem;
                            }
                            @media screen and  (max-width:$mobile - 1px){
                                padding: 1.6rem 0 0 0;
                                margin-top: -1.6rem;
                            }
                            h4{
                                font-size: 1.3rem;
                                color: $second-color;
                                text-align: center;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: 1.2rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: 1.05rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .95rem;
                                }
                            }
                            p{
                                text-align: center;
                                color: $grey-color;
                                font-weight: 500;
                                font-size: .95rem;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: .9rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .75rem;
                                }
                            }
                        }
                        .only-mobile{
                            color: $second-color;
                            font-size: .85rem;
                            font-weight: 700;
                            text-align: center;
                            padding: 3rem 0;
                        }
                        .my-orders{
                            margin-top: 2rem;
                            @media screen and  (max-width:$tablet - 1px){
                                display:none;
                            }
                            thead{
                                background: $light-grey-back;
                                tr{
                                    border: 1px solid rgba(0,0,0,.06);
                                    th{
                                        border-right: 1px solid rgba(0,0,0,.06);
                                        font-weight: 700;
                                        font-size: .85rem;
                                        border-bottom:2px solid rgba(0,0,0,.06);
                                        padding:1rem .6rem;
                                        color: $second-color;
                                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                            font-size: .8rem;
                                            padding: .8rem .4rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: .75rem;
                                            padding: .6rem .3rem;
                                        }
                                        @media screen and  (max-width:$mobile - 1px){
                                            font-size: .7rem;
                                            padding: .4rem .3rem;
                                        }
                                        &:last-child{
                                            border-right: 0;
                                        }
                                    }
                                }
                            }
                            tbody{
                                tr{
                                    border: 1px solid rgba(0,0,0,.06);
                                    border-top:0;
                                    td{
                                        font-size: .85rem;
                                        font-weight: 500;
                                        color: $second-color;
                                        border-bottom: 0;
                                        border-right: 1px solid rgba(0,0,0,.06);
                                        padding: .8rem .6rem;
                                        vertical-align: middle;
                                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                            font-size: .8rem;
                                            padding: .8rem .4rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: .75rem;
                                            padding: .6rem .3rem;
                                        }
                                        @media screen and  (max-width:$mobile - 1px){
                                            font-size: .7rem;
                                            padding: .4rem .3rem;
                                        }
                                        span{
                                            margin:auto 0;
                                        }
                                        &:last-child{
                                            border-right: 0;
                                        }
                                        .button{
                                            background: $cta-color;
                                            color: $second-color;
                                            border-radius: 30px;
                                            font-weight: 700;
                                            font-size: .75rem;
                                            padding: .6rem 1rem;
                                            margin:auto;
                                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                                font-size: .7rem;
                                                padding: .5rem .7rem;
                                            }
                                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                                font-size: .6rem;
                                                padding: .3rem .6rem;
                                            }
                                            @media screen and  (max-width:$mobile - 1px){
                                                font-size: .6rem;
                                                padding: .3rem .4rem;
                                            }
                                            &:focus{
                                                outline: none;
                                                box-shadow: none;
                                            }
                                            &:hover{
                                                background: $cta-color-hover;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .current-data{
                            border:2px solid $cta-color;
                            padding:1rem;
                            @media screen and  (max-width:$mobile - 1px){
                                padding: .6rem;
                            }
                            .single-line{
                                font-size: .95rem;
                                font-weight: 500;
                                color: $second-color;
                                padding:.4rem 0;
                                border-bottom: 1px solid rgba(0,0,0,.03);
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: .9rem;
                                    padding: .3rem 0;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                    padding: .2rem 0;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .75rem;
                                    padding: .2rem 0;
                                }
                            }
                            .button-area{
                                margin:1.4rem 0 .6rem;
                                .button{
                                    padding: 1.2rem 1.6rem;
                                    font-size: .8rem;
                                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                        font-size: .75rem;
                                        padding: 1rem 1.4rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .7rem;
                                        padding:1rem 1.2rem;
                                    }
                                    @media screen and  (max-width:$mobile - 1px){
                                        font-size: .65rem;
                                        padding: .8rem 1rem;
                                    }
                                }
                            }
                        }
                        .user-data-form{
                            margin-top: 1rem;
                            fieldset{
                                .columns{
                                    label{
                                        font-size: .85rem;
                                        font-weight: 500;
                                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                            font-size: .8rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: .75rem;
                                        }
                                        @media screen and  (max-width:$mobile - 1px){
                                            font-size: .7rem;
                                        }
                                        input{
                                            margin-top: .4rem;
                                        }
                                    }
                                }
                                .button-area{
                                    .button{
                                        padding: 1.8rem 4rem;
                                        width: max-content;
                                        margin:1rem auto;
                                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                            font-size: .8rem;
                                            padding: 1.6rem 3rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: .75rem;
                                            padding:1.2rem 2rem;
                                        }
                                        @media screen and  (max-width:$mobile - 1px){
                                            font-size: .7rem;
                                            padding: 1.2rem 2rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.my-points-page{
        .container{
            .content{
                .main{
                    .subtitle{
                        font-size: 1.2rem;
                        font-family: $second-font;
                        color: $second-color;
                        margin:1rem 0;
                        text-align: center;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: 1.1rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: 1rem;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            font-size: 1rem;
                            margin-bottom: .3rem;
                        }
                    }
                    .total-points{
                        margin-top: 2rem;
                        @media screen and  (max-width:$mobile - 1px){
                            margin-bottom: 1.4rem;
                            display:block;
                        }
                        h1{
                            text-align: center;
                            font-size: 6rem;
                            margin:.4rem 0;
                            color: $grey-color;
                            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                font-size: 5rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: 4rem;
                            }
                            @media screen and  (max-width:$mobile - 1px){
                                font-size: 5rem;
                                margin-top: 0;
                            }
                        }
                    }
                    .create-coupon{
                        margin: 2rem auto;
                        padding-top: 1rem;
                        width: 65%;
                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                            font-size: .8rem;
                            margin: 1rem auto;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            font-size: .75rem;
                            margin: 0 auto;
                        }
                        @media screen and  (max-width:$mobile - 1px){
                            width: 100%;
                            padding-top:0;
                            margin: 0 auto;
                        }
                        form{
                            @media screen and  (max-width:$mobile - 1px){
                                margin-top: 1rem;
                            }
                            fieldset{
                                label{
                                    font-weight: 500;
                                    font-size: .85rem;
                                    color: $second-color;
                                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                        font-size: .8rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .75rem;
                                    }
                                    @media screen and  (max-width:$mobile - 1px){
                                        font-size: .7rem;
                                    }
                                    input{
                                        font-size: 1.6rem;
                                        height: 3.6rem;
                                        font-weight: 700;
                                        margin-top: .3rem;
                                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                            font-size: 1.4rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            font-size: 1.3rem;
                                        }
                                        @media screen and  (max-width:$mobile - 1px){
                                            font-size: 1.1rem;
                                        }
                                    }
                                }
                                .button-area{
                                    width: 50%;
                                    @media screen and  (max-width:$mobile - 1px){
                                        width: 100%;
                                    }
                                    .button{
                                        height: 3.6rem;
                                        margin:1.3rem auto;
                                        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                            height: 3.2rem;
                                        }
                                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                            height: 3rem;
                                        }
                                        @media screen and  (max-width:$mobile - 1px){
                                            height: 2.8rem;
                                            margin:auto;
                                            padding: 0 2rem;
                                        }
                                        &:focus{
                                            outline: none;
                                            box-shadow: none;
                                        }
                                    }
                                }
                                .coupon-money{
                                    font-size: .9rem;
                                    color: $second-color;
                                    font-weight: 500;
                                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                        font-size: .85rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .8rem;
                                    }
                                    @media screen and  (max-width:$mobile - 1px){
                                        font-size: .75rem;
                                        text-align: center;
                                    }
                                }
                            }
                            .error-coupon{
                                color: white;
                                background:$red-color;
                                border-radius: 4px;
                                padding: .6rem;
                                font-size: .9rem;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: .85rem;
                                    padding: .4rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .8rem;
                                    padding: .3rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .7rem;
                                    padding: .3rem;
                                }
                            }
                        }
                        .coupon-name{
                            margin-top: 2rem;
                            padding: 1rem;
                            border:2px solid $cta-color;
                            background: rgba(0,0,0,0.08);
                            @media screen and  (max-width:$mobile - 1px){
                                margin-top: 1.3rem;
                            }
                            p{
                                font-size: 1rem;
                                color: $second-color;
                                text-align: center;
                                border-radius: 4px;
                                font-weight: 500;
                                padding: .6rem;
                                margin-bottom: 0;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: .95rem;
                                    padding: .4rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .85rem;
                                    padding: .3rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: .75rem;
                                    padding: .3rem;
                                }
                            }
                            .name{
                                font-size: 3rem;
                                font-weight: 500;
                                color: $second-color;
                                display:table;
                                padding: .6rem 1rem;
                                margin:.4rem auto;
                                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                    font-size: 2.4rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: 2rem;
                                }
                                @media screen and  (max-width:$mobile - 1px){
                                    font-size: 1.8rem;
                                }
                            }
                            .button-area{
                                .button{
                                    margin:auto;
                                    font-size: .8rem;
                                    padding:1.2rem 1.6rem;
                                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                                        font-size: .75rem;
                                        padding: 1rem 1.4rem;
                                    }
                                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                        font-size: .7rem;
                                        padding: 1rem 1.2rem;
                                    }
                                    @media screen and  (max-width:$mobile - 1px){
                                        font-size: .65rem;
                                        padding: .8rem 1rem;
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
}

  #zoid-paypal-button-5fdd3d143c, #zoid-paypal-button-5fdd3d143c > .zoid-outlet{
      width: 100% !important;
  }

.slick{
    .slick-slider{
        .slick-slide{
            text-align: center;
            h3{
                font-size: 2rem;
                background: $main-color;
            }
        }
    }
}


.brends-page{
    .container{
        .columns{
            margin-top: 1rem;
            @media screen and (max-width:$tablet - 1px){
                display: flex;
                flex-wrap: wrap;
            }
            .column{
                width: 10%;
                min-width: 10%;
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    padding:.7rem .4rem;
                }
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    width: 20%;
                    min-width: 20%;
                }
                @media screen and (max-width:$mobile - 1px){
                    width: 25%;
                    min-width: 25%;
                }
            }
        }

        .alphabet{
            display: flex;
            width: 100%;
            margin: 4rem 0;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                margin: 3rem 0;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                margin: 3rem 0;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                margin: 3rem 0;
            }
            @media screen and (max-width:$mobile - 1px){
                margin: 2rem 0;
            }
            .inner{
                margin: auto 0;
                @media screen and (max-width:$tablet - 1px){
                    display: flex;
                    flex-wrap: wrap;
                }
                span{
                    font-size: 1.2rem;
                    font-weight: 700;
                    margin: 0 .4rem;
                    display: inline-block;
                    color: $second-color;
                    border:1px solid $light-grey-back;
                    width: 2.4rem;
                    height: 2.8rem;
                    text-align: center;
                    line-height: 2.8rem;
                    cursor: pointer;
                    @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                        margin: 0 .3rem;
                        font-size: 1.1rem;
                        width: 2.2rem;
                        height: 2.4rem;
                        line-height: 2.4rem;
                    }
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        margin: 0 .2rem;
                        font-size: .95rem;
                        width: 2rem;
                        height: 2.2rem;
                        line-height: 2.2rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        margin:.2rem;
                        font-size: .9rem;
                        width: 1.8rem;
                        height: 2rem;
                        line-height: 2rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        margin: .4rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: 1rem;
                        margin:.3rem;
                        width: 1.8rem;
                        height: 2rem;
                        line-height: 2rem;
                    }
                    &.current, &:hover{
                        border: 1px solid $main-color;
                        background: $main-color;
                        color: white;
                    }
                }
            }
        }
        .selected-letter{
            .big-text{
                font-size: 2.8rem;
                font-weight: 700;
                background: $main-color;
                padding: 0rem;
                width: 5rem;
                height: 5rem;
                line-height: 5rem;
                text-align: center;
                display: block;
                color: white;
                position: relative;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: 2.4rem;
                    width: 4rem;
                    line-height: 4rem;
                    height: 4rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: 2rem;
                    width: 3rem;
                    height: 3rem;
                    line-height: 3rem;
                }
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    font-size: 2.4rem;
                    width: 4.4rem;
                    height: 4.4rem;
                    line-height: 4.4rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: 1.8rem;
                    width: 3.4rem;
                    height: 3.4rem;
                    line-height: 3.4rem;
                }
                i{
                    width: .8rem;
                    height: .8rem;
                    top: .3rem;
                    right: .3rem;
                    position: absolute;
                    background: url(./images/close-white.svg) center no-repeat;
                    background-size: contain;
                    display: block;
                    cursor: pointer;
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        width: .6rem;
                        height: .6rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        width: .6rem;
                        height: .6rem;
                    }
                }
            }
        }
    }
}

.landing-page{
    padding-bottom: 0;
    .container{
        .content{
        .image{
            @media screen and (max-width:$tablet - 1px){
                display: none;
            }  
        }
            .text-content{
            }
            .products-slider{
            }
        }
    }
}

.faq-page{
    .container{
        .content{
            .faq-box{
                img{
                    height: unset;
                    width: auto;
                }
            }
        }
    }
}