.site-popup-wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    display:flex;
    background: rgba(255,255,255,.85);
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
    &.show{
        opacity: 1;
        visibility: visible;
    }
    .popup-content{
        background: $main-color-hover;
        position: relative;
        border-radius: 7px;
        margin:auto;
        width: 900px;
        height: 610px;
        overflow: hidden;
        box-shadow: 0px 8px 24px -10px rgba(0, 0, 0, 0.55);
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
           width: 750px;
           height: 508px;
        }
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            width: 650px;
            height: 441px;
        }
        @media screen and (max-width:$tablet - 1px){
            width: 100%;
            padding: 0 2rem;
            height: 100%;
        }
        .logo{
            margin:2rem auto 0;
            display:table;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                margin: 1rem auto 0;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                margin: .7rem auto 0;
            }
            img{
                width: 250px;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    width: 200px;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    width: 170px;
                }
                @media screen and (max-width:$mobile - 1px){
                    width: 180px;
                }
            }
        }

        .close-icon{
            position: absolute;
            right: 1.4rem;
            top:1.4rem;
            width: 2.4rem;
            height: 2.4rem;
            background: url(./images/close-white.svg) center no-repeat;
            background-size: contain;
            display:block;
            cursor: pointer;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                width: 1.6rem;
                height: 1.6rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                width: 1.2rem;
                height: 1.2rem;
            }
            @media screen and (max-width:$mobile - 1px){
                width: 1.6rem;
                height: 1.6rem;
            }
        }
        .main-title{
            width: 100%;
            margin:1.4rem auto 0;
            text-align: center;
            font-size: 1.8rem;
            line-height: 2.2rem;
            color: white;
            text-shadow: 0px 1px 2px #919191;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: 1.5rem;
                line-height: 1.9rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: 1.3rem;
                line-height: 1.6rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                width: 90%;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: 1.3rem;
                line-height: 1.8rem;
            }
            span{
                font-weight: 700;
                display:block;
            }
        }
        .hide-popup{
            display: none;
            font-family: $second-font;
            color: $second-color;
            font-size: 1.1rem;
            font-weight: 700;
            text-align: center;
            margin: .8rem auto 2rem;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .9rem;
                margin: auto auto 1.4rem;
                @media screen and (max-width:$mobile - 1px){
                    font-size: .9rem;
                }
            }
            &.is-mobile{
                @media screen and (max-width:$tablet - 1px){
                    display: block;
                }
            }
        }
        .content{
            width: 100%;
            margin:0rem auto 0;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                margin: 1rem auto 0;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                margin: 1rem auto 0;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                width: 90%;
            }
            @media screen and (max-width:$mobile - 1px){
                margin-top: 4rem;
            }
            p{
                font-size: 1.2rem;
                color: white;
                text-align: center;
                text-shadow: 0px 1px 2px #919191;
                margin-bottom: 0;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: 1rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .9rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: 1rem;
                }
                span{
                    font-weight: 700;
                }
            }
        }
        .bottom{
            background: url(../../static/popup-waves.png) top no-repeat;
            background-size: auto;
            height: 180px;
            bottom: 0;
            width: 100%;
            left: 0;
            position: absolute;
            display: flex;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                height: 160px;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                height: 140px;
            }
            @media screen and (max-width:$mobile - 1px){
                height: 150px;
            }
            .inner{
                display: flex;
                width: 60%;
                margin: auto;
                height: 100%;
                @media screen and (max-width:$tablet - 1px){
                    display: block;
                    height: 0;
                }
            }
            p, a{
                font-family: $second-font;
                color: $second-color;
                font-size: 1rem;
                font-weight: 400;
                margin: auto auto 2rem;
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .9rem;
                    margin: auto auto 1.4rem;
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .8rem;
                    }
                }
                span{
                    font-weight: 700;
                    color: $second-color;
                    cursor: pointer;
                }
                &.is-desktop{
                    @media screen and (max-width:$tablet - 1px){
                        display: none;
                    }
                }
            }
        }
    }
}