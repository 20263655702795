.admin{
    .default-form{
    .container{
        position: unset;
        form{
            display:flex;
            &.border-form{
            }
            &.add-filter-form{
                margin-bottom: 3rem;
                .button{
                    margin-top: 0 !important;
                }
            }
            fieldset{
                border:none;
                width: 100%;
                .subtitle{
                    color: #666666;
                    font-size: .9rem;
                    padding:1rem 0 0;
                    text-transform: uppercase;
                    width: 50%;

                }
                .main{
                    width: 75%;
                } 
                .top{
                    margin-top:2rem;
                    border-top: 1px solid #e9e9e9;
                    width: 75%;
                    padding: 1rem 0;
                    .break-line{
                        width: 100%;
                        height: 1px;
                        background: #e1e1e1;
                        margin:1rem;
                    }
                } 

                
                
                .variation-box{
                    margin-top: 1rem;
                    .button{
                        margin:auto 0;
                        position: relative;
                        top:.2rem;
                        padding: .5rem 1rem;
                    }
                    table{
                        border:1px solid rgba(0, 0, 0, 0.09);
                        width: 75%;
                        thead{
                            border-bottom: 1px solid rgba(0, 0, 0, 0.09);
                            background: white;
                            tr{
                                th{
                                    border-bottom: 0;
                                    border-right: 1px solid rgba(0, 0, 0, 0.09);
                                    font-size: .85rem;
                                    color: $second-color;
                                }
                            }
                        }
                       tbody{
                           tr{
                               td{
                                border-right: 1px solid rgba(0, 0, 0, 0.09);
                                font-size: .85rem;
                                color: $second-color;
                                padding:.5rem .75rem;
                                margin: auto;
                                span{
                                    font-size: 1.1rem;
                                    text-transform: uppercase;
                                    color: $red-color;
                                    cursor: pointer;
                                    display:inherit;
                                }
                               }
                           }
                       }
                    }
                }
                .divider-vertical{
                    position: fixed;
                    right: 20%;
                    height: 100%;
                    top:70px;
                    background: #eeeeee;
                    width: 2px;
                }

                .sidebar{
                    position: absolute;
                    right:0;
                    background:transparent;
                    left:unset;
                    width: 20%;
                    padding: 1rem;
                    height: unset;
                    .button-area{
                        padding:2rem 1rem;
                        .button{
                            margin-top: 0;
                            &.add{
                                padding: 1.4rem;
                                span{
                                    margin-left: 0;
                                }
                                svg{
                                    font-size: 1.3rem;
                                    margin-left: .3rem;
                                    position: relative;
                                    top:.2rem;
                                }
                            }
                        }
                    }
                    
                    .header{
                        padding: 0 1rem;
                        margin-bottom:2rem;
                        border-bottom: 0;
                        margin-top: 1rem;
                        h4{
                            font-size: 1rem;
                            color: $second-color;
                            margin:auto 0;
                            font-weight: 700;
                        }
                        .icon{
                            background: url(./images/close.svg) center no-repeat;
                            background-size: contain;
                            width: 25px;
                            height: 25px;
                            display:block;
                            cursor: pointer;
                            margin:-1.4rem 0 auto auto;
                        }
                    }
                    label{
                        input, select,textarea{
                            background: $background-main;
                        }

                    }
                    .button{
                        width: 100%;   
                        margin-top:3rem; 
                    }

                    .label{
                        color: $second-color;
                        font-weight: 700;
                        font-size:.95rem;
                        font-weight: 500;
                        margin:3rem 0 .5rem 0;
                    }
                    .meta-button{
                        display:flex;
                        border:1px solid rgba(0,0,0,0.15);
                        background: white;
                        margin: 1rem;
                        padding: 1rem;
                        border-radius: 4px;
                        cursor: pointer;
                        .inner{
                            h4{
                                color: $second-color;
                                font-size: .9rem;
                                color: $second-color;
                                font-weight: 700;
                                margin-bottom:.1rem;
                            }
                            p{
                                font-size: .75rem;
                                color: $grey-color;
                            }
                        }
                        .icon{
                            width: 20px;
                            height: 20px;
                            background: url(./images//arrow-right-grey.svg) center no-repeat;
                            background-size: contain;
                            display:block;
                            margin:auto 0 auto auto;
                        }

                        &:hover{
                            box-shadow: -3px 13px 24px -10px rgba(0,0,0,.23);
                        }
                    }

                    &.seo-sidebar{
                        opacity: 0;
                        background: white;
                        visibility: hidden;
                        transform: translateX(100%);
                        &.show{
                            opacity: 1;
                            visibility: visible;
                            transform: translateX(0)
                        }
                    }
                }  
            }
            .ck-editor{
                margin-top:.4rem;
                label{
                    display:none;
                }
            }
            .short-description{
                .ck-content{
                    height: 120px;
                }
            }
            .description{
                .ck-content{
                    height: 350px;
                }
            }
            label{
                color: $second-color;
                font-size:.8rem;
                border:none;
                font-weight: 500;
                display:block;
                margin: 0;
                padding: .8rem .75rem;
                &.is-4{
                    width: 33.3333333%;
                }
                input, textarea, select{
                    width: 100%;
                    display:block;
                    margin-top:.6rem;
                    border-radius:4px;
                    background: white;
                    border:1px solid rgba(0,0,0,0.15);
                    padding: .7rem 1rem;
                    color: $second-color;
                    font-size: .85rem;
                    

                    &:focus{
                        outline:none;
                        box-shadow:  -3px 13px 24px -10px rgba(0,0,0,.13);
                    }
                }
                &.title,  &.price{
                    margin:1rem 0 0;
                    input{
                        padding:1rem 1.2rem;
                        font-size: 1rem;
                        border:1px solid rgba(0,0,0,0.45);
                    }
                }
                &.slug{
                    display:flex;
                    padding-top: 0;
                    margin-bottom: 1rem;
                    font-size: .7rem;
                    span{
                        margin:auto .4rem auto 0;
                    }
                    input{
                        width: 50%;
                        padding: .5rem 1rem;
                        font-size: .8rem;
                        margin-top: 0;
                    }
                }
                &.price{
                    &.not-editable{
                        input{
                            border:none;
                            background: transparent;
                            padding-left: 0;
                        }
                    }
                    input{
                        font-weight: 700;
                    }
                }
                ::placeholder{
                    color: #878787;
                }
                .real-price{
                    margin-top:.5rem;
                    display:inline-block;
                    border-radius: 7px;
                    background: #ad5389; /* fallback for old browsers */
                    background: -webkit-linear-gradient(to right, #ad5389, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
                    background: linear-gradient(to right, #ad5389, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                    color: white;
                    padding:.2rem .8rem;
                    font-size: .8rem;
                }

                textarea{
                    height: 150px;

                    &.description{
                        height: 500px;
                    }
                }
                &.filters-categories{
                   .wrapper{
                    display:block;
                    padding: 1rem;
                    margin-top:.6rem;
                    border:1px solid #c4c4c4;
                    max-height: 300px;
                    overflow: auto;
                    .inner-box{
                        margin-bottom: 1rem;
                        .single-line{
                            display:flex;
                            margin:.2rem 0;
                            input{
                                width:.9rem;
                                margin: auto .6rem auto 0;
                                height: .9rem;
                            }
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                   }
                    .tag-category-name{
                        font-size: .8rem;
                        margin-bottom: .4rem;
                        margin-top: 0rem;
                    }
                    .cat-filters-list{
                        display:flex;
                        width: 100%;
                        margin:.6rem 0;
                        .single-line{
                            display:flex;
                            width: 48%;
                            font-size: .8rem;
                            background: white;
                            margin:1%;
                            border:1px solid rgba(0, 0, 0, 0.09);
                            input{
                                width: unset;
                                margin-right: 0rem; 
                                margin: .2rem .4rem 0 0;
                            }
                        }
                    }  
                }

                &.product-categories{
                    .wrapper{
                        .single-line{
                            font-weight: 700;
                            &.subcategory{
                                margin-left: 1rem;
                                font-size: .75rem;
                                font-weight: 400;
                            }
                         
                        }
                    }
                }
            }
            #upload_button {
                .label-image{
                    margin:0;
                    &.labeled{
                        color: $second-color;
                        cursor: pointer;
                        font-size: .8rem;
                        display:block;
                        text-transform: uppercase;
                        margin-top:1rem;
                        margin-bottom:2rem;
                        padding: .9rem 1.6rem;
                        border-radius: 7px;
                        font-weight: 600;
                        width: 100%;
                        background: $background-main;
                        &:hover{
                            box-shadow:  -3px 13px 24px -10px rgba(0,0,0,.13);
                        }
                    }
                }
                .add-image{
                    color: $second-color;
                    cursor: pointer;
                    font-size: .8rem;
                    display:block;
                    margin-top:1rem;
                    padding: .9rem;
                    border-radius: 4px;
                    font-weight: 700;
                    width: 100%;
                    border:1px solid rgba(0,0,0,0.15);
                    background: white;
                    &:hover{
                        box-shadow:  -3px 13px 24px -10px rgba(0,0,0,.13);
                    }
                    svg{
                        margin-left:.2rem;
                        font-size: 1.1rem;
                        position: relative;
                        top:.1rem;
                    }
                }

                .image-preview{
                    margin-left:0rem;
                    padding: 0 1rem;
                    height: 250px;
                    position: relative;
                    img{
                        width: 100%;
                        margin:auto 0;
                        height: 100%;
                        object-fit: contain;
                        background: $background-main;
                    }

                    .remove-button{
                        background:#e9e9e9;
                        width: 35px;
                        height: 35px;
                        border-radius: 90px;
                        position: absolute;
                        top: 1%;
                        right: 5%;
                        cursor: pointer;
                        display:flex;
                        border: none;
                        .icon{
                            background: url(./images/close.svg) center no-repeat;
                            background-size: 12px;
                            display:block;
                            margin:auto;
                        }
                    }
                }

                
            }
            #upload_button input[type=file], #upload_slider input[type=file] {
                display:none;
            }

            #upload_slider{
                margin-top: 2rem;
                margin-bottom: 2rem;
            .single-row{
                width: 100%;
                label{
                    margin-top:0;
                    padding-top: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    &.labeled{
                        color: $second-color;
                        cursor: pointer;
                        font-size: .8rem;
                        display:block;
                        margin-top:1rem;
                        border-radius: 30px;
                        font-weight: 600;
                        width: max-content;
                    }
                }
                .add-image{
                    color: $second-color;
                    cursor: pointer;
                    font-size: .8rem;
                    display:block;
                    margin-top:-1rem;
                    padding: .7rem;
                    border-radius: 4px;
                    font-weight: 700;
                    border:1px solid rgba(0,0,0,0.15);
                    background: white;
                    &:hover{
                        box-shadow:  -3px 13px 24px -10px rgba(0,0,0,.13);
                    }
                    svg{
                        margin-left:.2rem;
                        font-size: .9rem;
                        position: relative;
                        top:.1rem;
                    }

                }
                .image-preview{
                    margin-top:.5rem;
                    margin-bottom:0;
                    position: relative;
                    display:inline-block;
                    img{
                        width: auto;
                        height: 150px;
                        object-fit: contain;
                        background: $background-main;
                            }

                    .remove-button{
                        background:white;
                        width: 30px;
                        height: 30px;
                        border-radius: 90px;
                        position: absolute;
                        top: 2%;
                        right: 2%;
                        cursor: pointer;
                        border: none;
                        .icon{
                            background: url(./images/close.svg) center no-repeat;
                            background-size: 10px;
                            display:block;
                            margin:auto;
                            width: unset;
                        }
                    }
                        }
                    }    
                }
            }
        }
        &.add-category, &.add-slider{
            .container{
                form{
                    fieldset{
                        #upload_button{
                            .add-image{
                                background: white;
                            }  
                        }
                    }
                
                }
            }
        }
      
    }


    .add-category.default-form .container form .button,
     .add-slider.default-form .container form .button,
     .add-brand.default-form .container form .button,
     .settings.default-form .container form .button{
        margin-top:2rem;
        margin-left: 1rem;
    }
}

