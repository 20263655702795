/*
Main CSS file, imported in homepage.js
1. Print button
2. Scrollbar
*/

//Import other style components
@import "../variables.scss";
@import "~bulma/bulma.sass";
@import "../helpers.scss";
@import "_global.scss";
@import "_header.scss";
@import "_footer.scss";
@import "_pages.scss";
@import "_popup.scss";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
body{
    background: white;
}

//1. Print button displayed in order
.print-button{
    background: $second-color;
    color: white;
    border-radius: 7px;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 700;
    padding: .4rem 1rem;
    position: absolute;
    top:1rem;
    right: 1rem;
    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
        font-size: .7rem;
        padding:.3rem .8rem;
    }
    @media screen and (max-width:$tablet - 1px){
        display:none;
    }
    &:hover{
        color: white;
        box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.23);
    }
}

//2. Default scrollbar style for whole page
::-webkit-scrollbar {
    width: 8px;
} 
::-webkit-scrollbar-track {
   background: $light-grey-back;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: rgba(0,0,0,0.15);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
}

.img-magnifier-container {
    position:relative;
  }
  
  .img-magnifier-glass {
    position: absolute;
    border: 3px solid #000;
    border-radius: 50%;
    cursor: none;
    /*Set the size of the magnifier glass:*/
    width: 100px;
    height: 100px;
  }