.admin{
    .login{
    background: $background-main;
    .container{
        display: flex;
        height: 100%;
        .inner{
            margin: auto;
            background:white;
            padding: 3rem;
            box-shadow: 0px 1px 24px 1px rgba(0, 0, 0, 0.10);    
            width: 450px;
            .logo{
                display:flex;
                a{
                    margin:auto;
                    img{
                        width: 190px;
                    }
                }
            }
            .login-form{
                margin:2rem 0;
                fieldset{
                    border:none;
                    label{
                        width: 100%;
                        position: relative;
                        display:flex;
                        input{
                            background: $background-main;
                            padding: 1rem;
                            border-radius: 7px;
                            color: $second-color;
                            width: 100%;
                            font-size: 1rem;
                            border:none;
                            margin:.6rem 0;  
                            padding-left:3rem; 
                            &:focus{
                                outline:none;
                                box-shadow:  -3px 13px 24px -10px rgba(0,0,0,.23);
                            }  
                        }
                        &:before{
                            content:"";
                            position: absolute;
                            left:0;
                            top:0;
                            margin:auto 1rem;
                            height: 100%;
                            width: 20px;
                            display:block;
                        }
                        &.email{
                            &:before{
                                background: url(./images/email.svg) center no-repeat;
                                background-size:contain;
                            }
                        }
                        &.password{
                            &:before{
                                background: url(./images/password.svg) center no-repeat;
                                background-size:contain;
                            }
                        }
                    }
    
                    ::placeholder{
                        color: $grey-color;
                    }
    
                    .button-area{
                        margin:2rem 0;
                        display:flex;
                        .forgot-password{
                            font-size: .9rem;
                            color: $grey-color;
                            margin:auto 0;
                        }
    
                        .button{
                            padding:.6rem 3rem;
                            font-size: 1rem;
                            .item{
                                margin:0;
                            }
                        }
                    }
                }
            }
    
            .divider{
                display:flex;
                position: relative;
                p{
                    font-size: .9rem;
                    color: $grey-color;
                    margin:auto;
                }
                &:before, &:after{
                    content:"";
                    position: absolute;
                    width: 45%;
                    top:40%;
                    border-bottom:2px dashed $grey-color;
                    opacity: .4;
                }
                &:before{
                    left:0;
                }
                &:after{
                    right: 0;
                }
            }
    
            .social-login{
                display:block;
                margin:2rem 0 0;
                width: 100%;
                a{
                    width: 100%;
                    border-radius: 7px;
                    display:flex;
                    color: white;
                    font-size: 1rem;
                    text-align: center;
                    margin:.6rem 0;  
    
                    .item{
                        margin:auto;
                    }
                    .icon{
                        background: rgba(0,0,0,0.1);
                        height: 50px;
                        width: 50px;
                        display:flex;
                        i{
                            width: 25px;
                            height: 25px;
                            display:block;
                            margin:auto;
                        } 
                    }
                    &.facebook{
                        background:#4267B2;
                        .icon{
                            i{
                            background:url(./images/facebook-white.svg) center no-repeat;
                            background-size:contain;
                            } 
                        }
                    }
                    &.google{
                        background:#2F85FF;
                        .icon{
                            i{
                            background:url(./images/google-white.svg) center no-repeat;
                            background-size:contain;
                            }
                        }
                    }
                }
            }
        }
    }
}
}