.admin{
    .sidebar{
        position: fixed;
        top:70px;
        left:0;
        width: 320px;
        background: white;
        min-height: 100vh;
        border-right: 2px solid #f0f0f0;
        z-index: 1;
       

    .menu{
        padding:0rem;
        .navbar{
            ul{
                width: 100%;
                li{
                    width: 100%;
                    display:block;
                    margin:.4rem 0;
                    position: relative;
                    a, .link{           
                        color: $second-color;
                        font-size: .9rem;
                        text-align: center;
                        display:flex;
                        font-weight: 500;
                        position: relative;
                        padding:.8rem 2rem;  
                        cursor: pointer;
                        .desc{
                            font-size: .7rem;
                        }
                        svg{
                            margin:auto 1rem auto 0;
                            font-size: 1rem;
                        }
                        .is-right{
                            svg{
                                font-size: .7rem;
                            }
                        }
                    &:hover, &.active{
                        color: $text-black;
                        text-shadow: 0px 0px 1px #000;
                        background: #eeeeee;
                        }
                    }
                    

                    &.add-new{
                        background: $main-color;
                        a{
                            color: white;
                           
                        }
                    }
                    ul{
                        margin-bottom: 1rem;
                        position: relative;
                        left:0;
                        top:0;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(-100%);
                        &.show{
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(0);
                        }
                        li{
                            margin: 0;
                            a{
                                padding: .6rem 4.2rem;
                                font-size: .85rem;
                                text-align: left;
                                &:hover{
                                    text-shadow: 0px 0px 1px #000;
                                    background: white;
                                }
                            }
                            
                        }
                        
                    }
                }
            }

            .subtitle{
                text-transform: uppercase;
                margin-top: 2rem;
                color: $grey-color;
                font-size: .8rem;
                display:block;
                margin-bottom: 0;
                padding:0 2rem;
            }
        }
    }

    .footer{
        position: relative;
        bottom:0;
        border-top: 1px solid $background-main;
        width: 100%;
        padding: 0;

        a{
            color: $grey-color;
            font-size: .9rem;
            font-weight: 500;
            padding:1.2rem 0;
            width: fit-content;
            margin:auto;
            text-align: center;
            display:flex;
            transform: translateX(-1rem);
            .icon{
                background: url(./images/external-link.svg) center no-repeat;
                background-size: contain;
                width: 15px;
                height: 15px;
                display:block;
                margin:auto 0 auto 1rem;
            }
            &:hover{
                color: $second-color;
            }
            
        }
    }
}
}