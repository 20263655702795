/*
Styles bellow is defined for parts which is repeated for one or more times
  1. CTA button
  2. Title box
  3. Product slider box
  4. Input fields form
  5. Search bar
  6. Popup for adding products
  7. Popup for deleting products
  8. Page pagination
  9. Swiper pagination - not used
  10. Load box
  11. Social box
  12. Thank you content
  13. Popup animations
*/

.shop{
//1. Global CTA button - default(cta color), red
.button-area{
    display:flex;
    .button{
        border-radius: 30px;
        padding: 1.5rem 2rem;
        line-height: 0;
        text-transform: uppercase;
        font-size: .85rem;
        font-weight: 600;
        height: 0;
        font-family: $main-font;
        &:focus{
            outline: none;
            box-shadow: none;
        }
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            font-size: .75rem;
            padding: 1.2rem 1.8rem;
        }
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            font-size: .8rem;
            padding: 1.3rem 1.8rem;
        }
        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
            font-size: .8rem;
            padding: 1.3rem 1.8rem;
        }
        @media screen and (max-width:$mobile - 1px){
            font-size: .75rem;
            padding: 1.2rem 1.8rem;
        }
//CTA background
        &.default{
            background: $cta-color;
            color: $second-color;
            &:hover{
                background: $cta-color-hover;
                box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.09);
            }
        }
//Red background
        &.red{
            background: $red-color;
            color: white;
            &:hover{
                box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.13);
                color: white;
            }
        }

//Checkout button
        &.checkout{
            background: $cta-color;
            width: 100%;
            font-weight: 700;
            padding: 2rem 0;
            font-size: 1.3rem;
            color: $second-color;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: 1.1rem;
                padding: 1.4rem 0;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: 1rem;
                padding: 1.4rem 0;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                font-size: 1rem;
                padding: 1.4rem 0;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .9rem;
                padding: 1.2rem 0;
            }
            .icon{
                width: 36px;
                height: 36px;
                display:block;
                background: url(./images//shopping-cart.svg) center no-repeat;
                background-size: contain;
                margin-right: 1rem;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    width: 32px;
                    height: 32px;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    width: 30px;
                    height: 30px;
                }
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    width: 30px;
                    height: 30px;
                }
                @media screen and (max-width:$mobile - 1px){
                    width: 28px;
                    height: 28px;
                }
            }
        }
        &:hover{
            box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.13);
            color: $second-color;
        }
    }

//Continue button
        .continue{
            font-size: .9rem;
            color: $second-color;
            text-align: center;
            margin:1.6rem auto;
            font-weight: 500;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                margin:1.2rem auto;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .8rem;
                margin: .4rem auto;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                font-size: .8rem;
                margin:.8rem auto;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .8rem;
                margin:.8rem auto;
            }
            .icon{
                width: 10px;
                height: 10px;
                background: url(./images/right-arrow-dark.svg) center no-repeat;
                background-size:contain;
                margin-left:.2rem;
                @media screen and (max-width:$mobile - 1px){
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
}

//2. Global title for pages and action part on homepage
.title-box-main{
    display:flex;
    position: relative;
    &:before{
        content:"";
        width: 100%;
        height: 1px;
        position: absolute;
        left:0;
        top:50%;
        background: $light-grey-back;
    }
    a{
        &.title{
            &:hover{
                background: $main-color-hover;
            }
        }
    }
    .title{
        background: $main-color;
        color: $second-color;
        font-size: .9rem;
        border-radius: 40px;
        padding: .8rem 2rem;
        font-weight: 700;
        margin-left:3rem;
        text-transform: uppercase;
        z-index: 1;
        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
            font-size: .85rem;
            padding: .7rem 1.8rem;
            margin-left:2rem;
        }
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            font-size: .8rem;
            padding: .65rem 1.6rem;
            margin-left:2rem;
        }
        @media screen and (max-width:$mobile - 1px){
            font-size: .8rem;
            padding: .6rem 1.6rem;
            margin-left:1rem;
        }
        @media screen and (max-width:$tablet - 1px){

        }
    }
}

.title-box-action{
    display:flex;
    .title{
        font-size: 1.6rem;
        margin:auto 0;
        text-transform: uppercase;
        color: $second-color;
        font-weight: 700;
        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
            font-size: 1.4rem
        }
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            font-size: 1.2rem
        }
        @media screen and (max-width:$mobile - 1px){
            font-size: 1.2rem
        }
    }
    .icon{
        background:$cta-color url(./images/right-arrow-white.svg) center no-repeat;
        background-size: 1.2rem;
        width: 2.4rem;
        height: 2.4rem;
        margin:auto 1rem;
        border-radius: 90px;
        display:block;
        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
            width: 2.2rem;
            height: 2.2rem;
        }
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            width: 2rem;
            height: 2rem;
            background-size: 1rem;
        }
        @media screen and (max-width:$mobile - 1px){
            width: 2rem;
            height: 2rem;
            background-size: 1rem;
        }
        &:hover{
            background:$cta-color-hover url(./images/right-arrow-white.svg) center no-repeat;
            background-size: 1.2rem;
            box-shadow: -3px 13px 24px -10px rgba(0, 0, 0, 0.33);
        }
    }
}

//3. Default product representation, used on homepage, category page, actions page, single product page
.products-slider{
    padding: 0;
    @media screen and (max-width:$mobile - 1px){
        padding: 0;
    }
    .single{
        padding:0 10px;
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            padding: 0 5px;
        }
        @media screen and (max-width:$mobile - 1px){
            padding: 0 5px;
        }
        &.is-one-fifth{
            padding-bottom:20px;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                padding-bottom: 10px;
            }
            @media screen and (max-width:$mobile - 1px){
                padding-bottom: 10px;
            }
        }
        &:focus{
            outline:none;
        } 
        &:last-child{
            margin-right: 0 !important;
        }

        .inner{
            width: 100%;
            box-sizing: border-box;
            background: white;
            border:1px solid #ececec;
            margin:auto;
            position: relative;

//Display button "View more" on hover
            .open-product-overlay{
                position: absolute;
                top:0%;
                left:0%;
                opacity: 0;
                visibility: hidden;
                width: 100%;
                height: 100%;
                display:flex;
                background: rgba(0, 0, 0, 0.24);
                z-index: 980;
                .button-area{
                    margin:auto;
                    .button{
                    font-size: .85rem;
                    padding: 1.1rem 1rem;
                    font-family: $second-font;
                    font-weight: 500;
                    background:$cta-color;
                    color: white;
                    @media screen and (min-width: $mobile) and (max-width:$fullhd - 1px){
                        font-size: .75rem;
                        padding: .9rem .8rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .7rem;
                        padding: .7rem .6rem;
                    }
                    &:hover{
                        background: $cta-color-hover;
                        }
                    }
                }
            }
        &:first-child{
            padding-left:0;
        }

//Display category name at the top of single product box - not used
        .category{
            text-transform: uppercase;
            font-size: .9rem;
            font-weight: 600;
            @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
                font-size: .8rem;
            }
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .75rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .7rem;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                font-size: .7rem;
                line-height: 1rem;
                display:block;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .65rem;
                line-height: .9rem;
                display:block;
            }
            &.light-green{
                color: $light-green-color;
            }

            &.red{
                color: $red-color;
            }

            &.orange{
                color: $orange-color;
            }

            &.light-blue{
                color: $light-blue-color;
            }
            &.dark-blue{
                color: $dark-blue-color;
            }
            &.dark-green{
                color: $dark-green-color;
            }
        }

        .brand-name{
            color:$grey-color;
            font-size: .9rem;
            font-weight: 400;
            padding: 0 1rem 0;
            margin-top: .4rem;
            margin-bottom: .1rem;
            height: 1.3rem;
            @media screen and (min-width: $mobile) and (max-width:$fullhd - 1px){
                font-size: .8rem;
                height: 1rem;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .8rem;
                height: 1rem;
                margin-bottom: .3rem;
            }

        }
//Product main title
        .title{
            font-family: $second-font;
            color: $second-color;
            font-size: 1.1rem;
            font-weight: 500;
            margin:auto 0;
            padding: 0rem 1rem 0rem;
            height: 5.5rem;
            max-height: 5.5rem;
            display:inline-block;
            a{
                color: $second-color;
            }
            @media screen and (min-width: $tablet) and (max-width:$fullhd - 1px){
                font-size: 1rem;
                height: 5.3rem;
                max-height: 5.3rem;
                overflow: hidden;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                font-size: 1rem;
                height: 5.3rem;
                max-height: 5.3rem;
                overflow: hidden;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: 1.1rem;
                height: 4.6rem;
                max-height: 4.6rem;
                overflow: hidden;
            }
        }

//Product main image
        .image{
            position: relative;
            display:flex;
            background: white;
            
            @media screen and (min-width: $widescreen) and (max-width:$fullhd - 1px){
            }
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
            }
            @media screen and (max-width:$mobile - 1px){
            }
            
//Label for action
            .action-label{
                position: absolute;
                left:0;
                top:1rem;
                padding: .4rem .4rem .15rem;
                background: $red-color;
                border-radius: 0 4px 4px 0;
                @media screen and (min-width: $mobile) and (max-width:$fullhd - 1px){
                    padding: .35rem .35rem .1rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    padding: .3rem .2rem .1rem;
                }
                &.gratis{
                    padding: .9rem;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        padding: .8rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        padding: .7rem;
                    }
                    .percent-discount{
                        font-size: .85rem;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            font-size: .8rem;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                            font-size: .75rem;
                        }
                    }
                }
                .percent-discount{
                    color: white;
                    font-weight: 700;
                    display:block;
                    width: 100%;
                    line-height: .4rem;
                    font-size: .9rem;
                    @media screen and (min-width: $mobile) and (max-width:$fullhd - 1px){
                        font-size: .8rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .75rem;
                    }
                }
                .save-calculator{
                    font-size: .7rem;
                    color: white;
                    display: block;
                    margin:.4rem 0 0;
                    @media screen and (min-width: $mobile) and (max-width:$fullhd - 1px){
                        font-size: .65rem;
                        margin:.3rem 0 0;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .7rem;
                        margin: .2rem 0 0;
                        
                    }
                }
            }
            img{
                height: 230px;
                width: auto;
                object-fit: cover;
                object-position: center;
                margin:auto;
                padding:1rem 0 1rem;
                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                    height: 210px;
                    object-fit: contain;
                }
                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                    height: 170px;
                    object-fit: contain;
                }
                @media screen and (max-width:$mobile - 1px){
                    height: 250px;
                    object-fit: contain;
                }
            }
        }
        .brand-logo{
            width: 5rem;
            height: 5rem;
            position: absolute;
            right: 0rem;
            top: 0rem;
            img{
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: contain;
            }
        }

//Product footer contains - prices, add to cart button
        .product-foot{
            display:flex;
            padding: 0rem 1rem 0;
            height: 4.4rem;
            border-top: 1px solid $light-grey-back;
            @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                height: 3.8rem;
            }
            @media screen and (max-width:$mobile - 1px){
                height: 3.8rem;
            }
    
//Price box with normal and action price
            .price-box{
                margin:auto 0;
                .price{
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: $second-color;
                    display:block;
                    margin-top:-.4rem;
                    line-height: 1.7;
                    margin-bottom: -.4rem;
                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                        font-size: 1.2rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: 1.3rem;
                    }
                }
                .old-price{
                    font-weight: 500;
                    font-size: 1rem;
                    display:block;
                    margin-bottom: 0;
                    color: $red-color;
                    text-decoration: line-through;
                    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                        font-size: .9rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        font-size: .85rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .9rem;
                    }
                }
            }
    
//Add to cart product button
            .add-to-cart-button{
                background: $cta-color url(./images/add-to-cart.svg);
                background-size:70%;
                padding: 0;
                border:none;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 90px;
                width: 44px;
                height: 44px;
                cursor: pointer;
                margin:auto 0 auto auto;
                z-index: 985;
                
                @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                    width: 36px;
                    height: 36px;
                    background-size: 65%;
                }
                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                    width: 36px;
                    height: 36px;
                    background-size: 65%;
                }
                @media screen and (max-width:$mobile - 1px)
                {
                    width: 42px;
                    height: 42px;
                    background-size: 75%;
                }

//Variation alert for variatible products
                .variation-alert{
                    position: absolute;
                    width: 90%;
                    left:5%;
                    bottom:4.2rem;
                    padding: .3rem;
                    font-weight: 500;
                    color: $second-color;
                    font-size: .6rem;
                    background: white;
                    border-radius: 7px;
                    opacity: 0;
                    visibility: hidden; 
                    &:before{
                        content:"";
                        position: absolute;
                        right:1.4rem;
                        bottom:-10px;
                        width: 6px; 
                        height: 0; 
                        border-radius: 7px;
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;  
                        border-left: 8px solid white;
                        transform: rotate(90deg);
                    }
                }
                &:hover{
                    background: $red-color url(./images//add-to-cart.svg);
                    box-shadow: -3px 13px 24px -10px rgba(0, 0, 0, 0.33);
                    background-size:70%;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &:hover .variation-alert{
                    opacity: 1;
                    visibility: visible;
                }

                &:focus{
                    outline:none;
                }
            }
            
        }
        &:hover .open-product-overlay{
            opacity: 1;
            visibility: visible;
        }
    }
}
}

//Message for no products founded
.no-products{
    font-family: $second-font;
    color: $second-color;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
    width: 100%;
    margin:3rem auto;
    @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
        font-size: 1.2rem;
        margin: 2rem auto;
    }
    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
        font-size: 1rem;
        margin: 1.4rem auto;
    }
    @media screen and (max-width:$mobile - 1px){
        font-size: 1rem;
        margin:1rem auto;
        width: 90%;
    }
    .icon{
        display:block;
        margin: auto auto 2rem;
        width: 80px;
        height: 80px;
        background: url(./images/no-products.svg) center no-repeat;
        background-size:contain;
        @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
            width: 70px;
            height: 70px;
        }
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            width: 60px;
            margin-bottom: 1rem;
            height: 60px;
        }
        @media screen and (max-width:$mobile - 1px){
            width: 55px;
            height: 55px;
            margin-bottom: 1rem;
        }
    }
}
//4. Default form used on contact page and order page
.default-form{
    fieldset{
        .error-message{
            color: $red-color;
            font-weight: 700;
            font-size: .8rem;
            margin-top: .3rem;
            display:flex;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .7rem;
                font-weight: 500;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .7rem;
                font-weight: 500;
            }
            &:before{
                content:"";
                position: relative;
                display:block;
                width: 15px;
                height: 15px;
                background: url(./images/empty-field.svg) center no-repeat;
                background-size: contain;
                margin-right: .3rem;
            }
            &.shipping-special{
                background: $red-color;
                padding: .7rem 0rem;
                border-radius: 3px;
                color: white;
                margin:1rem 0;
            }
        }
        .error-message-big{
            color: white;
            padding: .5rem;
            border-radius: 3px;
            margin-bottom: .6rem;
            font-weight: 700;
            font-size: .9rem;
            background: $red-color;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .8rem;
                font-weight: 500;

            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .8rem;
                font-weight: 500;
            }
        }
        
        label{
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                padding: .4rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                padding:.3rem;
            }
            @media screen and (max-width:$mobile - 1px){
                padding: .4rem;
            }
            &.password-create-account{
              margin:0 0 1rem !important;
            }
            .description{
                font-size: .9rem;
                margin-top: .4rem;
                margin-bottom: .3rem;
                display: block;
            }
            input, textarea, select{
                border-radius: 30px;
                background: $light-grey-back;
                border:none;
                color: $second-color;
                padding: 1rem;
                font-size: .9rem;
                font-family: $main-font;
                width: 100%;
                font-weight: 500;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: .85rem;
                    padding: .9rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .8rem;
                    padding: .75rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .8rem;
                    padding: .7rem;
                }
                &:focus{
                    outline: none;
                    box-shadow: 0 1px 5px 0px rgba(206, 206, 206, 1)
                }
                option{
                    background: white;
                    border:none;
                }
            }
            
            
            &.checkbox {
                display: flex;
                position: relative;
                padding-left: 3rem;
                cursor: pointer;
                font-size: .9rem !important;
                margin:1rem auto 0 !important;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                input{
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                .checkmark {
                    position: absolute;
                    left:1rem;
                    top:.5rem;
                    height: 22px;
                    margin:auto;
                    display:block;
                    width: 22px;
                    background-color: #eee;
                  }

                    &:hover input ~ .checkmark {
                        background-color: #ccc;
                    }
                    
                     input:checked ~ .checkmark {
                        background-color: $main-color;
                    }
                    
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                    
                    input:checked ~ .checkmark:after {
                        display: block;
                    }
                    
                     .checkmark:after {
                        left: 9px;
                        top: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
              }
              &.radio{
                display: block;
                position: relative;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border:1px solid rgba(0, 0, 0, 0.07);
                border-radius: 4px;
                padding: 1rem 2.4rem;
                margin:.3rem 0 !important;
                box-shadow: -3px 6px 14px -5px rgba(0, 0, 0, 0.05);
                  input{
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 2rem;
                  }
                  .text{
                      color: $second-color;
                      font-size: .9rem;
                      margin-left: .2rem;
                      @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: .85rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .8rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: .8rem;
                    }
                  }
                  .info{
                    width: 1.2rem;
                    height: 1.2rem;
                    display: block;
                    background:$main-color url(./images/info.svg) center no-repeat;
                    background-size: .8rem;
                    border: 1px solid $main-color-hover;
                    margin: auto 0 auto auto;
                    border-radius: 90px;
                    cursor: pointer;
                    position: absolute;
                    top:1.1rem;
                    right: 1rem;
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        width: 1rem;
                        height: 1rem;
                        top: .9rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        width: 1rem;
                        height: 1rem;
                        top: .95rem;
                        background-size: .5rem;
                    }
                }
                  .checkmark {
                    position: absolute;
                    top: 1rem;
                    left: .75rem;
                    height: 20px;
                    width: 20px;
                    background-color: $light-grey-back;
                    border-radius: 50%;
                    
                  }
                  &:hover input ~ .checkmark {
                    background-color: #ccc;
                  } 
                  input:checked ~ .checkmark {
                    background-color: $main-color;
                  }
                  .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                  } 
                  input:checked ~ .checkmark:after {
                    display: block;
                  }
                  .checkmark:after {
                    top: 6px;
                   left: 6px;
                   width: 8px;
                   height: 8px;
                   border-radius: 50%;
                   background: white;
               }
              }
              
            textarea{
                height: 150px;
            }
        }
        .subtitle{
            color: $grey-color;
            font-size: 1.1rem;
            padding:1rem .75rem .4rem;
            margin-bottom: 0;
            font-weight: 500;

        }
        ::placeholder{
            color: $grey-color;
        }

        .button-area{
            width: 100%;
            padding:.75rem;
            .button{
                height: unset;
                width: 100%;
                padding: 1.1rem 0;
            }
        }
    }
}

.shop{
    //5. Search bar component, used in header partialy and in newsletter on homepage
    .search-bar{
        margin:auto;
        .inner{
            background: white;
            border-radius: 30px;
            height:46px;
            padding:0;
            line-height: 46px;
            display:flex;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                height: 40px;
                line-height: 40px;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                height: 34px;
                line-height: 34px;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                height: 38px;
                line-height: 38px;
            }
            label{
                width: 100%;
            }
            input{
                background: none;
                color: $second-color;
                border:none;
                font-size: .9rem;
                font-family: $main-font;
                padding-left:1rem;
                font-weight: 400;
                width: 80%;
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .65rem;
                    width: 80%;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .8rem;
                }

                &:focus{
                    outline: none;
                }
            }

            button{
                width: 42px;
                height: 38px;
                margin: auto 4px auto auto;
                background: $cta-color url(./images/right-arrow-white.svg); 
                background-size: 45%;
                background-repeat: no-repeat;
                background-position: center;
                border:none;
                border-radius: 90px;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                width: 34px;
                height: 32px;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    width: 30px;
                    height: 30px;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    width: 30px;
                }
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    height: 34px;
                    width: 36px;
                }
                @media screen and (max-width:$mobile - 1px){
                    width: 34px;
                }
            }
        }
    }
}

//6. Popup for adding new product on cart - displayed when product is added to cart
.popup{
    position: fixed;
    top:1rem;
    right: 1rem;
    background: white;
    box-shadow: 0 0 10px #888;
    transform: translateY(-5%);
    transition: 0.5s;
    overflow: hidden;
    width: 450px;
    display: none;
    border-radius: 4px;
    z-index: 1010;
    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
        width: 350px;
    }
    @media screen and (max-width:$mobile - 1px){
        width: 320px;
    }
    &.show{
        transform:translateY(0);
      display: block;
    } 
    &.admin-popup{
        width: 300px;
        height: 150px;  
    }
    .popup-content{
        .text, a{
            font-size: 1rem;
            font-weight: 500;
            color: $second-color;
            padding: 1.4rem .8rem;
          
            margin:auto;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
               font-size: .85rem;
            }
            @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                font-size: .8rem;
             }
             @media screen and  (max-width:$mobile - 1px){
                font-size: .75rem;
                padding: 1rem .6rem;
             }
        }
        a{
            font-weight: 600;
            &:hover{
                text-decoration: underline;
            }
        }
        .single-row{
            display:flex;
            margin:1rem 0;
            padding: 0 .4rem .8rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                margin:.6rem 0;
            }
            @media screen and (max-width:$mobile - 1px){
                margin:.4rem 0;
            }
            .image{
                padding-right: 0;
                margin:0;
                overflow: hidden;
                a{
                    padding: 0;
                }
                img{
                    height: 75px;
                    width: 75px;
                    object-fit: contain;
                    transform: scale(1.4);
                    @media screen and  (max-width:$mobile - 1px){
                        height: 38px;
                        width: 38px;
                    }
                }      
            }
            .info{
                padding: 0rem .4rem;
                margin-left: .4rem;
                @media screen and  (max-width:$mobile - 1px){
                    padding: 0 .6rem;
                }
                .title{
                    font-size: 1.1rem;
                    color: $second-color;
                    margin-bottom:0;
                    font-weight: 600;
                    padding: 0;
                    font-family: $second-font;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size:1.05rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        font-size: .95rem;
                    }
                    @media screen and  (max-width:$mobile - 1px){
                        font-size: .95rem;
                    }
                }
                .price{
                    font-weight: 500;
                    font-size: 1.2rem;
                    color: $second-color;
                    margin-bottom: 0;
                    padding: .2rem 0;
                    .quantity{
                        margin: auto 1rem;
                        font-weight: 500;
                        font-size: .9rem;
                        color: $grey-color;
                        background: none;
                        @media screen and  (max-width:$mobile - 1px){
                            font-size: .78rem;
                        }
                    }
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: 1.1rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        font-size: .95rem;
                        padding: 0;
                    }
                    @media screen and  (max-width:$mobile - 1px){
                        font-size: .78rem;
                        padding: 0 0 .2rem;
                        margin-top:-.2rem;
                    }
                }
            
                .old-price{
                    text-decoration: line-through;
                    font-size: 1rem;
                    font-weight: 500;
                    color: $red-color;
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        font-size: .9rem;
                    }
                    @media screen and  (max-width:$mobile - 1px){
                        font-size: .65rem;
                    }
                }
            }
            .quantity{
                margin:auto 2rem auto auto;
                font-weight: 600;
                border-radius: 90px;
                background: $light-grey-back;
                width: 35px;
                height: 35px;
                font-size: 1.05rem;
                line-height: 25px;
                text-align: center;
                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: .9rem;
                }
                @media screen and  (max-width:$mobile - 1px){
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: .7rem;
                }
            }
        }
        .product-stock{
            border-radius: 7px;
            font-size: .8rem;
            display: initial;
            padding: .5rem .8rem;
            font-weight: 500;
            position: relative;
            &.is-mobile{
                display: none;
            }
           
            @media screen and (min-width: $desktop) and (max-width:$fullhd - 1px){
                font-size: .8rem;
                padding: .4rem .8rem;
                top: .8rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .7rem;
                padding: .3rem .6rem;
                top:.6rem;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .66rem;
                padding: .2rem .4rem;
                top: .6rem;
                
            }
            @media screen and (max-width:$tablet - 1px){
                display:inline-flex;
                width: 100%;
                top: 0;
            }
            &.yes{
                color: $second-color;
                background: $main-color;
            }
            &.no{
                color: white;
                background: $red-color;
            }
        }
        .button-area{
            display:flex;
            margin: 1rem;
            @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                margin:.6rem 1rem;
            }
            @media screen and (max-width:$mobile - 1px){
                margin: .8rem 1rem;
            }
            .button{
                border:none;
                border-radius: 30px;
                font-size: .85rem;
                font-weight: 500;
                padding:1.3rem .7rem;
                margin:0 .4rem;
                line-height: 0;
                height: 0;
                cursor: pointer;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: .7rem;
                    padding: .8rem;
                }
                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                    font-size: .65rem;
                    padding: .7rem;
                }
                @media screen and  (max-width:$mobile - 1px){
                    font-size: .65rem;
                    padding: .7rem;
                    display: initial;
                }
                &.continue-shop{
                    background:#efefef;
                    color: $text-black;
                    border:1px solid rgba(0, 0, 0, 0.05);
                }
    
                &.checkout{
                    background: $second-color;
                    color: white;
                }
    
                &:first-child{
                    margin-left:0;
                }

                &:focus{
                    outline:none;
                    box-shadow: none;
                }
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    &.popup-success{
        .popup-header{
            display:flex;
            background: $cta-color;
            color: $second-color;
            font-weight: 500;
            font-size: .95rem;
            padding:.6rem .8rem;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .75rem;
                padding: .6rem .7rem;
            }
            @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                font-size: .7rem;
                padding: .6rem .6rem;
            }
            @media screen and  (max-width:$mobile - 1px){
                font-size: .65rem;
                padding: .5rem .5rem;
            }

            .close-popup{
                margin:auto 0 auto auto;
                cursor: pointer;
                svg{
                    font-size: 1.1rem;
                    
                }
            }
        }
    }
}

//7. Popup wrapper for deleting products
.popup-wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    display:flex;
    z-index: 1010;
    opacity: 0;
    visibility: hidden;

    &.show{
        opacity: 1;
        visibility: visible;
    }
    .overlay-back{
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(0,0,0,0.5);
    }

    .popup-box{
        margin:auto;
        background: white;
        z-index: 980;
        padding:2.6rem;
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            padding: 2.2rem;
        }
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            padding: 2rem;
        }
        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
            width: 70%;
            padding: 1.8rem;
        }
        @media screen and (max-width:$mobile - 1px){
            width: 80%;
            padding: 1.4rem;
        }
        .text{
            font-size: 1rem;
            font-weight: 500;
            text-align: center;
            color: $second-color;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .9rem;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .8rem;
            }
            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                font-size: .8rem;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .8rem;
            }
        }

        .button-area{
            margin:2rem auto 0;
            display:flex;
            @media screen and (max-width:$mobile - 1px){
                margin:1rem auto 0;
            }

            .button{
                margin:auto;
                font-size: .9rem;
                text-transform: none;
                padding: 1.4rem 1.8rem;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: .8rem;
                    padding: 1.2rem 1.4rem;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    font-size: .75rem;
                    padding: 1rem 1.2rem;
                }
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    font-size: .75rem;
                    padding: 1rem 1.2rem;
                }
                @media screen and (max-width:$mobile - 1px){
                    padding: 1rem;
                    font-size: .75rem;
                }
                &.confirm{
                    color: white;
                }

                .decline{}
            }
        }
    }
}

//8. GLobal pagination for category page, actions page...
.shop{
    .pagination-wrapper{
        width: 100%;
        &.pagination-top{
        margin:1rem auto 2rem;
        }
        &.pagination-bottom{
            margin:3rem auto 0rem;
            @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                margin:1rem auto 0;
            }
            @media screen and (max-width:$mobile - 1px){
                margin-top: 1rem;
            }
        }
        .pagination{    
            &.pagination-box{
                justify-content: center;
                @media screen and (max-width:$mobile - 1px){
                    margin-left: -1rem;
                }
                li{
                    margin:0 .6rem;
                    list-style: none;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        margin:0 .4rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        margin:0 .2rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        margin:0 .2rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        margin:0 .2rem;
                    }
                    a{
                        border-radius: 90px;
                        color: $main-color;
                        width: 2.6rem;
                        height: 2.6rem;
                        font-size: 1rem;
                        text-align: center;
                        line-height: 2.6rem;
                        font-weight: 500;
                        background: $second-color;
                        display:block;
                        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                            width: 2.2rem;
                            height: 2.2rem;
                            font-size: .85rem;
                            line-height: 2.2rem;
                        }
                        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                            width: 1.8rem;
                            height: 1.8rem;
                            line-height: 1.8rem;
                            font-size: .75rem;
                        }
                        @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                            width: 1.6rem;
                            height: 1.6rem;
                            line-height: 1.6rem;
                            font-size: .75rem;
                        }
                        @media screen and (max-width:$mobile - 1px){
                            width: 1.4rem;
                            height: 1.4rem;
                            line-height: 1.4rem;
                            font-size: .65rem;
                        }
                        &:hover{
                            background: $main-color;
                            color: white;
                        }

                        &.text{
                            border:none;
                            width: auto;
                            font-size: 1rem;
                            background: none;
                            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                font-size: .85rem;
                            }
                            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                font-size: .75rem;
                            }
                            @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                font-size: .7rem;
                            }
                            @media screen and (max-width:$mobile - 1px){
                                font-size: .65rem;
                            }
                            &:hover{
                                color: $main-color;
                            }
                        }
                    }
                    &.active{
                    a{
                        background: $main-color;
                        color: white;
                    }
                    }
                    &.disabled{
                        a{
                            border:1px solid $grey-color;
                            color: $grey-color;
                            background: $background-main;
                            cursor: not-allowed;

                            &.text{
                                border:none;
                                width: auto;
                                font-size: 1rem;
                                background: none;
                                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                                    font-size: .9rem;
                                }
                                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                                    font-size: .75rem;
                                }
                                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                                    font-size: .8rem;
                                }
                                @media screen and (max-width:$mobile - 1px){
                                    font-size: .65rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//9. Global swiper slider pagination - not used
.slick-slider > .slick-dots{
    bottom:0rem !important;
    text-align:center;
    li{
       background:$grey-color;
       width: 10px;
       height: 10px;
       border-radius: 90px;
       button{
           &:before{
               content: none;
               display: none;
           }
       }
       @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
           width: 8px;
           height: 8px;
       }
       @media screen and (max-width:$mobile - 1px){
        width: 8px;
        height: 8px;
        }
        &.slick-active{
            width:20px;
            border-radius: 40px;
            background: $main-color;
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                width: 14px;
            }
            @media screen and (max-width:$mobile - 1px){
                width: 14px;
            }
       }
    }
}

//10. Page loader box
.page-load{
    display:flex;
    padding: 10rem 0;
    .loader{
        width: 50px;
        height: 50px;
        background-size: contain;
        display:block;
        margin:auto;
    }
}

//11. Social box in footer and on contact page
.social-box{
    display: flex;
    margin-top:2rem;
    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
        margin-top: 1rem;
    }
    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
        margin-top: 1rem;
    }
    @media screen and (max-width:$tablet - 1px){
        margin-top: 1rem;
    }
    .icon{
        width: 26px;
        height: 26px;
        display:block;
        margin-right: 1rem;
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            width: 28px;
            height: 28px;
        }
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            width: 24px;
            height: 24px;
        }
        @media screen and (max-width:$mobile - 1px){
            width: 22px;
            height: 22px;
        }
        &.facebook{
            background: url(./images/facebook.svg) center no-repeat;
            background-size:contain;
        }

        &.instagram{
            background: url(./images/instagram.svg) center no-repeat;
            background-size:contain;
        }
    }
}

//12. Content for thank you page used after creating order, sending message from contact page
.thank-you-content{
    .title{
        text-align: center;
        padding: 2rem 0;
        font-size: 2.4rem;
        margin:0;
        font-weight: 600;
        color: $second-color;
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            font-size: 2.2rem;
        }
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            font-size: 2rem;
        }
        @media screen and (max-width:$mobile - 1px){
            font-size: 1.8rem;
        }
        
    }
    .subtitle{
        font-size: 1rem;
        color: $second-color;
        width: 70%;
        margin:auto;
        text-align:center;
        padding: 0 0 0rem;
        line-height: 1.7rem;
        margin-bottom: 0rem;
        @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
            font-size: .9rem;
            line-height: 1.5rem;
        }
        @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
            font-size: .85rem;
            line-height: 1.3rem;
        }
        @media screen and (max-width:$mobile - 1px){
            font-size: .8rem;
            line-height: 1.1rem;
        }
    }
    .button-area{
        display:flex;
        .button{
            &.checkout{
                width: 50%;
                margin: auto;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    font-size: .95rem;
                }
                @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                    font-size: .85rem;
                }
                @media screen and (max-width:$tablet - 1px){
                    width: 90%;
                }
                @media screen and (max-width:$mobile - 1px){
                    font-size: .8rem;
                }
                .icon{
                    background: url(./images/order.svg) center no-repeat;
                    background-size: contain;
                    margin-right: 1rem;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        width: 30px;
                        height: 30px;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$desktop - 1px){
                        width: 28px;
                        height: 28px;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        width: 22px;
                        height: 22px;
                        margin-right: .6rem;
                    }
                }
            }
        }
    }
}

.without-footer{
    .container{
        .support-box{
            display:flex;  
            margin: 2.8rem 0 0;
            background: #f2f2f2;
            padding: 2rem 0;
            @media screen and (max-width:$mobile - 1px){
                margin-top:.8rem;
            }
            .inner{
                margin:auto;
                display:flex;
            }
            .help{
                background: url(./images/help-icon.svg) center no-repeat;
                background-size: contain;
                width: 60px;
                height: 60px;
                display:block;
                @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                    width: 46px;
                    height: 46px;
                }
                @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                    width: 40px;
                    height: 40px;
                }
                @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                    width: 50px;
                    height: 50px;
                }
                @media screen and (max-width:$mobile - 1px){
                    width: 50px;
                    height: 50px;
                    display:none;
                }
            }
            .content-box{
                margin-left:1rem;
                @media screen and (max-width:$mobile - 1px){
                    margin-left: 0;
                }
                p{
                    color: $second-color;
                    font-weight: 500;
                    font-size: 1rem;
                    margin-bottom: .3rem;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: .9rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: .9rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        font-size: .9rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: 1.2rem;
                    }
                }
                a{
                    font-size: 1.8rem;
                    color: $second-color;
                    font-weight: 400;
                    line-height: 2.2rem;
                    display:block;
                    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                        font-size: 1.6rem;
                    }
                    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                        font-size: 1rem;
                        line-height: 1.2rem;
                    }
                    @media screen and (min-width: $mobile) and (max-width:$tablet - 1px){
                        font-size: 1.6rem;
                        line-height: 2rem;
                    }
                    @media screen and (max-width:$mobile - 1px){
                        font-size: 1.2rem;
                        line-height: 1.8rem;
                    }
                }
            }
        }
    }
}

.shipping-table{
    position: absolute;
    right: 100%;
    margin-right: 1rem;
    width: 500px;
    box-shadow: -3px 10px 20px -10px rgba(0, 0, 0, 0.14);
    top: 0;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.08);
    border-left: 1px solid rgba(0,0,0,0.08);
    border-right: 1px solid rgba(0,0,0,0.08);
    background: $light-grey-back;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
        width: 400px;
    }
    @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
        width: 300px;
        border:1px solid #e6e6e6;
    }
    @media screen and (max-width:$tablet - 1px){
        right: 0%;
        top: 100%;
        width: 100%;
        margin-right: 0;
    }
    &.active{
        visibility: visible;
        opacity: 1;
    }
    &:before{
        content: "";
        position: absolute;
        right: -10px;
        top: .5rem;
        width: 10px;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid $main-color;
        z-index: 999;
        @media screen and (max-width:$tablet - 1px){
            content: none;
        }
    }
    &.free-pickup-table{
        .titles{
            height: auto;
        }
        .results{
            padding: .3rem 0;
            display: block;
            .column{
                padding-bottom: .15rem;
                padding-top: .15rem;
               width: 100% !important;
            }
        }
    }
    .titles{
        background: $main-color;
        margin: 0 !important;
        height: 3.6rem;
        display: flex;
        @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
            height: 2.4rem;
        }
        @media screen and (max-width:$tablet - 1px){
            height: 2.6rem;
        }
        .column{
            padding: .5rem;
            margin: auto !important;
            font-weight: 700 !important;
            font-size: .95rem !important;
            line-height: 1.2rem;
            border: 0;
            min-height: 100%;
            align-items: center;
            border-right: 1px solid rgba(255,255,255,.55);
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .8rem !important;
                padding: .4rem !important;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .72rem !important;
                line-height: .9rem;
                padding:.3rem !important;
            }
            @media screen and (max-width:$tablet - 1px){
                width: 33% !important;
                min-width: 33% !important;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .70rem !important;
                padding: .3rem !important;
                line-height: .8rem;
            }
            &:last-child{
                border-right: 0 !important;
            }
        }
    }
    .list-places{
        .column{
            padding-bottom: 0;
        }
    }
    .results{
        margin: 0 !important;
        border-bottom: 1px solid white;
        display: flex;
        &:last-child{
            border-bottom: 0;
        }
        &.list-places{
            .column{
                padding-bottom: 0 !important;
            }
        }
        .column{
            padding:.5rem;
            font-size: .95rem !important;
            border-right: 1px solid white !important;
            @media screen and (min-width: $desktop) and (max-width:$widescreen - 1px){
                font-size: .8rem !important;
                padding:.4rem !important;
            }
            @media screen and (min-width: $tablet) and (max-width:$desktop - 1px){
                font-size: .72rem !important;
                padding: .3rem !important;
            }
            @media screen and (max-width:$tablet - 1px){
                width: 33% !important;
                min-width: 33% !important;
            }
            @media screen and (max-width:$mobile - 1px){
                font-size: .70rem !important;
                padding: .3rem !important;
            }
            &:last-child{
                border-right: 0;
            }
        }
    }
}

//13. Popup animation
@keyframes hidePopupAnimation {
    0%   {opacity: 1; transform: translateY(0)}
    90%  {opacity: 1; transform: translateY(-5%)}
    100% {opacity: 0; transform: translateY(-10%); visibility: hidden;}
}
@-webkit-keyframes hidePopupAnimation {
    0%   {opacity: 1; transform: translateY(0)}
    90%  {opacity: 1; transform: translateY(-5%)}
    100% {opacity: 0; transform: translateY(-10%); visibility: hidden;}
}

.lazyload-wrapper{
    margin: auto;
}

.page-offline{
    font-size: 23px;
    font-weight: 500;
    text-align: center;
    color: #3c1053;
    margin-bottom: 1rem !important;
    max-width: 500px;
    margin: auto;
}
.offline-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 720px;
    text-align: center !important;
    div{
        margin-top: -4rem;
    }
}