
.footer{
    position: relative;
    &.admin-footer{
        background: $background-main;
        padding: 1.5rem 0;
        border-top:1px solid rgba(0,0,0,0.05);
        .container{
            .text{
                color: #666666;
                font-size: .8rem;
                text-align: center;
            }
        }
    }
}